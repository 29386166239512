import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserV1 } from '../../../Services/UserService';
import { UserDetails } from '../../../Components/UserDetails/UserDetails';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface UserLockPageConfirmStepProps extends WithTranslation
{
    user:       UserV1;
    onCancel:   ( ) => any;
    onFinish:   ( ) => any;
}


class $UserLockPageConfirmStep extends React.Component<UserLockPageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('userlockpageconfirmstep.txt_title')}
                </IcText>

                <UserDetails user={this.props.user} />
                
                <IcText>
                    {this.props.t('userlockpageconfirmstep.txt_confirm')}
                </IcText>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}
                        testID='userlockpageconfirmstep.btn_cancel'>
                        {this.props.t('userlockpageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}
                        testID='userlockpageconfirmstep.btn_lock'>
                        {this.props.t('userlockpageconfirmstep.btn_lock')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const UserLockPageConfirmStep = withTranslation()($UserLockPageConfirmStep);
