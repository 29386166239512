import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GroupService } from '../../Services/GroupService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminGroupAddPageLoadingStep } from './AdminGroupAddPageLoadingStep/AdminGroupAddPageLoadingStep';
import { AdminGroupAddPageSuccessStep } from './AdminGroupAddPageSuccessStep/AdminGroupAddPageSuccessStep';
import { AdminGroupAddPageFormStep, AdminGroupAddPageFormStepFormData } from './AdminGroupAddPageFormStep/AdminGroupAddPageFormStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminGroupAddPageProps extends RouteComponentProps, WithTranslation
{
}


enum AdminGroupAddPageStep
{
    Loading = 'LOADING',
    Form    = 'FORM',
    Success = 'SUCCESS'
}


interface AdminGroupAddPageState
{
    step:   AdminGroupAddPageStep;
    error:  Error | null;
}


class $AdminGroupAddPage extends React.Component<AdminGroupAddPageProps, AdminGroupAddPageState>
{
    private readonly _groupService: GroupService;
    

    constructor ( props: AdminGroupAddPageProps )
    {
        super(props);

        this.state = {
            step:           AdminGroupAddPageStep.Form,
            error:          null
        };

        this._groupService = GroupService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: AdminGroupAddPageFormStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminGroupAddPageStep.Loading,
                error:  null
            });

            await this._groupService.addGroup(
                {
                    key:    formData.key.trim(),
                    name:   formData.name.trim()
                }
            );

            this.setState({
                step:   AdminGroupAddPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating group: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminGroupAddPageStep.Form,
                error:  err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('admingroupaddpage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminGroupAddPageStep.Loading ?
                    <AdminGroupAddPageLoadingStep />
                : null}

                {this.state.step === AdminGroupAddPageStep.Form ?
                    <AdminGroupAddPageFormStep
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === AdminGroupAddPageStep.Success ?
                    <AdminGroupAddPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminGroupAddPage = withTranslation()(withRouter($AdminGroupAddPage));
