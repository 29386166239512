import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputCheckbox, IcInputText, IcSeparator, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { SourceV1 } from '../../../Services/SourceService';


export interface AdminSourceEditPageLdapFormStepFormData
{
    name:                           string;
    enabled:                        boolean;
    registration_enabled:           boolean;
    auto_assign_enabled:            boolean;
    ldap_uri:                       string;
    ldap_bind_user:                 string;
    ldap_bind_password:             string;
    ldap_user_filter_pattern:       string;
    ldap_user_username_attribute:   string;
    ldap_user_firstname_attribute:  string;
    ldap_user_lastname_attribute:   string;
    ldap_user_email_attribute:      string;
    ldap_user_base_dn:              string;
}


export interface AdminSourceEditPageLdapFormStepProps extends WithTranslation, WithLocaleProps
{
    source:     SourceV1;
    onCancel:   ( ) => any;
    onFinish:   ( formData: AdminSourceEditPageLdapFormStepFormData ) => any;
}


interface AdminSourceEditPageLdapFormStepState
{
    initialFormData:    AdminSourceEditPageLdapFormStepFormData;
}


class $AdminSourceEditPageLdapFormStep extends React.Component<AdminSourceEditPageLdapFormStepProps, AdminSourceEditPageLdapFormStepState>
{
    constructor ( props: AdminSourceEditPageLdapFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:                           '',
                enabled:                        true,
                registration_enabled:           false,
                auto_assign_enabled:            true,
                ldap_uri:                       '',
                ldap_bind_user:                 '',
                ldap_bind_password:             '',
                ldap_user_filter_pattern:       '',
                ldap_user_username_attribute:   '',
                ldap_user_firstname_attribute:  '',
                ldap_user_lastname_attribute:   '',
                ldap_user_email_attribute:      '',
                ldap_user_base_dn:              ''
            }
        };
    }


    private _applySource ( source: SourceV1 ): void
    {
        this.setState({
            initialFormData: {
                name:                           source.name,
                enabled:                        source.enabled,
                registration_enabled:           source.config.registration_enabled,
                auto_assign_enabled:            source.config.auto_assign_enabled,
                ldap_uri:                       source.config.ldap?.uri || '',
                ldap_bind_user:                 source.config.ldap?.bind_user || '',
                ldap_bind_password:             source.config.ldap?.bind_password || '',
                ldap_user_filter_pattern:       source.config.ldap?.user_filter_pattern || '',
                ldap_user_username_attribute:   source.config.ldap?.user_username_attribute || '',
                ldap_user_firstname_attribute:  source.config.ldap?.user_firstname_attribute || '',
                ldap_user_lastname_attribute:   source.config.ldap?.user_lastname_attribute || '',
                ldap_user_email_attribute:      source.config.ldap?.user_email_attribute || '',
                ldap_user_base_dn:              source.config.ldap?.user_base_dn || '',
            }
        });
    }


    public componentDidMount ( ): void
    {
        this._applySource(this.props.source);
    }


    public componentDidUpdate ( prevProps: AdminSourceEditPageLdapFormStepProps ): void
    {
        if ( prevProps.source !== this.props.source )
        {
            this._applySource(this.props.source);
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminSourceEditPageLdapFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='enabled'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='registration_enabled'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_registration_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='auto_assign_enabled'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_auto_assign_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_name')}
                                    testID='adminsourceeditpageldapformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <IcSeparator />

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_uri'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_uri')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_uri'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_bind_user'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_bind_user')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_bind_user'
                                    validators={[
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_bind_password'
                                    type='password'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_bind_password')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_bind_password'
                                    validators={[
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_base_dn'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_user_base_dn')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_user_base_dn'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_filter_pattern'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_user_filter_pattern')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_user_filter_pattern'
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_username_attribute'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_user_username_attribute')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_user_username_attribute'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_firstname_attribute'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_user_firstname_attribute')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_user_firstname_attribute'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_lastname_attribute'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_user_lastname_attribute')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_user_lastname_attribute'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_email_attribute'
                                    label={this.props.t('adminsourceeditpageldapformstep.inp_ldap_user_email_attribute')}
                                    testID='adminsourceeditpageldapformstep.inp_ldap_user_email_attribute'
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='adminsourceeditpageldapformstep.btn_cancel'>
                                {this.props.t('adminsourceeditpageldapformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='adminsourceeditpageldapformstep.btn_update'>
                                {this.props.t('adminsourceeditpageldapformstep.btn_update')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminSourceEditPageLdapFormStep = withLocale(withTranslation()($AdminSourceEditPageLdapFormStep));
