import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { GroupV1 } from '../../../Services/GroupService';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AdminGroupEditPageFormStepFormData
{
    key:    string;
    name:   string;
}


export interface AdminGroupEditPageFormStepProps extends WithTranslation, WithLocaleProps
{
    group:      GroupV1;
    onCancel:   ( ) => any;
    onFinish:   ( formData: AdminGroupEditPageFormStepFormData ) => any;
}


interface AdminGroupEditPageFormStepState
{
    initialFormData:    AdminGroupEditPageFormStepFormData;
}


class $AdminGroupEditPageFormStep extends React.Component<AdminGroupEditPageFormStepProps, AdminGroupEditPageFormStepState>
{
    constructor ( props: AdminGroupEditPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                key:    '',
                name:   ''
            }
        };
    }


    private _applyGroup ( group: GroupV1 ): void
    {
        this.setState({
            initialFormData: {
                key:    group.key,
                name:   group.name
            }
        });
    }


    public componentDidMount ( ): void
    {
        this._applyGroup(this.props.group);
    }


    public componentDidUpdate ( prevProps: AdminGroupEditPageFormStepProps ): void
    {
        if ( prevProps.group !== this.props.group )
        {
            this._applyGroup(this.props.group);
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminGroupEditPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='key'
                                    label={this.props.t('admingroupeditpageformstep.inp_key')}
                                    testID='admingroupeditpageformstep.inp_key'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('admingroupeditpageformstep.inp_name')}
                                    testID='admingroupeditpageformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='admingroupeditpageformstep.btn_cancel'>
                                {this.props.t('admingroupeditpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='admingroupeditpageformstep.btn_update'>
                                {this.props.t('admingroupeditpageformstep.btn_update')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminGroupEditPageFormStep = withLocale(withTranslation()($AdminGroupEditPageFormStep));
