import { Country } from '../Model/Country';


export const Environment = {
    stage:  ((window as any).CONFIG && (window as any).CONFIG.STAGE ? (window as any).CONFIG.STAGE : 'local') as string,
    server: {
        baseUrl:                    ((window as any).CONFIG && (window as any).CONFIG.SERVER_BASE_URL ? (window as any).CONFIG.SERVER_BASE_URL : '') as Array<string>,     
        version:                    ((window as any).CONFIG && (window as any).CONFIG.SERVER_VERSION ? (window as any).CONFIG.SERVER_VERSION : '?') as string,
        name:                       ((window as any).CONFIG && (window as any).CONFIG.SERVER_NAME ? (window as any).CONFIG.SERVER_NAME : 'indece SSO') as string,
        locales:                    ((window as any).CONFIG && (window as any).CONFIG.SERVER_LOCALES ? (window as any).CONFIG.SERVER_LOCALES : []) as Array<string>,     
        countries:                  ((window as any).CONFIG && (window as any).CONFIG.SERVER_COUNTRIES ? (window as any).CONFIG.SERVER_COUNTRIES : []) as Array<string>,     
        default_country:            ((window as any).CONFIG && (window as any).CONFIG.SERVER_DEFAULT_COUNTRY ? (window as any).CONFIG.SERVER_DEFAULT_COUNTRY : 'DE') as Country,     
        registration_disabled:      ((window as any).CONFIG && (window as any).CONFIG.SERVER_REGISTRATION_DISABLED ? (window as any).CONFIG.SERVER_REGISTRATION_DISABLED : false) as boolean,     
    },
    company: {
        name:   ((window as any).CONFIG && (window as any).CONFIG.COMPANY_NAME ? (window as any).CONFIG.COMPANY_NAME : '') as string,
        slogan: ((window as any).CONFIG && (window as any).CONFIG.COMPANY_SLOGAN ? (window as any).CONFIG.COMPANY_SLOGAN : '') as string
    },
    oauth: {
        authority:      ((window as any).CONFIG && (window as any).CONFIG.OAUTH_AUTHORITY ? (window as any).CONFIG.OAUTH_AUTHORITY : 'http://localhost:8080/oauth/') as string,
        clientId:       ((window as any).CONFIG && (window as any).CONFIG.OAUTH_CLIENT_ID ? (window as any).CONFIG.OAUTH_CLIENT_ID : 'licenser') as string,
        redirectUri:    ((window as any).CONFIG && (window as any).CONFIG.OAUTH_REDIRECT_URI ? (window as any).CONFIG.OAUTH_REDIRECT_URI : 'http://localhost:3000/') as string
    },
    links: {
        supportUrl:     ((window as any).CONFIG && (window as any).CONFIG.LINK_SUPPORT_URL ? (window as any).CONFIG.LINK_SUPPORT_URL : '') as string
    }
};
