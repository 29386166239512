import React from 'react';
import { Formatter } from '../../../utils/Formatter';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserSfaV1 } from '../../../Services/UserService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../../Components/List/ListItemHeaderAction';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { IcCard, IcCardPadding, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface ResetPasswordPageSfaStepProps extends WithTranslation
{
    sfas:       Array<UserSfaV1>;
    onFinish:   ( sfa: UserSfaV1 ) => any;
}


class $ResetPasswordPageSfaStep extends React.Component<ResetPasswordPageSfaStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small}>
                    {this.props.t('resetpasswordpagesfastep.txt_subtitle')}
                </IcText>
                
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('resetpasswordpagesfastep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('resetpasswordpagesfastep.txt_select_type')}
                </IcText>

                <List>
                    {this.props.sfas.map( ( sfa ) => (
                        <ListItem key={sfa.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    text={Formatter.sfaType(sfa.type)}
                                    subtext={sfa.name}
                                    onClick={ ( ) => this.props.onFinish(sfa)}
                                />

                                <ListItemHeaderAction
                                    icon={faCaretRight}
                                    onClick={ ( ) => this.props.onFinish(sfa)}
                                />
                            </ListItemHeader>
                        </ListItem>
                    )) }
                </List>
            </IcCard>
        );
    }
}


export const ResetPasswordPageSfaStep = withTranslation()($ResetPasswordPageSfaStep);
