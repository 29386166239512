import React from 'react';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridPadding, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface LoginPageSfaTotpStepProps extends WithTranslation
{
    onFinish:   ( formData: LoginPageSfaTotpStepFormData ) => any;
    onOtherSfa: ( ) => any;
}


export interface LoginPageSfaTotpStepFormData
{
    code:  string;
}


interface LoginPageSfaTotpStepState
{
    initialFormData: LoginPageSfaTotpStepFormData;
}


class $LoginPageSfaTotpStep extends React.Component<LoginPageSfaTotpStepProps, LoginPageSfaTotpStepState>
{
    constructor ( props: LoginPageSfaTotpStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                code:  ''
            }
        };

        this._confirm = this._confirm.bind(this);
    }


    private async _confirm ( values: LoginPageSfaTotpStepFormData ): Promise<void>
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<LoginPageSfaTotpStepFormData>;

        return (
            <IcCard padding={IcCardPadding.None}>
                <IcGridRow padding={IcGridPadding.None}>
                    <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                        <IcText size={IcTextSize.Small}>
                            {this.props.t('loginpagesfatotpstep.txt_subtitle')}
                        </IcText>
                        
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('loginpagesfatotpstep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('loginpagesfatotpstep.txt_code')}
                        </IcText>

                        <MyFormik
                            onSubmit={this._confirm}
                            initialValues={this.state.initialFormData}
                            enableReinitialize={true}>
                            <Form>
                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='code'
                                            autoComplete='off'
                                            label={this.props.t('loginpagesfatotpstep.inp_code')}
                                            required={true}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <RequiredHint />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={ ( ) => this.props.onOtherSfa() }>
                                        {this.props.t('loginpagesfatotpstep.btn_other_sfa')}
                                    </IcButton>

                                    <IcButton type='submit'>
                                        {this.props.t('loginpagesfatotpstep.btn_confirm')}
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </MyFormik>
                    </IcGridItem>

                    <IcGridItem s={0} m={4} l={6}>
                        <BoxImage>
                            <ContentImage contentFileKey={ContentFileV1Key.LoginSfaTotpImage} />

                            <SupportOverlay />
                        </BoxImage>
                    </IcGridItem>
                </IcGridRow>
            </IcCard>
        );
    }
}


export const LoginPageSfaTotpStep = withTranslation()($LoginPageSfaTotpStep);
