import DayJS from 'dayjs';
import { t } from 'i18next';
import { Gender } from '../Model/Gender';
import { UserSfaV1Type, UserV1, UserV1GlobalRole } from '../Services/UserService';
import { Country } from '../Model/Country';
import { Locale } from '../Model/Locale';
import { LocaleService } from '../Services/LocaleService';
import { ContentFileV1Key, ContentV1Key } from '../Services/ContentService';
import { SourceV1Type } from '../Services/SourceService';


interface CachedDisplayNames
{
    locale:         Locale;
    displayNames:   Intl.DisplayNames
}


export class Formatter
{
    private static _cachedCountryNames: CachedDisplayNames | null;


    public static duration ( duration: string | null ): string
    {
        if ( ! duration )
        {
            return '';
        }

        const count = parseInt(duration, 10);

        return  t(`formatter.duration.${duration.toLowerCase()}`, {count});
    }


    public static interval ( duration: string | null ): string
    {
        if ( ! duration )
        {
            return '';
        }

        const count = parseInt(duration, 10);

        return  t(`formatter.interval.${duration.toLowerCase()}`, {count});
    }
    

    public static country ( country: Country | null | undefined ): string
    {
        if ( ! country )
        {
            return '';
        }

        const locale = LocaleService.getInstance().getLocale().get();

        if ( !Formatter._cachedCountryNames ||
             Formatter._cachedCountryNames.locale !== locale )
        {
            Formatter._cachedCountryNames = {
                locale,
                displayNames: new Intl.DisplayNames([locale], {type: 'region'})
            };
        }

        return Formatter._cachedCountryNames.displayNames.of(country) || country;
    }
    

    public static locale ( locale: Locale | null | undefined ): string
    {
        if ( ! locale )
        {
            return '';
        }

        return t(`formatter.locale.${locale.toLowerCase()}`);
    }
    
    
    public static gender ( gender: Gender ): string
    {
        return t(`formatter.gender.${gender.toLowerCase()}`);
    }

    
    public static sfaType ( sfaType: UserSfaV1Type ): string
    {
        return t(`formatter.usersfav1type.${sfaType.toLowerCase()}`);
    }


    public static contentKey ( contentKey: ContentV1Key ): string
    {
        return t(`formatter.contentkey.${contentKey.toLowerCase()}`);
    }


    public static contentFileKey ( contentFileKey: ContentFileV1Key ): string
    {
        return t(`formatter.contentfilekey.${contentFileKey.toLowerCase()}`);
    }

    
    public static sourceType ( sourceType: SourceV1Type ): string
    {
        return t(`formatter.sourcev1type.${sourceType.toLowerCase()}`);
    }


    public static username ( user: UserV1 | null | undefined ): string
    {
        if ( ! user )
        {
            return '???';
        }

        return `${user.firstname} ${user.lastname}`;
    }
    
    
    public static userGlobalRole ( userGlobalRole: UserV1GlobalRole | null | undefined ): string
    {
        if ( ! userGlobalRole )
        {
            return '';
        }

        return t(`formatter.userv1globalrole.${userGlobalRole.toLowerCase()}`);
    }


    public static date ( date: DayJS.Dayjs | string ): string
    {
        return DayJS(date).format('YYYY-MM-DD');
    }
}
