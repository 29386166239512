import React from 'react';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AccountAddSfaPageSfaTotpConfirmStepProps extends WithTranslation
{
    onFinish: ( formData: AccountAddSfaPageSfaTotpConfirmStepFormData ) => any;
}


export interface AccountAddSfaPageSfaTotpConfirmStepFormData
{
    code:   string;
}


interface AccountAddSfaPageSfaTotpConfirmStepState
{
    initialFormData: AccountAddSfaPageSfaTotpConfirmStepFormData;
}


class $AccountAddSfaPageSfaTotpConfirmStep extends React.Component<AccountAddSfaPageSfaTotpConfirmStepProps, AccountAddSfaPageSfaTotpConfirmStepState>
{
    constructor ( props: AccountAddSfaPageSfaTotpConfirmStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                code:   '',
            }
        };

        this._accountaddsfa = this._accountaddsfa.bind(this);
    }


    private async _accountaddsfa ( values: AccountAddSfaPageSfaTotpConfirmStepFormData ): Promise<void>
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<AccountAddSfaPageSfaTotpConfirmStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <MyFormik
                    onSubmit={this._accountaddsfa}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcText size={IcTextSize.Small}>
                            {this.props.t('accountaddsfapagesfatotpconfirmstep.txt_subtitle')}
                        </IcText>
        
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('accountaddsfapagesfatotpconfirmstep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('accountaddsfapagesfatotpconfirmstep.txt_confirm')}
                        </IcText>

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='code'
                                    autoComplete='off'
                                    label={this.props.t('accountaddsfapagesfatotpconfirmstep.inp_code')}
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton type='submit'>
                                {this.props.t('accountaddsfapagesfatotpconfirmstep.btn_confirm')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const AccountAddSfaPageSfaTotpConfirmStep = withTranslation()($AccountAddSfaPageSfaTotpConfirmStep);
