import { IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';


export interface RequiredHintProps
{
}


export const RequiredHint: FunctionComponent<RequiredHintProps> = ( props ) =>
{
    const [t] = useTranslation();

    return (
        <IcText size={IcTextSize.Small} className='RequiredHint'>
            * {t('requiredhint.txt_required')}
        </IcText>
    );
}
