import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserV1 } from '../../Services/UserService';
import { Formatter } from '../../utils/Formatter';
import { IcDummyContent, IcSeparator, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface UserDetailsProps extends WithTranslation
{
    user:   UserV1 | null;
}


class $UserDetails extends React.Component<UserDetailsProps>
{
    public render ( )
    {
        return (
            <div>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('userdetails.txt_user')}
                </IcText>
                
                <IcText>
                    {this.props.user ?
                        Formatter.username(this.props.user)
                    :
                        <IcDummyContent />
                    }
                </IcText>
                        
                <IcText>
                    {this.props.user ?
                        this.props.user.email
                    :
                        <IcDummyContent />
                    }
                </IcText>

                <IcSeparator />
            </div>
        );
    }
}


export const UserDetails = withTranslation()($UserDetails);
