import { FunctionComponent, useEffect } from 'react';
import { IcCard, IcCardPadding, IcPageContent, IcSpinner, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';


export interface HomePageProps
{
}


export const HomePage: FunctionComponent<HomePageProps> = ( props ) =>
{
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect( ( ) =>
    {
        if ( auth.isLoading )
        {
            return;
        }

        if ( auth.userData )
        {
            navigate(LinkUtils.make('account'));

            return;
        }

        auth.signIn();
    }, [auth, navigate]);

    return (
        <IcPageContent>
            <IcCard padding={IcCardPadding.Large}>
                <IcSpinner />
            </IcCard>
        </IcPageContent>
    );
}
