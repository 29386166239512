import React from 'react';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthContextProps, withAuth } from 'oidc-react';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AccountUpdatePasswordPageFormStepProps extends WithTranslation, AuthContextProps
{
    onCancel: ( ) => any;
    onFinish: ( formData: AccountUpdatePasswordPageFormStepFormData ) => any;
}


export interface AccountUpdatePasswordPageFormStepFormData
{
    password_old:       string;
    password_new:       string;
    password_confirm:   string;
}


interface AccountUpdatePasswordPageFormStepState
{
    initialFormData: AccountUpdatePasswordPageFormStepFormData;
}


class $AccountUpdatePasswordPageFormStep extends React.Component<AccountUpdatePasswordPageFormStepProps, AccountUpdatePasswordPageFormStepState>
{
    constructor ( props: AccountUpdatePasswordPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                password_old:       '',
                password_new:       '',
                password_confirm:   ''
            }
        };

        this._submit = this._submit.bind(this);
    }


    private _submit ( values: AccountUpdatePasswordPageFormStepFormData ): void
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<AccountUpdatePasswordPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <MyFormik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('accountupdatepasswordpageformstep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('accountupdatepasswordpageformstep.txt_update_password')}
                        </IcText>

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    type='password'
                                    label={this.props.t('accountupdatepasswordpageformstep.inp_password_old')}
                                    name='password_old'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    type='password'
                                    label={this.props.t('accountupdatepasswordpageformstep.inp_password_new')}
                                    name='password_new'
                                    autoComplete='off'
                                    required={true}
                                    validators={[Validator.password]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    type='password'
                                    label={this.props.t('accountupdatepasswordpageformstep.inp_password_confirm')}
                                    name='password_confirm'
                                    autoComplete='off'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcText>
                                    {this.props.t('validator.password.policy', {length: Validator.PASSWORD_MIN_LENGTH})}
                                </IcText>
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />
                        
                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                onClick={this.props.onCancel}
                                color={IcButtonColor.Link}>
                                {this.props.t('accountupdatepasswordpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton type='submit'>
                                {this.props.t('accountupdatepasswordpageformstep.btn_update')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const AccountUpdatePasswordPageFormStep = withTranslation()(withAuth($AccountUpdatePasswordPageFormStep));
