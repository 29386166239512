import React from 'react';
import { ContentFileV1Key, ContentService } from '../../Services/ContentService';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';

import './ContentImage.css';


export interface ContentImageProps extends WithLocaleProps
{
    contentFileKey: ContentFileV1Key;
}


interface ContentImageState
{
    loaded: boolean;
}


class $ContentImage extends React.Component<ContentImageProps, ContentImageState>
{
    private readonly _contentService:   ContentService;


    constructor ( props: ContentImageProps )
    {
        super(props);

        this.state = {
            loaded: false
        };

        this._contentService = ContentService.getInstance();

        this._loaded = this._loaded.bind(this);
    }


    private _loaded ( ): void
    {
        this.setState({
            loaded: true
        });
    }


    public render ( )
    {
        return (
            <div className={`ContentImage ${this.state.loaded ? 'loaded' : ''}`}>
                <img
                    src={this._contentService.getContentFileDataURI(this.props.contentFileKey, this.props.locale)}
                    alt=''
                    onLoad={this._loaded}
                />
            </div>
        );
    }
}


export const ContentImage = withLocale($ContentImage);
