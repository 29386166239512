import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';

import './SecureConnectionTag.css';


export interface SecureConnectionTagProps
{
}


export const SecureConnectionTag: FunctionComponent<SecureConnectionTagProps> = ( props ) =>
{
    const [t] = useTranslation();

    return (
        <div className='SecureConnectionTag'>
            <IcText size={IcTextSize.Small}>
                <FontAwesomeIcon icon={faLock} />

                <div className='SecureConnectionTag-label'>
                    {t('secureconnectiontag.txt_secure')}
                </div>
            </IcText>
        </div>
    );
}
