import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface LogoutSuccessPageProps extends RouteComponentProps, WithTranslation
{
}


class $LogoutSuccessPage extends React.Component<LogoutSuccessPageProps>
{
    private _timeoutRedirect: any | null;


    constructor ( props: LogoutSuccessPageProps )
    {
        super(props);

        this._timeoutRedirect = null;
    }


    public componentDidMount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
        }

        this._timeoutRedirect = setTimeout( ( ) =>
        {
            this._timeoutRedirect = null;

            this.props.router.navigate(LinkUtils.make());
        }, 500);
    }


    public componentWillUnmount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
            this._timeoutRedirect = null;
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcCard padding={IcCardPadding.Small}>
                    {this.props.t('logoutsuccesspage.txt_success')}
                </IcCard>
            </IcPageContent>
        );
    }
}


export const LogoutSuccessPage = withTranslation()(withRouter($LogoutSuccessPage));
