import React from 'react';
import Cookies from 'universal-cookie';
import { LoginPageCredentialsStep, LoginPageCredentialsStepFormData } from './LoginPageCredentialsStep/LoginPageCredentialsStep';
import { LoginPageSuccessStep } from './LoginPageSuccessStep/LoginPageSuccessStep';
import { Delay } from 'ts-delay';
import { LoginPageLoadingStep } from './LoginPageLoadingStep/LoginPageLoadingStep';
import { LoginPageSfaTotpStep, LoginPageSfaTotpStepFormData } from './LoginPageSfaTotpStep/LoginPageSfaTotpStep';
import { LoginPageSfaEmailStep, LoginPageSfaEmailStepFormData } from './LoginPageSfaEmailStep/LoginPageSfaEmailStep';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { RespLoginSfaV1, UserService, UserSfaV1, UserSfaV1Type } from '../../Services/UserService';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LoginPageSfaStep } from './LoginPageSfaStep/LoginPageSfaStep';
import { LoginPageSfaWebAuthNStep } from './LoginPageSfaWebAuthNStep/LoginPageSfaWebAuthNStep';
import { LoginPageSfaRecoveryStep, LoginPageSfaRecoveryStepFormData } from './LoginPageSfaRecoveryStep/LoginPageSfaRecoveryStep';
import { AuthContextProps, withAuth } from 'oidc-react';
import { ErrorCode, ErrorWithCode } from '../../utils/ErrorTranslator';
import { IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { LoginPageWebAuthNOnlyStep } from './LoginPageWebAuthNOnlyStep/LoginPageWebAuthNOnlyStep';
import { WebAuthN, WebAuthNType } from '@indece-common/ic-ui-lib-webauthn';


export interface LoginPageProps extends RouteComponentProps, WithTranslation, AuthContextProps
{
}


interface LoginPageFormData
{
    email:      string;
    password:   string;
}


enum LoginPageStep
{
    Credentials     = 'CREDENTIALS',
    WebAuthNOnly    = 'WEBAUTHN_ONLY',
    Sfa             = 'SFA',
    SfaEmail        = 'SFA_EMAIL',
    SfaWebAuthN     = 'SFA_WEBAUTHN',
    SfaTotp         = 'SFA_TOTP',
    SfaRecovery     = 'SFA_RECOVERY',
    Loading         = 'LOADING',
    Success         = 'SUCCESS'
}


interface LoginPageState
{
    formData:           LoginPageFormData;
    clientID:           string | null;
    sfas:               Array<UserSfaV1>;
    availableSfaTypes:  Array<UserSfaV1Type>;
    sfa:                UserSfaV1 | null;
    loginSfa:           RespLoginSfaV1 | null;
    redirectURI:        string | null;
    step:               LoginPageStep;
    error:              Error | null;
}


class $LoginPage extends React.Component<LoginPageProps, LoginPageState>
{
    private readonly _userService:  UserService;
    private readonly _webAuthN:     WebAuthN;
    private readonly _cookies:      Cookies;


    constructor ( props: LoginPageProps )
    {
        super(props);

        this.state = {
            formData: {
                email:          '',
                password:       ''
            },
            clientID:           null,
            sfas:               [],
            availableSfaTypes:  [],
            sfa:                null,
            loginSfa:           null,
            redirectURI:        null,
            step:               LoginPageStep.Credentials,
            error:              null
        };

        this._userService = UserService.getInstance();
        this._webAuthN = new WebAuthN();
        this._cookies = new Cookies(null, {path: '/'});

        this._finishCredentials = this._finishCredentials.bind(this);
        this._finishWebAuthNOnly = this._finishWebAuthNOnly.bind(this);
        this._finishSfa = this._finishSfa.bind(this);
        this._finishSfaEmail = this._finishSfaEmail.bind(this);
        this._finishSfaTotp = this._finishSfaTotp.bind(this);
        this._finishSfaWebAuthN = this._finishSfaWebAuthN.bind(this);
        this._finishSfaRecovery = this._finishSfaRecovery.bind(this);
        this._regularLogin = this._regularLogin.bind(this);
        this._otherSfa = this._otherSfa.bind(this);
        this._setError = this._setError.bind(this);
    }


    private async _finishCredentials ( formData: LoginPageCredentialsStepFormData | null, webauth: boolean ): Promise<void>
    {
        try
        {
            if ( webauth )
            {
                this.setState({
                    step:   LoginPageStep.Loading,
                    error:  null
                });

                // TODO

                this.setState({
                    step:   LoginPageStep.WebAuthNOnly
                });

                return;
            }

            if ( ! formData )
            {
                throw new Error('Empty form data');
            }

            this.setState({
                formData: {
                    email:      formData.email,
                    password:   formData.password
                },
                step:   LoginPageStep.Loading,
                sfa:    null,
                error:  null
            });

            const resp = await this._userService.login({
                email:          formData.email,
                password:       formData.password,
                stay_loggedin:  formData.stay_loggedin
            });

            if ( resp.sfa_required )
            {
                const webAuthNTypes = await this._webAuthN.checkBrowserSupport();

                const sfaTypes: Array<UserSfaV1Type> = [];
                for ( const webAuthNType of webAuthNTypes )
                {
                    switch ( webAuthNType )
                    {
                        case WebAuthNType.Platform:
                            sfaTypes.push(UserSfaV1Type.WebAuthNPlatform);
                            break;
                        case WebAuthNType.CrossPlatform:
                            sfaTypes.push(UserSfaV1Type.WebAuthNCrossPlatform);
                            break;
                    }
                }

                this.setState({
                    sfas:   resp.sfas,
                    availableSfaTypes: [
                        ...sfaTypes,
                        UserSfaV1Type.Totp,
                        UserSfaV1Type.Email,
                        UserSfaV1Type.Recovery
                    ],
                    step:   LoginPageStep.Sfa
                });

                return;
            }
            else
            {
                this._cookies.set('ic-licenser-signedin', '1', {
                    path:       '/',
                    secure:     false,
                    httpOnly:   false
                });

                this.setState({
                    step:           LoginPageStep.Success,
                    redirectURI:    resp.redirect_uri || LinkUtils.make('account')
                });

                return;
            }
        }
        catch ( err )
        {
            console.error(`Error login in: ${(err as Error).message}`, err);

            if ( ErrorWithCode.hasCode(err as Error, ErrorCode.SessionStatusInvalid) )
            {
                // Restart signin 
                await this.props.signIn();

                return;
            }

            this.setState({
                step:   LoginPageStep.Credentials,
                error:  err as Error
            });
        }
    }


    private async _finishWebAuthNOnly ( ): Promise<void>
    {
        // TODO
    }


    private async _finishSfa ( sfa: UserSfaV1 ): Promise<void>
    {
        const delay = new Delay(1000);

        try
        {
            this.setState({
                step:   LoginPageStep.Loading,
                sfa,
                error:  null
            });

            const loginSfa = await this._userService.loginSfa({
                sfa_uid:    sfa.uid
            });

            await delay.sleep();

            let step: LoginPageStep;

            switch ( sfa.type )
            {
                case UserSfaV1Type.Email:
                    step = LoginPageStep.SfaEmail;
                    break;
                case UserSfaV1Type.Totp:
                    step = LoginPageStep.SfaTotp;
                    break;
                case UserSfaV1Type.WebAuthNPlatform:
                case UserSfaV1Type.WebAuthNCrossPlatform:
                    step = LoginPageStep.SfaWebAuthN;
                    break;
                case UserSfaV1Type.Recovery:
                    step = LoginPageStep.SfaRecovery;
                    break;
                default:
                    throw new Error('Unsupported 2FA-Method');
            }

            this.setState({
                formData:   {
                    ...this.state.formData
                },
                loginSfa,
                step
            });
        }
        catch ( err )
        {
            console.error(`Error triggering sfa for login: ${(err as Error).message}`, err);

            if ( ErrorWithCode.hasCode(err as Error, ErrorCode.SessionStatusInvalid) )
            {
                // Restart signin 
                await this.props.signIn();

                return;
            }
            
            await delay.sleep();

            this.setState({
                step:   LoginPageStep.Sfa,
                error:  err as Error
            });
        }
    }


    private async _finishSfaEmail ( formData: LoginPageSfaEmailStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   LoginPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            const resp = await this._userService.loginConfirmSfa({
                code:   formData.code
            });

            this._cookies.set('ic-licenser-signedin', '1', {
                path:       '/',
                secure:     false,
                httpOnly:   false
            });

            await delay.sleep();

            this.setState({
                step:           LoginPageStep.Success,
                redirectURI:    resp.redirect_uri || LinkUtils.make('account')
            });
        }
        catch ( err )
        {
            console.error(`Error login in: ${(err as Error).message}`, err);

            if ( ErrorWithCode.hasCode(err as Error, ErrorCode.SessionStatusInvalid) )
            {
                // Restart signin 
                await this.props.signIn();

                return;
            }
            
            this.setState({
                step:   LoginPageStep.SfaEmail,
                error:  err as Error
            });
        }
    }


    private async _finishSfaTotp ( formData: LoginPageSfaTotpStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   LoginPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            const resp = await this._userService.loginConfirmSfa({
                code:   formData.code
            });

            this._cookies.set('ic-licenser-signedin', '1', {
                path:       '/',
                secure:     false,
                httpOnly:   false
            });

            await delay.sleep();

            this.setState({
                step:           LoginPageStep.Success,
                redirectURI:    resp.redirect_uri || LinkUtils.make('account')
            });
        }
        catch ( err )
        {
            console.error(`Error login in: ${(err as Error).message}`, err);

            if ( ErrorWithCode.hasCode(err as Error, ErrorCode.SessionStatusInvalid) )
            {
                // Restart signin 
                await this.props.signIn();

                return;
            }

            this.setState({
                step:   LoginPageStep.SfaTotp,
                error:  err as Error
            });
        }
    }


    private async _finishSfaWebAuthN ( webauthnAuthData: string, webauthnClientDataJSON: string, webauthnSignature: string ): Promise<void>
    {
        try
        {
            this.setState({
                step:   LoginPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            const resp = await this._userService.loginConfirmSfa({
                webauthn_authdata:          webauthnAuthData,
                webauthn_clientdatajson:    webauthnClientDataJSON,
                webauthn_signature:         webauthnSignature
            });

            this._cookies.set('ic-licenser-signedin', '1', {
                path:       '/',
                secure:     false,
                httpOnly:   false
            });

            await delay.sleep();

            this.setState({
                step:           LoginPageStep.Success,
                redirectURI:    resp.redirect_uri || LinkUtils.make('account')
            });
        }
        catch ( err )
        {
            console.error(`Error login in: ${(err as Error).message}`, err);

            if ( ErrorWithCode.hasCode(err as Error, ErrorCode.SessionStatusInvalid) )
            {
                // Restart signin 
                await this.props.signIn();

                return;
            }

            this.setState({
                step:   LoginPageStep.SfaWebAuthN,
                error:  err as Error
            });
        }
    }


    private async _finishSfaRecovery ( formData: LoginPageSfaRecoveryStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   LoginPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            const resp = await this._userService.loginConfirmSfa({
                code:   formData.code
            });

            this._cookies.set('ic-licenser-signedin', '1', {
                path:       '/',
                secure:     false,
                httpOnly:   false
            });

            await delay.sleep();

            this.setState({
                step:           LoginPageStep.Success,
                redirectURI:    resp.redirect_uri || LinkUtils.make('account')
            });
        }
        catch ( err )
        {
            console.error(`Error login in: ${(err as Error).message}`, err);

            if ( ErrorWithCode.hasCode(err as Error, ErrorCode.SessionStatusInvalid) )
            {
                // Restart signin 
                await this.props.signIn();

                return;
            }

            this.setState({
                step:   LoginPageStep.SfaRecovery,
                error:  err as Error
            });
        }
    }


    private _regularLogin ( ): void
    {
        this.setState({
            step: LoginPageStep.Credentials
        });
    }


    private _otherSfa ( ): void
    {
        this.setState({
            step: LoginPageStep.Sfa
        });
    }


    private _setError ( error: Error | null ): void
    {
        this.setState({
            error
        });
    }


    public async componentDidMount ( ): Promise<void>
    {
        const search = new URLSearchParams(this.props.router.location.search);
        const clientID = search.get('client_id');

        if ( !clientID )
        {
            await this.props.signIn();

            return;
        }

        this.props.router.navigate(
            {
                pathname: this.props.router.location.pathname,
                search: '?',
            },
            {
                replace: true
            }
        );

        this.setState({
            clientID: clientID || null
        });
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('loginpage.txt_login')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === LoginPageStep.Loading ?
                    <LoginPageLoadingStep />
                : null}
                
                {this.state.step === LoginPageStep.Credentials ?
                    <LoginPageCredentialsStep
                        onFinish={this._finishCredentials}
                    />
                : null}
                
                {this.state.step === LoginPageStep.WebAuthNOnly ?
                    <LoginPageWebAuthNOnlyStep
                        onFinish={this._finishWebAuthNOnly}
                        onError={this._setError}
                        onRegularLogin={this._regularLogin}
                    />
                : null}
                
                {this.state.step === LoginPageStep.Sfa ?
                    <LoginPageSfaStep
                        sfas={this.state.sfas}
                        availableSfaTypes={this.state.availableSfaTypes}
                        onFinish={this._finishSfa}
                    />
                : null}

                {this.state.step === LoginPageStep.SfaEmail ?
                    <LoginPageSfaEmailStep
                        onFinish={this._finishSfaEmail}
                        onOtherSfa={this._otherSfa}
                    />
                : null}

                {this.state.step === LoginPageStep.SfaTotp ?
                    <LoginPageSfaTotpStep
                        onFinish={this._finishSfaTotp}
                        onOtherSfa={this._otherSfa}
                    />
                : null}
                
                {this.state.step === LoginPageStep.SfaWebAuthN && this.state.sfa && this.state.loginSfa ?
                    <LoginPageSfaWebAuthNStep
                        sfa={this.state.sfa}
                        loginSfa={this.state.loginSfa}
                        onFinish={this._finishSfaWebAuthN}
                        onError={this._setError}
                        onOtherSfa={this._otherSfa}
                    />
                : null}
                
                {this.state.step === LoginPageStep.SfaRecovery ?
                    <LoginPageSfaRecoveryStep
                        onFinish={this._finishSfaRecovery}
                        onOtherSfa={this._otherSfa}
                    />
                : null}

                {this.state.step === LoginPageStep.Success && this.state.redirectURI ?
                    <LoginPageSuccessStep
                        redirectURI={this.state.redirectURI}
                    />
                : null}
            </IcPageContent>
        );
    }
}


export const LoginPage = withTranslation()(withAuth(withRouter($LoginPage)));
