import { BackendService } from './BackendService';


export enum SourceV1Type
{
    Local   = 'LOCAL',
    Ldap    = 'LDAP'
}


export const SourceV1Types: Array<SourceV1Type> = [
    SourceV1Type.Local,
    SourceV1Type.Ldap
];


export interface SourceV1ConfigLdap
{
    uri:                        string;
    bind_user:                  string | null;
    bind_password:              string | null;
    user_filter_pattern:        string | null;
    user_username_attribute:    string;
    user_firstname_attribute:   string;
    user_lastname_attribute:    string;
    user_email_attribute:       string;
    user_base_dn:               string;
}


export interface SourceV1Config
{
    registration_enabled:   boolean;
    auto_assign_enabled:    boolean;
    ldap?:                  SourceV1ConfigLdap | null;
}


export interface SourceV1
{
    uid:        string;
    type:       SourceV1Type;
    name:       string;
    config:     SourceV1Config;
    enabled:    boolean;
}


export interface AddSourceV1
{
    type:       SourceV1Type;
    name:       string;
    config:     SourceV1Config;
    enabled:    boolean;
}


export interface UpdateSourceV1
{
    name:       string;
    config:     SourceV1Config;
    enabled:    boolean;
}


export class SourceService
{
    private static _instance: SourceService;
    
    
    public static getInstance ( ): SourceService
    {
        if ( ! this._instance )
        {
            this._instance = new SourceService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getSources ( from: number, size: number ): Promise<Array<SourceV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/source?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.sources;
    }


    public async getSource ( sourceUID: string ): Promise<SourceV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/source/${encodeURIComponent(sourceUID)}`
        );

        return resp.source;
    }
    

    public async addSource ( params: AddSourceV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/source`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.source_uid;
    }
    

    public async updateSource ( sourceUID: string, params: UpdateSourceV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/source/${encodeURIComponent(sourceUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deleteSource ( sourceUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/source/${encodeURIComponent(sourceUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
