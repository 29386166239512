import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GroupV1 } from '../../../Services/GroupService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface AdminGroupDeletePageConfirmStepProps extends WithTranslation
{
    group:      GroupV1;
    onCancel:   ( ) => any;
    onFinish:   ( ) => any;
}


class $AdminGroupDeletePageConfirmStep extends React.Component<AdminGroupDeletePageConfirmStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('admingroupdeletepageconfirmstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('admingroupdeletepageconfirmstep.txt_confirm')}
                </IcText>

                <List>
                    <ListItem>
                        <ListItemHeader>
                            <ListItemHeaderField
                                grow={true}
                                text={this.props.group.key}
                            />

                            <ListItemHeaderField
                                grow={true}
                                text={this.props.group.name}
                            />
                        </ListItemHeader>
                    </ListItem>
                </List>

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this.props.onCancel}
                        testID='admingroupdeletepageconfirmstep.btn_cancel'>
                        {this.props.t('admingroupdeletepageconfirmstep.btn_cancel')}
                    </IcButton>

                    <IcButton
                        onClick={this.props.onFinish}
                        testID='admingroupdeletepageconfirmstep.btn_delete'>
                        {this.props.t('admingroupdeletepageconfirmstep.btn_delete')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AdminGroupDeletePageConfirmStep = withTranslation()($AdminGroupDeletePageConfirmStep);
