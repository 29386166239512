import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcSpinner } from '@indece-common/ic-ui-lib-react';


export interface AdminGroupDeletePageLoadingStepProps extends WithTranslation
{
}


class $AdminGroupDeletePageLoadingStep extends React.Component<AdminGroupDeletePageLoadingStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcSpinner
                    message={this.props.t('admingroupdeletepageloadingstep.txt_loading')}
                />
            </IcCard>
        );
    }
}


export const AdminGroupDeletePageLoadingStep = withTranslation()($AdminGroupDeletePageLoadingStep);
