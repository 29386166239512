import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { RespAddSfaV1 } from '../../../Services/UserService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcCode, IcFloatRow, IcFloatRowAlign, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface AccountAddSfaPageSfaTotpStepProps extends WithTranslation
{
    sfa:        RespAddSfaV1;
    onFinish:   ( ) => any;
}


interface AccountAddSfaPageSfaTotpStepState
{
    showCode:   boolean;
}


class $AccountAddSfaPageSfaTotpStep extends React.Component<AccountAddSfaPageSfaTotpStepProps, AccountAddSfaPageSfaTotpStepState>
{
    constructor ( props: AccountAddSfaPageSfaTotpStepProps )
    {
        super(props);

        this.state = {
            showCode:   false
        };

        this._showCode = this._showCode.bind(this);
    }


    private _showCode ( ): void
    {
        this.setState({
            showCode:   true
        })
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small}>
                    {this.props.t('accountaddsfapagesfatotpstep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('accountaddsfapagesfatotpstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('accountaddsfapagesfatotpstep.txt_totp')}
                </IcText>

                <QRCode
                    value={this.props.sfa.totp_provisioning_uri || ''}
                />

                {!this.state.showCode?
                    <IcButton
                        color={IcButtonColor.Link}
                        onClick={this._showCode}>
                        {this.props.t('accountaddsfapagesfatotpstep.btn_show_code')}
                    </IcButton>
                : 
                    <IcCode
                        code={this.props.sfa.totp_secret || ''}
                    />
                }

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        onClick={ ( ) => this.props.onFinish()}>
                        {this.props.t('accountaddsfapagesfatotpstep.txt_continue')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AccountAddSfaPageSfaTotpStep = withTranslation()($AccountAddSfaPageSfaTotpStep);
