import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ResetPasswordSfaV1Response, UserSfaV1 } from '../../../Services/UserService';
import { Formatter } from '../../../utils/Formatter';
import { IcCard, IcCardPadding, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface ResetPasswordPageSfaWebAuthNStepProps extends WithTranslation
{
    sfa:            UserSfaV1;
    sfaResponse:    ResetPasswordSfaV1Response;
    onFinish:       ( webauthnAuthData: string, webauthnClientDataJSON: string, webauthnSignature: string ) => any;
    onError:        ( err: Error | null ) => any;
}


class $ResetPasswordPageSfaWebAuthNStep extends React.Component<ResetPasswordPageSfaWebAuthNStepProps>
{
    private _active:    boolean;


    constructor ( props: ResetPasswordPageSfaWebAuthNStepProps )
    {
        super(props);

        this._verify = this._verify.bind(this);

        this._active = false;
    }


    private async _verify ( ): Promise<void>
    {
        if ( this._active )
        {
            return;
        }

        try
        {
            this._active = true;

            this.props.onError(null);

            if ( !this.props.sfaResponse.webauthn_challenge || !this.props.sfaResponse.webauthn_credential_id )
            {
                throw new Error('No challenge or credential id provided');
            }
        
            const credentialID = Uint8Array.from(atob(this.props.sfaResponse.webauthn_credential_id), c => c.charCodeAt(0));
            const challenge = Uint8Array.from(atob(this.props.sfaResponse.webauthn_challenge), c => c.charCodeAt(0));

            const response = await navigator.credentials.get({
                publicKey: {
                    challenge,
                    allowCredentials: [{
                        id: credentialID,
                        type: 'public-key',
                        transports: ['usb', 'ble', 'nfc', 'hybrid', 'internal'],
                    }],
                    timeout: 60000,
                    userVerification: "discouraged"
                }
            });

            if ( ! response )
            {
                this._active = false;

                return;
            }

            const authResponse = ((response as PublicKeyCredential).response as AuthenticatorAssertionResponse);
            const signature = authResponse.signature;

            this.props.onFinish(
                btoa(String.fromCharCode(...new Uint8Array(authResponse.authenticatorData))),
                btoa(String.fromCharCode(...new Uint8Array(authResponse.clientDataJSON))),
                btoa(String.fromCharCode(...new Uint8Array(signature!)))
            );

            this._active = false;
        }
        catch ( err )
        {
            console.error(`Error getting credential: ${(err as Error).message}`, err);

            this.props.onError(err as Error);

            this._active = false;
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._verify();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small}>
                    {this.props.t('resetpasswordpagesfawebauthnstep.txt_subtitle')}
                </IcText>
                
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('resetpasswordpagesfawebauthnstep.txt_title', {sfatype: Formatter.sfaType(this.props.sfa.type)})}
                </IcText>

                <IcText>
                    {this.props.t('resetpasswordpagesfawebauthnstep.txt_webauthn')}
                </IcText>

                <IcSpinner />
            </IcCard>
        );
    }
}


export const ResetPasswordPageSfaWebAuthNStep = withTranslation()($ResetPasswordPageSfaWebAuthNStep);
