import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcInputRadio, IcText } from '@indece-common/ic-ui-lib-react';
import { SourceV1Type, SourceV1Types } from '../../../Services/SourceService';
import { Formatter } from '../../../utils/Formatter';


export interface AdminSourceAddPageTypeStepProps
{
    onFinish: ( type: SourceV1Type ) => any;
}


export const AdminSourceAddPageTypeStep: FunctionComponent<AdminSourceAddPageTypeStepProps> = ( props ) =>
{
    const [t] = useTranslation();

    return (
        <IcCard padding={IcCardPadding.Large}>
            <IcText>
                {t('adminsourceaddpagetypestep.txt_info')}
            </IcText>

            <IcFloatRow align={IcFloatRowAlign.Center}>
                {SourceV1Types.map( ( type ) => (
                    <IcInputRadio
                        key={type}
                        onClick={ ( ) => props.onFinish(type)}
                        value=''
                        label={Formatter.sourceType(type)}
                    />
                ))}
            </IcFloatRow>
        </IcCard>
    );
}
