import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApiClientService } from '../../Services/ApiClientService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminApiClientAddPageLoadingStep } from './AdminApiClientAddPageLoadingStep/AdminApiClientAddPageLoadingStep';
import { AdminApiClientAddPageSuccessStep } from './AdminApiClientAddPageSuccessStep/AdminApiClientAddPageSuccessStep';
import { AdminApiClientAddPageFormStep, AdminApiClientAddPageFormStepFormData } from './AdminApiClientAddPageFormStep/AdminApiClientAddPageFormStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminApiClientAddPageProps extends RouteComponentProps, WithTranslation
{
}


enum AdminApiClientAddPageStep
{
    Loading = 'LOADING',
    Form    = 'FORM',
    Success = 'SUCCESS'
}


interface AdminApiClientAddPageState
{
    step:   AdminApiClientAddPageStep;
    error:  Error | null;
}


class $AdminApiClientAddPage extends React.Component<AdminApiClientAddPageProps, AdminApiClientAddPageState>
{
    private readonly _apiClientService: ApiClientService;
    

    constructor ( props: AdminApiClientAddPageProps )
    {
        super(props);

        this.state = {
            step:           AdminApiClientAddPageStep.Form,
            error:          null
        };

        this._apiClientService = ApiClientService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: AdminApiClientAddPageFormStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminApiClientAddPageStep.Loading,
                error:  null
            });

            await this._apiClientService.addApiClient(
                {
                    name:       formData.name.trim(),
                    source_uid: formData.source_uid,
                    config:     {}
                }
            );

            this.setState({
                step:   AdminApiClientAddPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error adding apiclient: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminApiClientAddPageStep.Form,
                error:  err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('adminapiclientaddpage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminApiClientAddPageStep.Loading ?
                    <AdminApiClientAddPageLoadingStep />
                : null}

                {this.state.step === AdminApiClientAddPageStep.Form ?
                    <AdminApiClientAddPageFormStep
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === AdminApiClientAddPageStep.Success ?
                    <AdminApiClientAddPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminApiClientAddPage = withTranslation()(withRouter($AdminApiClientAddPage));
