import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RespAddSfaV1, UserSfaV1Type } from '../../../Services/UserService';
import { AuthContextProps, withAuth } from 'oidc-react';
import { Environment } from '../../../utils/Environment';
import { IcCard, IcCardPadding, IcSpinner } from '@indece-common/ic-ui-lib-react';
import { WebAuthN, WebAuthNType } from '@indece-common/ic-ui-lib-webauthn';


export interface AccountAddSfaPageSfaWebAuthNStepProps extends WithTranslation, AuthContextProps
{
    sfa:        RespAddSfaV1;
    sfaType:    UserSfaV1Type;
    onFinish:   ( webauthnCredentialID: string, publicKey: string, publicKeyAlogrithm: number, attestationObject: string ) => any;
    onError:    ( err: Error | null ) => any;
}


interface AccountAddSfaPageSfaWebAuthNStepState
{
}


class $AccountAddSfaPageSfaWebAuthNStep extends React.Component<AccountAddSfaPageSfaWebAuthNStepProps, AccountAddSfaPageSfaWebAuthNStepState>
{
    private readonly _webAuthN: WebAuthN;
    private _active:            boolean;


    constructor ( props: AccountAddSfaPageSfaWebAuthNStepProps )
    {
        super(props);

        this._generate = this._generate.bind(this);

        this._webAuthN = new WebAuthN();
        this._active = false;
    }


    private async _generate ( ): Promise<void>
    {
        if ( this._active )
        {
            return;
        }

        this._active = true;

        try
        {
            this.props.onError(null);

            if ( !this.props.userData ||
                !this.props.sfa.webauthn_challenge )
            {
                throw new Error('No user data or challenge provided');
            }

            const credential = await this._webAuthN.createCredentials(
                this.props.sfa.webauthn_challenge,
                {
                    uid: this.props.sfa.sfa_uid,
                    email: this.props.userData.profile.email || '',
                    name: this.props.userData.profile.name || '',
                },
                {
                    name: Environment.server.name,
                    hostname: window.location.hostname,
                },
                {
                    type: this.props.sfaType === UserSfaV1Type.WebAuthNCrossPlatform ? WebAuthNType.CrossPlatform : WebAuthNType.Platform,
                    userVerification: 'preferred',
                    residentKey: 'discouraged'
                }
            );

            if ( ! credential )
            {
                this._active = false;

                return;
            }

            const publicKey = credential.response.getPublicKey();

            const attestationObjectBase64 = btoa(String.fromCharCode(...new Uint8Array(credential.response.attestationObject)));
            const credentialIDBase64 = btoa(String.fromCharCode(...new Uint8Array(credential.rawId)));

            this.props.onFinish(
                credentialIDBase64,
                btoa(String.fromCharCode(...new Uint8Array(publicKey!))),
                credential.response.getPublicKeyAlgorithm(),
                attestationObjectBase64
            );
        }
        catch ( err )
        {
            console.error(`Error creating credential: ${(err as Error).message}`, err);

            this.props.onError(err as Error);
        }
        finally
        {
            this._active = false;
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._generate();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcSpinner />
            </IcCard>
        );
    }
}


export const AccountAddSfaPageSfaWebAuthNStep = withTranslation()(withAuth($AccountAddSfaPageSfaWebAuthNStep));
