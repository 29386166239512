import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ClientService, ClientV1 } from '../../Services/ClientService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminClientDeletePageLoadingStep } from './AdminClientDeletePageLoadingStep/AdminClientDeletePageLoadingStep';
import { AdminClientDeletePageSuccessStep } from './AdminClientDeletePageSuccessStep/AdminClientDeletePageSuccessStep';
import { AdminClientDeletePageConfirmStep } from './AdminClientDeletePageConfirmStep/AdminClientDeletePageConfirmStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminClientDeletePageRouteParams
{
    clientUID: string;
}


export interface AdminClientDeletePageProps extends RouteComponentProps<AdminClientDeletePageRouteParams>, WithTranslation
{
}


enum AdminClientDeletePageStep
{
    Loading = 'LOADING',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS'
}


interface AdminClientDeletePageState
{
    step:   AdminClientDeletePageStep;
    client: ClientV1 | null;
    error:  Error | null;
}


class $AdminClientDeletePage extends React.Component<AdminClientDeletePageProps, AdminClientDeletePageState>
{
    private readonly _clientService:  ClientService;
    

    constructor ( props: AdminClientDeletePageProps )
    {
        super(props);

        this.state = {
            step:   AdminClientDeletePageStep.Loading,
            client: null,
            error:  null
        };

        this._clientService = ClientService.getInstance();

        this._cancel        = this._cancel.bind(this);
        this._finishConfirm = this._finishConfirm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminClientDeletePageStep.Loading,
                error:  null
            });

            const client = await this._clientService.getClient(this.props.router.params.clientUID);

            this.setState({
                step:   AdminClientDeletePageStep.Confirm,
                client
            });
        }
        catch ( err )
        {
            console.error(`Error loading client: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishConfirm ( ): Promise<void>
    {
        if ( ! this.state.client )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminClientDeletePageStep.Loading,
                error:  null
            });

            await this._clientService.deleteClient(this.state.client.uid);

            this.setState({
                step:   AdminClientDeletePageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error deleting client: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminClientDeletePageStep.Confirm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminclientdeletepage.txt_delete_client')} />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminClientDeletePageStep.Loading ?
                    <AdminClientDeletePageLoadingStep />
                : null}

                {this.state.step === AdminClientDeletePageStep.Confirm && this.state.client ?
                    <AdminClientDeletePageConfirmStep
                        client={this.state.client}
                        onCancel={this._cancel}
                        onFinish={this._finishConfirm}
                    />
                : null}

                {this.state.step === AdminClientDeletePageStep.Success ?
                    <AdminClientDeletePageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminClientDeletePage = withTranslation()(withRouter($AdminClientDeletePage));
