import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ResetPasswordSfaV1Response, UserService, UserSfaV1, UserSfaV1Type } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { ResetPasswordPageLoadingStep } from './ResetPasswordPageLoadingStep/ResetPasswordPageLoadingStep';
import { ResetPasswordPageSuccessStep } from './ResetPasswordPageSuccessStep/ResetPasswordPageSuccessStep';
import { ResetPasswordPageEmailStep, ResetPasswordPageEmailStepFormData } from './ResetPasswordPageEmailStep/ResetPasswordPageEmailStep';
import { Delay } from 'ts-delay';
import { ResetPasswordPageCodeStep, ResetPasswordPageCodeStepFormData } from './ResetPasswordPageCodeStep/ResetPasswordPageCodeStep';
import { ResetPasswordPageSfaEmailStep, ResetPasswordPageSfaEmailStepFormData } from './ResetPasswordPageSfaEmailStep/ResetPasswordPageSfaEmailStep';
import { ResetPasswordPageSfaTotpStep, ResetPasswordPageSfaTotpStepFormData } from './ResetPasswordSfaTotpStep/ResetPasswordSfaTotpStep';
import { ResetPasswordPageSfaRecoveryStep, ResetPasswordPageSfaRecoveryStepFormData } from './ResetPasswordPageSfaRecoveryStep/ResetPasswordPageSfaRecoveryStep';
import { ResetPasswordPageSfaStep } from './ResetPasswordSfaStep/ResetPasswordSfaStep';
import { ResetPasswordPageSfaWebAuthNStep } from './ResetPasswordSfaWebAuthNStep/ResetPasswordSfaWebAuthNStep';
import { IcPageContent } from '@indece-common/ic-ui-lib-react';


export interface ResetPasswordPageProps extends WithTranslation
{
}


enum ResetPasswordPageStep
{
    Loading     = 'LOADING',
    Email       = 'EMAIL',
    Code        = 'CODE',
    Sfa         = 'SFA',
    SfaEmail    = 'SFA_EMAIL',
    SfaWebAuthN = 'SFA_WEBAUTHN',
    SfaTotp     = 'SFA_TOTP',
    SfaRecovery = 'SFA_RECOVERY',
    Success     = 'SUCCESS'
}


interface ResetPasswordPageState
{
    step:           ResetPasswordPageStep;
    email:          string | null;
    token:          string | null;
    sfas:           Array<UserSfaV1>;
    sfa:            UserSfaV1 | null;
    sfaResponse:    ResetPasswordSfaV1Response | null;
    error:          Error | null;
}


class $ResetPasswordPage extends React.Component<ResetPasswordPageProps, ResetPasswordPageState>
{
    private readonly _userService:  UserService;


    constructor ( props: ResetPasswordPageProps )
    {
        super(props);

        this.state = {
            step:           ResetPasswordPageStep.Email,
            email:          null,
            token:          null,
            sfas:           [],
            sfa:            null,
            sfaResponse:    null,
            error:          null
        };

        this._userService = UserService.getInstance();
    
        this._setError = this._setError.bind(this);
        this._finishEmail = this._finishEmail.bind(this);
        this._finishCode = this._finishCode.bind(this);
        this._finishSfa = this._finishSfa.bind(this);
        this._finishSfaEmail = this._finishSfaEmail.bind(this);
        this._finishSfaTotp = this._finishSfaTotp.bind(this);
        this._finishSfaWebAuthN = this._finishSfaWebAuthN.bind(this);
        this._finishSfaRecovery = this._finishSfaRecovery.bind(this);
    }


    private _setError ( error: Error | null ): void
    {
        this.setState({
            error
        });
    }


    private async _finishEmail ( formData: ResetPasswordPageEmailStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   ResetPasswordPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            this._userService.resetPassword({
                email:  formData.email.trim()
            });

            await delay.sleep();

            this.setState({
                step:   ResetPasswordPageStep.Code,
                email:  formData.email.trim(),
                error:  null
            });
        }
        catch ( err)
        {
            console.error(`Error resetting password: ${(err as Error).message}`, err);

            this.setState({
                step:   ResetPasswordPageStep.Email,
                error:  err as Error
            });
        }
    }


    private async _finishCode ( formData: ResetPasswordPageCodeStepFormData ): Promise<void>
    {
        try
        {
            if ( ! this.state.email )
            {
                throw new Error('Email not set');
            }

            this.setState({
                step:   ResetPasswordPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            const resp = await this._userService.resetPasswordConfirm({
                email:          this.state.email,
                code:           formData.code.trim(),
                new_password:   formData.password.trim(),
            });

            await delay.sleep();

            if ( ! resp.sfa_required )
            {
                this.setState({
                    step:   ResetPasswordPageStep.Success,
                    token:  resp.resetpassword_token,
                    sfas:   resp.sfas,
                    error:  null
                });

                return;
            }

            this.setState({
                step:   ResetPasswordPageStep.Sfa,
                token:  resp.resetpassword_token,
                sfas:   resp.sfas,
                error:  null
            });
        }
        catch ( err)
        {
            console.error(`Error confirming password reset: ${(err as Error).message}`, err);

            this.setState({
                step:   ResetPasswordPageStep.Code,
                error:  err as Error
            });
        }
    }


    private async _finishSfa ( sfa: UserSfaV1 ): Promise<void>
    {
        const delay = new Delay(1000);

        try
        {
            if ( ! this.state.token )
            {
                throw new Error('No token loaded');
            }

            this.setState({
                step:   ResetPasswordPageStep.Loading,
                sfa,
                error:  null
            });

            const sfaResponse = await this._userService.resetPasswordSfa({
                resetpassword_token:    this.state.token,
                sfa_uid:                sfa.uid
            });

            await delay.sleep();

            let step: ResetPasswordPageStep;

            switch ( sfa.type )
            {
                case UserSfaV1Type.Email:
                    step = ResetPasswordPageStep.SfaEmail;
                    break;
                case UserSfaV1Type.Totp:
                    step = ResetPasswordPageStep.SfaTotp;
                    break;
                case UserSfaV1Type.WebAuthNPlatform:
                case UserSfaV1Type.WebAuthNCrossPlatform:
                    step = ResetPasswordPageStep.SfaWebAuthN;
                    break;
                case UserSfaV1Type.Recovery:
                    step = ResetPasswordPageStep.SfaRecovery;
                    break;
                default:
                    throw new Error('Unsupported 2FA-Method');
            }

            this.setState({
                sfaResponse,
                step
            });
        }
        catch ( err )
        {
            console.error(`Error triggering sfa for login: ${(err as Error).message}`, err);

            await delay.sleep();

            this.setState({
                step:   ResetPasswordPageStep.Sfa,
                error:  err as Error
            });
        }
    }


    private async _finishSfaEmail ( formData: ResetPasswordPageSfaEmailStepFormData ): Promise<void>
    {
        try
        {
            if ( ! this.state.token )
            {
                throw new Error('No token loaded');
            }

            this.setState({
                step:   ResetPasswordPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.resetPasswordSfaConfirm({
                resetpassword_token:    this.state.token,
                code:                   formData.code
            });

            await delay.sleep();

            this.setState({
                step:   ResetPasswordPageStep.Success
            });
        }
        catch ( err )
        {
            console.error(`Error confirming sfa: ${(err as Error).message}`, err);

            this.setState({
                step:   ResetPasswordPageStep.SfaEmail,
                error:  err as Error
            });
        }
    }


    private async _finishSfaTotp ( formData: ResetPasswordPageSfaTotpStepFormData ): Promise<void>
    {
        try
        {
            if ( ! this.state.token )
            {
                throw new Error('No token loaded');
            }

            this.setState({
                step:   ResetPasswordPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.resetPasswordSfaConfirm({
                resetpassword_token:    this.state.token,
                code:                   formData.code
            });

            await delay.sleep();

            this.setState({
                step:   ResetPasswordPageStep.Success
            });
        }
        catch ( err )
        {
            console.error(`Error confirming sfa: ${(err as Error).message}`, err);

            this.setState({
                step:   ResetPasswordPageStep.SfaTotp,
                error:  err as Error
            });
        }
    }


    private async _finishSfaWebAuthN ( webauthnAuthData: string, webauthnClientDataJSON: string, webauthnSignature: string ): Promise<void>
    {
        try
        {
            if ( ! this.state.token )
            {
                throw new Error('No token loaded');
            }

            this.setState({
                step:   ResetPasswordPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.resetPasswordSfaConfirm({
                resetpassword_token:        this.state.token,
                webauthn_authdata:          webauthnAuthData,
                webauthn_clientdatajson:    webauthnClientDataJSON,
                webauthn_signature:         webauthnSignature
            });

            await delay.sleep();

            this.setState({
                step:   ResetPasswordPageStep.Success
            });
        }
        catch ( err )
        {
            console.error(`Error confirming sfa: ${(err as Error).message}`, err);

            this.setState({
                step:   ResetPasswordPageStep.SfaWebAuthN,
                error:  err as Error
            });
        }
    }


    private async _finishSfaRecovery ( formData: ResetPasswordPageSfaRecoveryStepFormData ): Promise<void>
    {
        try
        {
            if ( ! this.state.token )
            {
                throw new Error('No token loaded');
            }

            this.setState({
                step:   ResetPasswordPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.resetPasswordSfaConfirm({
                resetpassword_token:    this.state.token,
                code:                   formData.code
            });

            await delay.sleep();

            this.setState({
                step:           ResetPasswordPageStep.Success
            });
        }
        catch ( err )
        {
            console.error(`Error confirming sfa: ${(err as Error).message}`, err);

            this.setState({
                step:   ResetPasswordPageStep.SfaRecovery,
                error:  err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('resetpasswordpage.txt_title')}
                    hidden={true}
                />

                {this.state.step === ResetPasswordPageStep.Loading ?
                    <ResetPasswordPageLoadingStep />
                : null}

                {this.state.step === ResetPasswordPageStep.Email ?
                    <ResetPasswordPageEmailStep
                        onFinish={this._finishEmail}
                    />
                : null}

                {this.state.step === ResetPasswordPageStep.Code ?
                    <ResetPasswordPageCodeStep
                        onFinish={this._finishCode}
                    />
                : null}

                {this.state.step === ResetPasswordPageStep.Sfa ?
                    <ResetPasswordPageSfaStep
                        sfas={this.state.sfas}
                        onFinish={this._finishSfa}
                    />
                : null}

                {this.state.step === ResetPasswordPageStep.SfaEmail ?
                    <ResetPasswordPageSfaEmailStep
                        onFinish={this._finishSfaEmail}
                    />
                : null}

                {this.state.step === ResetPasswordPageStep.SfaTotp ?
                    <ResetPasswordPageSfaTotpStep
                        onFinish={this._finishSfaTotp}
                    />
                : null}
                
                {this.state.step === ResetPasswordPageStep.SfaWebAuthN && this.state.sfa && this.state.sfaResponse ?
                    <ResetPasswordPageSfaWebAuthNStep
                        sfa={this.state.sfa}
                        sfaResponse={this.state.sfaResponse}
                        onFinish={this._finishSfaWebAuthN}
                        onError={this._setError}
                    />
                : null}
                
                {this.state.step === ResetPasswordPageStep.SfaRecovery ?
                    <ResetPasswordPageSfaRecoveryStep
                        onFinish={this._finishSfaRecovery}
                    />
                : null}


                {this.state.step === ResetPasswordPageStep.Success ?
                    <ResetPasswordPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const ResetPasswordPage = withTranslation()($ResetPasswordPage);
