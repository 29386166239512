import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserService, UserSfaV1, UserSfaV1Type } from '../../Services/UserService';
import { List } from '../../Components/List/List';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Formatter } from '../../utils/Formatter';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AccountPageSfasBoxProps extends WithTranslation
{
}


interface AccountPageSfasBoxState
{
    sfas:       Array<UserSfaV1>;
    loading:    boolean;
    error:      Error | null;
}


class $AccountPageSfasBox extends React.Component<AccountPageSfasBoxProps, AccountPageSfasBoxState>
{
    private readonly _userService:  UserService;


    constructor ( props: AccountPageSfasBoxProps )
    {
        super(props);

        this.state = {
            sfas:       [],
            loading:    true,
            error:      null
        };

        this._userService = UserService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const sfas = await this._userService.getSfas();

            this.setState({
                sfas,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading ser sfas: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('accountpagesfasbox.txt_sfas')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <List>
                    {this.state.sfas.map( ( sfa ) => (
                        <ListItem key={sfa.uid}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    text={Formatter.sfaType(sfa.type)}
                                    subtext={sfa.name}
                                />

                                {(sfa.type !== UserSfaV1Type.Recovery && this.state.sfas.length > 1) ?
                                    <ListItemHeaderAction
                                        icon={faTrash}
                                        to={LinkUtils.make('account', 'sfa', sfa.uid, 'delete')}
                                    />
                                : null}
                            </ListItemHeader>
                        </ListItem>
                    ))}

                    {this.state.sfas.length === 0 && !this.state.loading ?
                        <ListEmpty>
                            {this.props.t('accountpagesfasbox.txt_no_sfas')}
                        </ListEmpty>
                    : null}
                </List>

                <IcSpinner active={this.state.loading} />

                <IcFloatRow align={IcFloatRowAlign.Right}>
                    <IcButton
                        to={LinkUtils.make('account', 'sfa', 'add')}
                        color={IcButtonColor.Secondary}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                        {this.props.t('accountpagesfasbox.btn_add_sfa')}
                    </IcButton>
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const AccountPageSfasBox = withTranslation()($AccountPageSfasBox);
