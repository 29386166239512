import React from 'react';
import { Modal } from '../../Components/Modal/Modal';
import { CookieHintService } from '../../Services/CookieHintService';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { Link } from 'react-router-dom';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { sleep } from 'ts-delay';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcButtonColor, IcInputCheckbox, IcSpinner, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './CookieHintModal.css';


interface CookieHintModalProps extends RouteComponentProps, WithTranslation
{
}


interface CookieHintModalFormData
{
    required:   boolean;
    analytics:  boolean;
    marketing:  boolean;
}


interface CookieHintModalState
{
    wasSelected:        boolean;
    forceShow:          boolean;
    edit:               boolean;
    loading:            boolean;
    initialFormData:    CookieHintModalFormData;
}


class $CookieHintModal extends React.Component<CookieHintModalProps, CookieHintModalState>
{
    private readonly _cookieHintService: CookieHintService;


    constructor ( props: CookieHintModalProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                required:   true,
                analytics:  true,
                marketing:  false
            },
            edit:           false,
            loading:        false,
            wasSelected:    false,
            forceShow:      false
        }

        this._cookieHintService = CookieHintService.getInstance();
        this._confirmAll        = this._confirmAll.bind(this);
        this._confirmSelected   = this._confirmSelected.bind(this);
        this._edit   = this._edit.bind(this);
    }


    private _confirmAll ( ): void
    {
        this._cookieHintService.setSelection({
            analytics: true,
            marketing: true
        });

        const selection = this._cookieHintService.getSelection().get();

        this.setState({
            initialFormData: {
                required:   true,
                analytics:  selection.analytics,
                marketing:  selection.marketing
            },
            wasSelected:    this._cookieHintService.wasSelected(),
            loading:        false,
            forceShow:      false
        });
    }


    private _confirmSelected ( values: CookieHintModalFormData ): void
    {
        this._cookieHintService.setSelection({
            analytics:  values.analytics,
            marketing:  values.marketing
        });

        const selection = this._cookieHintService.getSelection().get();

        this.setState({
            initialFormData: {
                required:   true,
                analytics:  selection.analytics,
                marketing:  selection.marketing
            },
            wasSelected:    this._cookieHintService.wasSelected(),
            loading:        false,
            forceShow:      false
        });
    }


    private async _edit ( ): Promise<void>
    {
        this.setState({
            loading:    true
        });

        await sleep(3000);
        
        this.setState({
            loading:    false,
            edit:       true
        });
    }


    public componentDidMount ( )
    {
        this._cookieHintService.onShow().subscribe(this, ( ) =>
        {
            this.setState({
                forceShow:  true
            });
        });

        if ( this._cookieHintService.wasSelected() )
        {
            const selection = this._cookieHintService.getSelection().get();

            this.setState({
                initialFormData: {
                    required:   true,
                    analytics:  selection.analytics,
                    marketing:  selection.marketing
                },
                wasSelected:    true,
                forceShow:      false
            });
        }
    }


    public componentWillUnmount ( ): void
    {
        this._cookieHintService.onShow().unsubscribe(this);
    }


    public render ( )
    {
        const MyFormik = Formik<CookieHintModalFormData>;

        if ( !this.state.forceShow &&
             (this.state.wasSelected ||
              this.props.router.location.pathname.startsWith('/imprint') ||
              this.props.router.location.pathname.startsWith('/privacypolicy')) )
        {
            return null;
        }

        return (
            <Modal className='CookieHintModal' closable={false}>
                <ModalTitle>
                    {this.props.t('cookiehintmodal.txt_title')}
                </ModalTitle>

                <ModalContent>
                    <div className='CookieHintModal-content'>
                        <div className='CookieHintModal-text'>
                            {this.props.t('cookiehintmodal.txt_usage')}
                            <br />
                            <br />
                            {this.props.t('cookiehintmodal.txt_more')} <Link to={LinkUtils.make('privacypolicy')} target='_blank'>{this.props.t('cookiehintmodal.btn_privacypolicy')}</Link>.
                        </div>

                        <MyFormik
                            onSubmit={this._confirmSelected}
                            initialValues={this.state.initialFormData}
                            enableReinitialize={true}>
                            <Form className='CookieHintModal-selection'>
                                {this.state.edit ?
                                    <>
                                        <IcInputCheckbox
                                            name='required'
                                            disabled={true}
                                            label={this.props.t('cookiehintmodal.inp_required')}
                                        />

                                        <IcInputCheckbox
                                            name='analytics'
                                            label={this.props.t('cookiehintmodal.inp_analytics')}
                                        />

                                        <IcInputCheckbox
                                            name='marketing'
                                            label={this.props.t('cookiehintmodal.inp_marketing')}
                                        />
                                    </>
                                :
                                    <ul>
                                        <li>{this.props.t('cookiehintmodal.txt_required')}</li>
                                        <li>{this.props.t('cookiehintmodal.txt_analytics')}</li>
                                        <li>{this.props.t('cookiehintmodal.txt_marketing')}</li>
                                    </ul>
                                }

                                <IcSpinner active={this.state.loading} />

                                <div className='CookieHintModal-actions'>
                                    <div className='CookieHintModal-imprint'>
                                        <Link to={LinkUtils.make('imprint')} target='_blank'>{this.props.t('cookiehintmodal.btn_imprint')}</Link>
                                    </div>

                                    {this.state.edit ?
                                        <div className='CookieHintModal-confirmselected'>
                                            <IcButton
                                                color={IcButtonColor.Link}
                                                type='submit'>
                                                {this.props.t('cookiehintmodal.btn_confirmselection')}
                                            </IcButton>
                                        </div>
                                    :
                                        <div className='CookieHintModal-edit'>
                                            <IcButton
                                                color={IcButtonColor.Link}
                                                onClick={this._edit}>
                                                {this.props.t('cookiehintmodal.btn_editselection')}
                                            </IcButton>
                                        </div>
                                    }

                                    <div className='CookieHintModal-confirmall'>
                                        <IcButton
                                            type='button'
                                            onClick={this._confirmAll}>
                                            {this.props.t('cookiehintmodal.btn_confirmall')}
                                        </IcButton>
                                    </div>
                                </div>
                            </Form>
                        </MyFormik>
                    </div>
                </ModalContent>
            </Modal>
        );
    }
}


export const CookieHintModal = withTranslation()(withRouter($CookieHintModal));
