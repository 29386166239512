import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AdminGroupAddPageFormStepFormData
{
    key:    string;
    name:   string;
}


export interface AdminGroupAddPageFormStepProps extends WithTranslation, WithLocaleProps
{
    onCancel:   ( ) => any;
    onFinish:   ( formData: AdminGroupAddPageFormStepFormData ) => any;
}


interface AdminGroupAddPageFormStepState
{
    initialFormData:    AdminGroupAddPageFormStepFormData;
}


class $AdminGroupAddPageFormStep extends React.Component<AdminGroupAddPageFormStepProps, AdminGroupAddPageFormStepState>
{
    constructor ( props: AdminGroupAddPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                key:    '',
                name:   ''
            }
        };
    }


    public render ( )
    {
        const MyFormik = Formik<AdminGroupAddPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='key'
                                    label={this.props.t('admingroupaddpageformstep.inp_key')}
                                    testID='admingroupaddpageformstep.inp_key'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('admingroupaddpageformstep.inp_name')}
                                    testID='admingroupaddpageformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='admingroupaddpageformstep.btn_cancel'>
                                {this.props.t('admingroupaddpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='admingroupaddpageformstep.btn_update'>
                                {this.props.t('admingroupaddpageformstep.btn_add')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminGroupAddPageFormStep = withLocale(withTranslation()($AdminGroupAddPageFormStep));
