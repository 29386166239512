import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { ClientService, ClientV1 } from '../../Services/ClientService';
import { sleep } from 'ts-delay';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { LabelValue } from '../../Components/LabelValueList/LabelValue';
import { LabelValueList } from '../../Components/LabelValueList/LabelValueList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcPageContent, IcSeparator, IcSpinner, LinkUtils, Validator, RouteComponentProps, withRouter, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface AdminEditClientRouteParams
{
    clientUID:  string;
}


export interface AdminClientEditPageProps extends RouteComponentProps<AdminEditClientRouteParams>, WithTranslation
{
}


interface AdminClientEditPageFormData
{
    redirect_uris:              Array<string>;
    logout_uris:                Array<string>;
    cors_uris:                  Array<string>;
    on_user_delete_uri:         string;
    on_user_purge_uri:          string;
    on_organisation_delete_uri: string;
    on_organisation_purge_uri:  string;
}


interface AdminClientEditPageState
{
    initialFormData:    AdminClientEditPageFormData;
    client:             ClientV1 | null;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $AdminClientEditPage extends React.Component<AdminClientEditPageProps, AdminClientEditPageState>
{
    private readonly _clientService:  ClientService;
    

    constructor ( props: AdminClientEditPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                redirect_uris:              [],
                logout_uris:                [],
                cors_uris:                  [],
                on_user_delete_uri:         '',
                on_user_purge_uri:          '',
                on_organisation_delete_uri: '',
                on_organisation_purge_uri:  ''
            },
            client:     null,
            error:      null,
            loading:    true,
            success:    null
        };

        this._clientService = ClientService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._update    = this._update.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const client = await this._clientService.getClient(this.props.router.params.clientUID);

            this.setState({
                error:      null,
                loading:    false,
                client,
                initialFormData: {
                    redirect_uris:              client.config.redirect_uris,
                    logout_uris:                client.config.logout_uris,
                    cors_uris:                  client.config.cors_uris,
                    on_user_delete_uri:         client.config.on_user_delete_uri || '',
                    on_user_purge_uri:          client.config.on_user_purge_uri || '',
                    on_organisation_delete_uri: client.config.on_organisation_delete_uri || '',
                    on_organisation_purge_uri:  client.config.on_organisation_purge_uri || ''
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading client: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _update ( formData: AdminClientEditPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.client )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._clientService.updateClient(
                this.state.client.uid,
                {
                    config: {
                        redirect_uris:              formData.redirect_uris.map( s => s.trim() ).filter( s => !!s ),
                        logout_uris:                formData.logout_uris.map( s => s.trim() ).filter( s => !!s ),
                        cors_uris:                  formData.cors_uris.map( s => s.trim() ).filter( s => !!s ),
                        on_user_delete_uri:         formData.on_user_delete_uri.trim() || null,
                        on_user_purge_uri:          formData.on_user_purge_uri.trim() || null,
                        on_organisation_delete_uri: formData.on_organisation_delete_uri.trim() || null,
                        on_organisation_purge_uri:  formData.on_organisation_purge_uri.trim() || null
                    }
                }
            );

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('adminclienteditpage.txt_success'),
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'clients'));
        }
        catch ( err )
        {
            console.error(`Error updating client: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<AdminClientEditPageFormData>;

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminclienteditpage.txt_edit_client')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._update}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <LabelValueList>
                                    <LabelValue label={this.props.t('adminclienteditpage.txt_client_id')}>
                                        {this.state.client ?
                                            this.state.client.client_id
                                        :
                                            <IcDummyContent />
                                        }
                                    </LabelValue>
                                </LabelValueList>

                                <IcSeparator />

                                <FieldArray name='redirect_uris'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminclienteditpage.txt_redirect_uris')}
                                            </IcText>

                                            <List>
                                                {values.redirect_uris.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`redirect_uris.${index}`}
                                                                    label={this.props.t('adminclienteditpage.inp_redirect_uri')}
                                                                    validators={[
                                                                        Validator.url
                                                                    ]}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminclienteditpage.btn_delete_redirect_uri')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.redirect_uris.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminclienteditpage.txt_no_redirect_uris')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    onClick={() => arrayHelpers.push('')}
                                                    color={IcButtonColor.Secondary}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />

                                                    {this.props.t('adminclienteditpage.btn_add_redirect_uri')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='logout_uris'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminclienteditpage.txt_logout_uris')}
                                            </IcText>
                                            
                                            <List>
                                                {values.logout_uris.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`logout_uris.${index}`}
                                                                    label={this.props.t('adminclienteditpage.inp_logout_uri')}
                                                                    validators={[
                                                                        Validator.url
                                                                    ]}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminclienteditpage.btn_delete_logout_uri')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.logout_uris.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminclienteditpage.txt_no_logout_uris')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    onClick={() => arrayHelpers.push('')}
                                                    color={IcButtonColor.Secondary}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />

                                                    {this.props.t('adminclienteditpage.btn_add_logout_uri')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='cors_uris'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminclienteditpage.txt_cors_uris')}
                                            </IcText>

                                            <List>
                                                {values.cors_uris.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`cors_uris.${index}`}
                                                                    label={this.props.t('adminclienteditpage.inp_cors_uri')}
                                                                    validators={[
                                                                        Validator.url
                                                                    ]}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminclienteditpage.btn_delete_cors_uri')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.cors_uris.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminclienteditpage.txt_no_cors_uris')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    onClick={() => arrayHelpers.push('')}
                                                    color={IcButtonColor.Secondary}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminclienteditpage.btn_add_cors_uri')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_user_delete_uri'
                                            label={this.props.t('adminclienteditpage.inp_on_user_delete_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_user_purge_uri'
                                            label={this.props.t('adminclienteditpage.inp_on_user_purge_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_organisation_delete_uri'
                                            label={this.props.t('adminclienteditpage.inp_on_organisation_delete_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_organisation_purge_uri'
                                            label={this.props.t('adminclienteditpage.inp_on_organisation_purge_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminclienteditpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('adminclienteditpage.btn_update')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminClientEditPage = withTranslation()(withRouter($AdminClientEditPage));
