import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { Environment } from '../../utils/Environment';
import { StatusService, StatusV1 } from '../../Services/StatusService';
import { ListItemBody } from '../../Components/List/ListItemBody';
import { IcCard, IcCardPadding, IcErrorBox, IcPageContent, IcSpinner } from '@indece-common/ic-ui-lib-react';


export interface AdminStatusPageProps extends WithTranslation
{
}


interface AdminStatusPageState
{
    status:     Array<StatusV1>;
    loading:    boolean;
    error:      Error | null;
}


class $AdminStatusPage extends React.Component<AdminStatusPageProps, AdminStatusPageState>
{
    private readonly _statusService: StatusService;


    constructor ( props: AdminStatusPageProps )
    {
        super(props);

        this.state = {
            status:     [],
            loading:    true,
            error:      null
        };

        this._statusService = StatusService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const status = await this._statusService.getStatus();

            this.setState({
                status,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading status: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminstatuspage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcErrorBox error={this.state.error} />

                    <List>
                        <ListItem key='server-version'>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    text={this.props.t('adminstatuspage.txt_server_version')}
                                />

                                <ListItemHeaderField
                                    text={`Licenser ${Environment.server.version}`}
                                />
                            </ListItemHeader>
                        </ListItem>

                        {this.state.status.map( ( status, i ) => (
                            <ListItem key={i}>
                                <ListItemHeader>
                                    <ListItemHeaderField
                                        grow={true}
                                        text={status.name}
                                    />
                                    
                                    <ListItemHeaderField
                                        text={status.status}
                                    />
                                </ListItemHeader>

                                {status.error ?
                                    <ListItemBody>
                                        {status.error}
                                    </ListItemBody>
                                : null}
                            </ListItem>
                        ))}
                    </List>
                
                    <IcSpinner active={this.state.loading} />
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminStatusPage = withTranslation()($AdminStatusPage);
