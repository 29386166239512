import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ConfigPropertyV1, ConfigPropertyV1Bool, ConfigPropertyV1Key, ConfigService } from '../../Services/ConfigService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputCheckbox, IcInputText, IcSpinner, IcSpinnerSize, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface AdminConfigPageServerBoxProps extends WithTranslation
{
}


interface AdminConfigPageServerBoxFormData
{
    company_name:                       string;
    company_slogan:                     string;
    company_address_street:             string;
    company_address_zip:                string;
    company_address_city:               string;
    server_name:                        string;
    server_base_url:                    string;
    server_registration_disabled:       boolean;
    link_support_url:                   string;
}


interface AdminConfigPageServerBoxState
{
    initialFormData:    AdminConfigPageServerBoxFormData;
    config:             Partial<Record<ConfigPropertyV1Key, ConfigPropertyV1>>;
    edit:               boolean;
    loading:            boolean;
    error:              Error | null;
}


class $AdminConfigPageServerBox extends React.Component<AdminConfigPageServerBoxProps, AdminConfigPageServerBoxState>
{
    private readonly _configService:    ConfigService;


    constructor ( props: AdminConfigPageServerBoxProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                company_name:                       '',
                company_slogan:                     '',
                company_address_street:             '',
                company_address_zip:                '',
                company_address_city:               '',
                server_name:                        '',
                server_base_url:                    '',
                server_registration_disabled:       false,
                link_support_url:                   ''
            },
            config:     {},
            edit:       false,
            loading:    true,
            error:      null
        };

        this._configService = ConfigService.getInstance();

        this._enableEdit = this._enableEdit.bind(this);
        this._disableEdit = this._disableEdit.bind(this);
        this._submit = this._submit.bind(this);
    }


    private _enableEdit ( )
    {
        this.setState({
            edit:   true,
            initialFormData: {
                company_name:                   this.state.config[ConfigPropertyV1Key.CompanyName]?.value || '',
                company_slogan:                 this.state.config[ConfigPropertyV1Key.CompanySlogan]?.value || '',
                company_address_street:         this.state.config[ConfigPropertyV1Key.CompanyAddressStreet]?.value || '',
                company_address_zip:            this.state.config[ConfigPropertyV1Key.CompanyAddressZip]?.value || '',
                company_address_city:           this.state.config[ConfigPropertyV1Key.CompanyAddressCity]?.value || '',
                server_name:                    this.state.config[ConfigPropertyV1Key.ServerName]?.value || '',
                server_base_url:                this.state.config[ConfigPropertyV1Key.ServerBaseURL]?.value || '',
                server_registration_disabled:   this.state.config[ConfigPropertyV1Key.ServerRegistrationDisabled]?.value === ConfigPropertyV1Bool.True,
                link_support_url:               this.state.config[ConfigPropertyV1Key.LinkSupportUrl]?.value || '',
            }
        });
    }


    private _disableEdit ( )
    {
        this.setState({
            edit:   false
        });
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const config = await this._configService.getConfig();

            this.setState({
                error:      null,
                loading:    false,
                config
            });
        }
        catch ( err )
        {
            console.error(`Error loading config: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _submit ( values: AdminConfigPageServerBoxFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.CompanyName,
                values.company_name.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.CompanySlogan,
                values.company_slogan.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.CompanyAddressStreet,
                values.company_address_street.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.CompanyAddressZip,
                values.company_address_zip.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.CompanyAddressCity,
                values.company_address_city.trim()
            );

            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.ServerName,
                values.server_name.trim()
            );
            
            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.ServerBaseURL,
                values.server_base_url.trim()
            );
            
            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.ServerRegistrationDisabled,
                values.server_registration_disabled ? ConfigPropertyV1Bool.True : ConfigPropertyV1Bool.False
            );
            
            await this._configService.setConfigProperty(
                ConfigPropertyV1Key.LinkSupportUrl,
                values.link_support_url.trim()
            );

            this.setState({
                loading:    false,
                edit:       false
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error storing config: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Small}>
                <IcText size={IcTextSize.Heading3}>
                    {this.props.t('adminconfigpageserverbox.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <Formik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <List>
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.CompanyName]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_company_name')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_company_name')}
                                                name='company_name'
                                                required={true}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.CompanySlogan]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_company_slogan')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_company_slogan')}
                                                name='company_slogan'
                                                required={true}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.CompanyAddressStreet]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_company_address_street')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_company_address_street')}
                                                name='company_address_street'
                                                required={true}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.CompanyAddressZip]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_company_address_zip')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_company_address_zip')}
                                                name='company_address_zip'
                                                required={true}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>

                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.CompanyAddressCity]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_company_address_city')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_company_address_city')}
                                                name='company_address_city'
                                                required={true}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.ServerName]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_server_name')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_server_name')}
                                                name='server_name'
                                                required={true}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.ServerBaseURL]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_server_base_url')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_server_base_url')}
                                                name='server_base_url'
                                                required={true}
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.ServerRegistrationDisabled]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_server_registration_disabled')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputCheckbox
                                                label={this.props.t('adminconfigpageserverbox.inp_server_registration_disabled')}
                                                name='server_registration_disabled'
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                            
                            <ListItem>
                                <ListItemHeader>
                                    {!this.state.edit ?
                                        <ListItemHeaderField
                                            text={this.state.config[ConfigPropertyV1Key.LinkSupportUrl]?.value || ''}
                                            subtext={this.props.t('adminconfigpageserverbox.txt_link_support_url')}
                                            grow={true}
                                        />
                                    : null}
                                    
                                    {this.state.edit ?
                                        <ListItemHeaderField grow={true}>
                                            <IcInputText
                                                label={this.props.t('adminconfigpageserverbox.inp_link_support_url')}
                                                name='link_support_url'
                                            />
                                        </ListItemHeaderField>
                                    : null}
                                </ListItemHeader>
                            </ListItem>
                        </List>

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcSpinner
                                size={IcSpinnerSize.Small}
                                active={this.state.loading}
                            />

                            {!this.state.edit && !this.state.loading ?
                                <IcButton
                                    color={IcButtonColor.Secondary}
                                    type='button'
                                    onClick={this._enableEdit}>
                                    <FontAwesomeIcon icon={faPen} />

                                    {this.props.t('adminconfigpageserverbox.btn_edit')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton
                                    color={IcButtonColor.Link}
                                    type='button'
                                    onClick={this._disableEdit}>
                                    {this.props.t('adminconfigpageserverbox.btn_cancel')}
                                </IcButton>
                            : null}

                            {this.state.edit && !this.state.loading ? 
                                <IcButton type='submit'>
                                    <FontAwesomeIcon icon={faCheck} />

                                    {this.props.t('adminconfigpageserverbox.btn_save')}
                                </IcButton>
                            : null}
                        </IcFloatRow>
                    </Form>
                </Formik>
            </IcCard>
        );
    }
}


export const AdminConfigPageServerBox = withTranslation()($AdminConfigPageServerBox);
