import { BackendService } from './BackendService';


export enum LogV1Type
{
    UserAdded               = 'USER_ADDED',
    UserUpdated             = 'USER_UPDATED',
    UserLocked              = 'USER_LOCKED',
    UserUnlocked            = 'USER_UNLOCKED',
    UserPasswordResetted    = 'USER_PASSWORD_RESETTED',
    UserDeleted             = 'USER_DELETED',
	UserLoggedIn            = 'USER_LOGGEDIN',
	UserLoggedOut           = 'USER_LOGGEDOUT',
	UserSfaAdded            = 'USERSFA_ADDED',
	UserSfaDeleted          = 'USERSFA_DELETED',
    GroupAdded              = 'GROUP_ADDED',
    GroupUpdated            = 'GROUP_UPDATED',
	GroupDeleted            = 'GROUP_DELETED',
    UserGroupAssigned       = 'USERGROUP_ASSIGNED',
    UserGroupUnassigned     = 'USERGROUP_UNASSIGNED',
    ClientAdded             = 'CLIENT_ADDED',
	ClientUpdated           = 'CLIENT_UPDATED',
	ClientDeleted           = 'CLIENT_DELETED',
    PolicyAdded             = 'POLICY_ADDED',
	PolicyUpdated           = 'POLICY_UPDATED',
	PolicyDeleted           = 'POLICY_DELETED'
}


export interface LogV1User
{
    uid:        string;
    firstname:  string;
    lastname:   string;
    email:      string;
}


export interface LogV1
{
    uid:                string;
    type:               LogV1Type;
    user:               LogV1User | null;
    details:            string;
    datetime_created:   string;
}


export class LogService
{
    private static _instance: LogService;
    
    
    public static getInstance ( ): LogService
    {
        if ( ! this._instance )
        {
            this._instance = new LogService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getLogs ( from: number, size: number ): Promise<Array<LogV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/log?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.logs;
    }
}
