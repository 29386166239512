import React from 'react';
import { Form, Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface ResetPasswordPageSfaTotpStepProps extends WithTranslation
{
    onFinish:   ( formData: ResetPasswordPageSfaTotpStepFormData ) => any;
}


export interface ResetPasswordPageSfaTotpStepFormData
{
    code:  string;
}


interface ResetPasswordPageSfaTotpStepState
{
    initialFormData: ResetPasswordPageSfaTotpStepFormData;
}


class $ResetPasswordPageSfaTotpStep extends React.Component<ResetPasswordPageSfaTotpStepProps, ResetPasswordPageSfaTotpStepState>
{
    constructor ( props: ResetPasswordPageSfaTotpStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                code:  ''
            }
        };

        this._confirm = this._confirm.bind(this);
    }


    private async _confirm ( values: ResetPasswordPageSfaTotpStepFormData ): Promise<void>
    {
        this.props.onFinish(values);
    }


    public render ( )
    {
        const MyFormik  = Formik<ResetPasswordPageSfaTotpStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small}>
                    {this.props.t('resetpasswordpagesfatotpstep.txt_subtitle')}
                </IcText>
                
                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('resetpasswordpagesfatotpstep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('resetpasswordpagesfatotpstep.txt_code')}
                </IcText>

                <MyFormik
                    onSubmit={this._confirm}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='code'
                                    autoComplete='off'
                                    label={this.props.t('resetpasswordpagesfatotpstep.inp_code')}
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton type='submit'>
                                {this.props.t('resetpasswordpagesfatotpstep.btn_confirm')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const ResetPasswordPageSfaTotpStep = withTranslation()($ResetPasswordPageSfaTotpStep);
