import React from 'react';
import { MetadataService } from '../../Services/MetadataService';
import { ContentService, ContentV1, ContentV1Key } from '../../Services/ContentService';
import { WithLocaleProps, withLocale } from '../../utils/withLocale';
import { IcDummyContent, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';

import './PageTitle.css';


export interface PageTitleProps extends WithLocaleProps
{
    title?:                 string;
    subtitle?:              string;
    titleContentKey?:       ContentV1Key;
    subtitleContentKey?:    ContentV1Key;
    metaTitle?:             string;
    hidden?:                boolean;
    children?:              React.ReactNode | null;
}


interface PageTitleState
{
    contentTitle:       ContentV1 | null;
    contentSubtitle:    ContentV1 | null;
    loading:            boolean;
}


class $PageTitle extends React.Component<PageTitleProps, PageTitleState>
{
    private readonly _metadataService:  MetadataService;
    private readonly _contentService:  ContentService;


    constructor ( props: PageTitleProps )
    {
        super(props);

        this.state = {
            contentTitle:       null,
            contentSubtitle:    null,
            loading:            false
        }

        this._metadataService = MetadataService.getInstance();
        this._contentService = ContentService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        if ( !this.props.subtitleContentKey && !this.props.titleContentKey )
        {
            this._metadataService.setTitle(this.props.metaTitle ?? this.props.title ?? '');

            this.setState({
                loading:            false,
                contentTitle:       null,
                contentSubtitle:    null
            });

            return;
        }

        try
        {
            this.setState({
                loading:    true
            });

            let contentTitle: ContentV1 | null = null;
            let contentSubtitle: ContentV1 | null = null;

            if ( this.props.titleContentKey )
            {
                contentTitle = await this._contentService.getContent(
                    this.props.titleContentKey,
                    this.props.locale
                );
            }

            if ( this.props.subtitleContentKey )
            {
                contentSubtitle = await this._contentService.getContent(
                    this.props.subtitleContentKey,
                    this.props.locale
                );
            }

            this._metadataService.setTitle(contentTitle?.content ?? this.props.metaTitle ?? this.props.title ?? '');

            this.setState({
                loading:    false,
                contentTitle,
                contentSubtitle
            });
        }
        catch ( err )
        {
            console.error(`Error loading page title: ${(err as Error).message}`, err);

            this.setState({
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public async componentDidUpdate ( prevProps: Readonly<PageTitleProps> ): Promise<void>
    {
        if ( (prevProps.title !== this.props.title) ||
             (prevProps.locale !== this.props.locale) ||
             (prevProps.titleContentKey !== this.props.titleContentKey) ||
             (prevProps.subtitleContentKey !== this.props.subtitleContentKey) ||
             (prevProps.metaTitle !== this.props.metaTitle))
        {
            await this._load();
        }   
    }


    public render ( )
    {
        if ( this.props.hidden )
        {
            return null;
        }

        return (
            <div className='PageTitle'>
                {this.props.subtitle || this.props.subtitleContentKey ?
                    <IcText size={IcTextSize.Small}>
                        {this.props.subtitleContentKey && this.state.loading ?
                            <IcDummyContent />
                        : (this.props.subtitleContentKey && !this.state.loading ?
                            this.state.contentSubtitle?.content
                        :
                            this.props.subtitle
                        )}
                    </IcText>
                :null}

                <IcText size={IcTextSize.Heading1}>
                    {this.props.titleContentKey && this.state.loading ?
                        <IcDummyContent />
                    : (this.props.titleContentKey && !this.state.loading ?
                        this.state.contentTitle?.content
                    :
                        this.props.title
                    )}
                </IcText>

                <div className='PageTitle-actions'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}


export const PageTitle = withLocale($PageTitle);
