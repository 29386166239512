import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TileButton } from '../../Components/TileButton/TileButton';
import { faAlignJustify, faBars, faCogs, faLaptopCode, faPlay, faTemperatureHalf, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcGridItem, IcGridRow, IcPageContent, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminPageProps extends WithTranslation
{
}


class $AdminPage extends React.Component<AdminPageProps>
{
    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminpage.txt_title')} />

                <IcGridRow>
                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faLaptopCode}
                            title={this.props.t('adminpage.btn_clients_title')}
                            text={this.props.t('adminpage.btn_clients_text')}
                            linkTitle={this.props.t('adminpage.btn_clients_link')}
                            to={LinkUtils.make('admin', 'clients')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faUsers}
                            title={this.props.t('adminpage.btn_groups_title')}
                            text={this.props.t('adminpage.btn_groups_text')}
                            linkTitle={this.props.t('adminpage.btn_groups_link')}
                            to={LinkUtils.make('admin', 'groups')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faUser}
                            title={this.props.t('adminpage.btn_users_title')}
                            text={this.props.t('adminpage.btn_users_text')}
                            linkTitle={this.props.t('adminpage.btn_users_link')}
                            to={LinkUtils.make('admin', 'users')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faUser}
                            title={this.props.t('adminpage.btn_policies_title')}
                            text={this.props.t('adminpage.btn_policies_text')}
                            linkTitle={this.props.t('adminpage.btn_policies_link')}
                            to={LinkUtils.make('admin', 'policies')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faUser}
                            title={this.props.t('adminpage.btn_sources_title')}
                            text={this.props.t('adminpage.btn_sources_text')}
                            linkTitle={this.props.t('adminpage.btn_sources_link')}
                            to={LinkUtils.make('admin', 'sources')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faUser}
                            title={this.props.t('adminpage.btn_apiclients_title')}
                            text={this.props.t('adminpage.btn_apiclients_text')}
                            linkTitle={this.props.t('adminpage.btn_apiclients_link')}
                            to={LinkUtils.make('admin', 'apiclients')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faAlignJustify}
                            title={this.props.t('adminpage.btn_contents_title')}
                            text={this.props.t('adminpage.btn_contents_text')}
                            linkTitle={this.props.t('adminpage.btn_contents_link')}
                            to={LinkUtils.make('admin', 'contents')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faFile}
                            title={this.props.t('adminpage.btn_contentfiles_title')}
                            text={this.props.t('adminpage.btn_contentfiles_text')}
                            linkTitle={this.props.t('adminpage.btn_contentfiles_link')}
                            to={LinkUtils.make('admin', 'contentfiles')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faCogs}
                            title={this.props.t('adminpage.btn_config_title')}
                            text={this.props.t('adminpage.btn_config_text')}
                            linkTitle={this.props.t('adminpage.btn_config_link')}
                            to={LinkUtils.make('admin', 'config')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faPlay}
                            title={this.props.t('adminpage.btn_jobs_title')}
                            text={this.props.t('adminpage.btn_jobs_text')}
                            linkTitle={this.props.t('adminpage.btn_jobs_link')}
                            to={LinkUtils.make('admin', 'jobs')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faBars}
                            title={this.props.t('adminpage.btn_logs_title')}
                            text={this.props.t('adminpage.btn_logs_text')}
                            linkTitle={this.props.t('adminpage.btn_logs_link')}
                            to={LinkUtils.make('admin', 'logs')}
                        />
                    </IcGridItem>

                    <IcGridItem s={12} m={6} l={4}>
                        <TileButton
                            icon={faTemperatureHalf}
                            title={this.props.t('adminpage.btn_status_title')}
                            text={this.props.t('adminpage.btn_status_text')}
                            linkTitle={this.props.t('adminpage.btn_status_link')}
                            to={LinkUtils.make('admin', 'status')}
                        />
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    }
}


export const AdminPage = withTranslation()($AdminPage);
