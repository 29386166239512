import { BackendService } from './BackendService';
import { UserSfaV1Type } from './UserService';


export interface PolicyV1Filter
{
    whitelist_all_groups:   boolean;
    whitelist_group_uids:   Array<string>;
    blacklist_group_uids:   Array<string>;
    whitelist_all_sources:  boolean;
    whitelist_source_uids:  Array<string>;
    blacklist_source_uids:  Array<string>;
}


export enum PolicyV1RulesSfaMode
{
    Disabled    = 'DISABLED',
    Optional    = 'OPTIONAL',
    Required    = 'REQUIRED',
    Enforced    = 'ENFORCED'
}


export const PolicyV1RulesSfaModes: Array<PolicyV1RulesSfaMode> = [
    PolicyV1RulesSfaMode.Disabled,
    PolicyV1RulesSfaMode.Optional,
    PolicyV1RulesSfaMode.Required,
    PolicyV1RulesSfaMode.Enforced
];


export interface PolicyV1RulesLogin
{
    enabled:    boolean;
}


export interface PolicyV1RulesSfa
{
    mode:   PolicyV1RulesSfaMode;
    types:  Array<UserSfaV1Type>;
}


export enum PolicyV1RulesPasswordResetSfaMode
{
    Disabled    = 'DISABLED',
    Required    = 'REQUIRED',
    Enforced    = 'ENFORCED'
}


export const PolicyV1RulesPasswordResetSfaModes: Array<PolicyV1RulesPasswordResetSfaMode> = [
    PolicyV1RulesPasswordResetSfaMode.Disabled,
    PolicyV1RulesPasswordResetSfaMode.Required,
    PolicyV1RulesPasswordResetSfaMode.Enforced
];


export interface PolicyV1RulesPasswordReset
{
    enabled:    boolean;
    sfa_mode:   PolicyV1RulesPasswordResetSfaMode;
}


export interface PolicyV1Rules
{
    login:          PolicyV1RulesLogin | null;
    sfa:            PolicyV1RulesSfa | null;
    password_reset: PolicyV1RulesPasswordReset | null;
}


export interface PolicyV1Config
{
    filter: PolicyV1Filter;
    rules:  PolicyV1Rules;
}


export interface PolicyV1
{
    uid:        string;
    default:    boolean;
    name:       string;
    config:     PolicyV1Config;
    enabled:    boolean;
}


export interface AddPolicyV1
{
    name:       string;
    config:     PolicyV1Config;
    enabled:    boolean;
}


export interface UpdatePolicyV1
{
    name:       string;
    config:     PolicyV1Config;
    enabled:    boolean;
}


export class PolicyService
{
    private static _instance: PolicyService;
    
    
    public static getInstance ( ): PolicyService
    {
        if ( ! this._instance )
        {
            this._instance = new PolicyService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getPolicies ( from: number, size: number ): Promise<Array<PolicyV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/policy?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.policies;
    }


    public async getPolicy ( policyUID: string ): Promise<PolicyV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/policy/${encodeURIComponent(policyUID)}`
        );

        return resp.policy;
    }
    

    public async addPolicy ( params: AddPolicyV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/policy`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.policy_uid;
    }
    

    public async updatePolicy ( policyUID: string, params: UpdatePolicyV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/policy/${encodeURIComponent(policyUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deletePolicy ( policyUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/policy/${encodeURIComponent(policyUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
