import { BackendService } from './BackendService';


export interface GroupV1
{
    uid:    string;
    key:    string;
    name:   string;
}


export interface AddGroupV1
{
    key:    string;
    name:   string;
}


export interface UpdateGroupV1
{
    key:    string;
    name:   string;
}


export class GroupService
{
    private static _instance: GroupService;
    
    
    public static getInstance ( ): GroupService
    {
        if ( ! this._instance )
        {
            this._instance = new GroupService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getGroups ( from: number, size: number ): Promise<Array<GroupV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/group?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.groups;
    }


    public async getGroup ( groupUID: string ): Promise<GroupV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/group/${encodeURIComponent(groupUID)}`
        );

        return resp.group;
    }


    public async addGroup ( params: AddGroupV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/group`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.group_uid;
    }
    

    public async updateGroup ( groupUID: string, params: UpdateGroupV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/group/${encodeURIComponent(groupUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deleteGroup ( groupUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/group/${encodeURIComponent(groupUID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
