import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputCheckbox, IcInputText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AdminSourceAddPageLocalFormStepFormData
{
    name:                           string;
    enabled:                        boolean;
    registration_enabled:           boolean;
    auto_assign_enabled:            boolean;
}


export interface AdminSourceAddPageLocalFormStepProps extends WithTranslation, WithLocaleProps
{
    onCancel:   ( ) => any;
    onFinish:   ( formData: AdminSourceAddPageLocalFormStepFormData ) => any;
}


interface AdminSourceAddPageLocalFormStepState
{
    initialFormData:    AdminSourceAddPageLocalFormStepFormData;
}


class $AdminSourceAddPageLocalFormStep extends React.Component<AdminSourceAddPageLocalFormStepProps, AdminSourceAddPageLocalFormStepState>
{
    constructor ( props: AdminSourceAddPageLocalFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:                   '',
                enabled:                true,
                registration_enabled:   false,
                auto_assign_enabled:    true
            }
        };
    }


    public render ( )
    {
        const MyFormik = Formik<AdminSourceAddPageLocalFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='enabled'
                                    label={this.props.t('adminsourceaddpagelocalformstep.inp_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='registration_enabled'
                                    label={this.props.t('adminsourceaddpagelocalformstep.inp_registration_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='auto_assign_enabled'
                                    label={this.props.t('adminsourceaddpagelocalformstep.inp_auto_assign_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('adminsourceaddpagelocalformstep.inp_name')}
                                    testID='adminsourceaddpagelocalformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='adminsourceaddpagelocalformstep.btn_cancel'>
                                {this.props.t('adminsourceaddpagelocalformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='adminsourceaddpagelocalformstep.btn_add'>
                                {this.props.t('adminsourceaddpagelocalformstep.btn_add')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminSourceAddPageLocalFormStep = withLocale(withTranslation()($AdminSourceAddPageLocalFormStep));
