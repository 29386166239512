import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminSourceEditPageSuccessStepProps extends WithTranslation
{
}


class $AdminSourceEditPageSuccessStep extends React.Component<AdminSourceEditPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                testID='adminsourceeditpagesuccessstep.txt_success'
                message={this.props.t('adminsourceeditpagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminSourceEditPageSuccessStep = withTranslation()($AdminSourceEditPageSuccessStep);
