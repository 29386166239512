import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GroupService, GroupV1 } from '../../Services/GroupService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminGroupEditPageLoadingStep } from './AdminGroupEditPageLoadingStep/AdminGroupEditPageLoadingStep';
import { AdminGroupEditPageSuccessStep } from './AdminGroupEditPageSuccessStep/AdminGroupEditPageSuccessStep';
import { AdminGroupEditPageFormStep } from './AdminGroupEditPageFormStep/AdminGroupEditPageFormStep';
import { AdminGroupEditPageFormStepFormData } from './AdminGroupEditPageFormStep/AdminGroupEditPageFormStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminGroupEditPageRouteParams
{
    groupUID: string;
}


export interface AdminGroupEditPageProps extends RouteComponentProps<AdminGroupEditPageRouteParams>, WithTranslation
{
}


enum AdminGroupEditPageStep
{
    Loading = 'LOADING',
    Form    = 'FORM',
    Success = 'SUCCESS'
}


interface AdminGroupEditPageState
{
    step:   AdminGroupEditPageStep;
    group:  GroupV1 | null;
    error:  Error | null;
}


class $AdminGroupEditPage extends React.Component<AdminGroupEditPageProps, AdminGroupEditPageState>
{
    private readonly _groupService: GroupService;
    

    constructor ( props: AdminGroupEditPageProps )
    {
        super(props);

        this.state = {
            step:   AdminGroupEditPageStep.Loading,
            group:  null,
            error:  null
        };

        this._groupService = GroupService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishForm = this._finishForm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminGroupEditPageStep.Loading,
                error:  null
            });

            const group = await this._groupService.getGroup(this.props.router.params.groupUID);

            this.setState({
                step:   AdminGroupEditPageStep.Form,
                group
            });
        }
        catch ( err )
        {
            console.error(`Error loading group: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishForm ( formData: AdminGroupEditPageFormStepFormData ): Promise<void>
    {
        if ( ! this.state.group )
        {
            return;
        }

        try
        {
            this.setState({
                step:   AdminGroupEditPageStep.Loading,
                error:  null
            });

            await this._groupService.updateGroup(
                this.state.group.uid,
                {
                    key:    formData.key.trim(),
                    name:   formData.name.trim()
                }
            );

            this.setState({
                step:   AdminGroupEditPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating group: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminGroupEditPageStep.Form,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('admingroupeditpage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminGroupEditPageStep.Loading ?
                    <AdminGroupEditPageLoadingStep />
                : null}

                {this.state.step === AdminGroupEditPageStep.Form && this.state.group ?
                    <AdminGroupEditPageFormStep
                        group={this.state.group}
                        onCancel={this._cancel}
                        onFinish={this._finishForm}
                    />
                : null}

                {this.state.step === AdminGroupEditPageStep.Success ?
                    <AdminGroupEditPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminGroupEditPage = withTranslation()(withRouter($AdminGroupEditPage));
