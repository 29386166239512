import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { JobService } from '../../Services/JobService';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcInputSelect, IcInputSelectItem, IcInputSelectOnSearchParams, IcPageContent, IcSpinner, IcText, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { SourceService, SourceV1, SourceV1Type } from '../../Services/SourceService';
import { Form, Formik } from 'formik';


export interface AdminJobAddSyncSourcePageProps extends RouteComponentProps, WithTranslation
{
}


interface AdminJobAddSyncSourcePageFormData
{
    source_uid: string;
}


interface AdminJobAddSyncSourcePageState
{
    initialFormData:    AdminJobAddSyncSourcePageFormData;
    error:              Error | null;
    loading:            boolean;
    success:            boolean;
}


class $AdminJobAddSyncSourcePage extends React.Component<AdminJobAddSyncSourcePageProps, AdminJobAddSyncSourcePageState>
{
    private readonly _jobService:       JobService;
    private readonly _sourceService:    SourceService;
    

    constructor ( props: AdminJobAddSyncSourcePageProps )
    {
        super(props);

        this.state = {
            initialFormData:    {
                source_uid: ''
            },
            error:              null,
            loading:            false,
            success:            false
        };

        this._jobService = JobService.getInstance();
        this._sourceService = SourceService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._searchSources = this._searchSources.bind(this);
        this._add = this._add.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _searchSources (params: IcInputSelectOnSearchParams<string> ): Promise<Array<IcInputSelectItem<string>>>
    {
        let sources: Array<SourceV1> = [];

        if ( params.value )
        {
            const source = await this._sourceService.getSource(params.value);

            sources = [source];
        }
        else if ( params.query && params.query.trim() )
        {
            const query = params.query.trim().toLowerCase();

            const orgSources = await this._sourceService.getSources(0, 100);

            sources = orgSources.filter( o => o.type !== SourceV1Type.Local && o.name.toLowerCase().includes(query) );
        }
        else
        {
            const orgSources = await this._sourceService.getSources(0, 10);

            sources = orgSources.filter( o => o.type !== SourceV1Type.Local );
        }

        return sources.map( ( o ) => ({
            label:  o.name,
            value:  o.uid
        }));
    }


    private async _add ( formData: AdminJobAddSyncSourcePageFormData ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    false
            });

            await this._jobService.addJobSyncSource(formData.source_uid);

            this.setState({
                error:      null,
                loading:    false,
                success:    true
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'jobs'));
        }
        catch ( err )
        {
            console.error(`Error adding job: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminJobAddSyncSourcePageFormData>;

        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'jobs')}
                        label={this.props.t('adminjobspage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'jobs', 'add', 'sync-source')}
                        label={this.props.t('adminjobaddsyncsourcepage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminjobaddsyncsourcepage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                {this.state.loading ?
                    <IcCard padding={IcCardPadding.Large}>
                        <IcSpinner />
                    </IcCard>
                : null}
                
                {this.state.success ?
                    <SuccessBox
                        message={this.props.t('adminjobaddsyncsourcepage.txt_success')}
                    />
                : null}

                {!this.state.success && !this.state.loading ?
                    <IcCard padding={IcCardPadding.Small}>
                        <IcText>
                            {this.props.t('adminjobaddsyncsourcepage.txt_confirm')}
                        </IcText>

                        <MyFormik
                            initialValues={this.state.initialFormData}
                            enableReinitialize={true}
                            onSubmit={this._add}>
                            <Form>
                                <IcInputSelect
                                    name='source_uid'
                                    label={this.props.t('adminjobaddsyncsourcepage.inp_source')}
                                    required={true}
                                    onSearch={this._searchSources}
                                />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminjobaddsyncsourcepage.btn_cancel')}
                                    </IcButton>

                                    <IcButton type='submit'>
                                        {this.props.t('adminjobaddsyncsourcepage.btn_confirm')}
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </MyFormik>
                    </IcCard>
                : null}
            </IcPageContent>
        );
    } 
}


export const AdminJobAddSyncSourcePage = withTranslation()(withRouter($AdminJobAddSyncSourcePage));
