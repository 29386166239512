import { useAuth } from 'oidc-react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';
import { IcButton, IcFloatRow, IcFloatRowAlign } from '@indece-common/ic-ui-lib-react';


export interface RegisterPageSuccessStepProps
{
}


export const RegisterPageSuccessStep: FunctionComponent<RegisterPageSuccessStepProps> = ( props ) =>
{
    const auth = useAuth();
    const [t] = useTranslation();

    return (
        <div className='RegisterPageSuccessStep'>
            <SuccessBox
                message={t('registerpagesuccessstep.txt_success')}
            />

            <IcFloatRow align={IcFloatRowAlign.Right}>
                <IcButton onClick={ ( ) => auth.signIn()}>
                    {t('registerpagesuccessstep.btn_login')}
                </IcButton>
            </IcFloatRow>
        </div>
    );
}
