import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcPageContent } from '@indece-common/ic-ui-lib-react';


export interface Error404PageProps extends WithTranslation
{
}


class $Error404Page extends React.Component<Error404PageProps>
{
    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle title={this.props.t('error404page.txt_error')} />

                <IcCard padding={IcCardPadding.Small}>
                    {this.props.t('error404page.txt_description')}
                </IcCard>
            </IcPageContent>
        );
    }
}


export const Error404Page = withTranslation()($Error404Page);
