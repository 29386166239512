import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SourceService } from '../../Services/SourceService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminSourceAddPageLoadingStep } from './AdminSourceAddPageLoadingStep/AdminSourceAddPageLoadingStep';
import { AdminSourceAddPageSuccessStep } from './AdminSourceAddPageSuccessStep/AdminSourceAddPageSuccessStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { SourceV1Type } from '../../Services/SourceService';
import { AdminSourceAddPageLdapFormStep, AdminSourceAddPageLdapFormStepFormData } from './AdminSourceAddPageLdapFormStep/AdminSourceAddPageLdapFormStep';
import { AdminSourceAddPageTypeStep } from './AdminSourceAddPageTypeStep/AdminSourceAddPageTypeStep';
import { AdminSourceAddPageLocalFormStep, AdminSourceAddPageLocalFormStepFormData } from './AdminSourceAddPageLocalFormStep/AdminSourceAddPageLocalFormStep';


export interface AdminSourceAddPageProps extends RouteComponentProps, WithTranslation
{
}


enum AdminSourceAddPageStep
{
    Loading     = 'LOADING',
    Type        = 'TYPE',
    LdapForm    = 'LDAP_FORM',
    LocalForm   = 'LOCAL_FORM',
    Success     = 'SUCCESS'
}


interface AdminSourceAddPageState
{
    step:   AdminSourceAddPageStep;
    type:   SourceV1Type | null;
    error:  Error | null;
}


class $AdminSourceAddPage extends React.Component<AdminSourceAddPageProps, AdminSourceAddPageState>
{
    private readonly _sourceService: SourceService;
    

    constructor ( props: AdminSourceAddPageProps )
    {
        super(props);

        this.state = {
            step:   AdminSourceAddPageStep.Type,
            type:   null,
            error:  null
        };

        this._sourceService = SourceService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishType = this._finishType.bind(this);
        this._finishLdapForm = this._finishLdapForm.bind(this);
        this._finishLocalForm = this._finishLocalForm.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishType ( type: SourceV1Type ): Promise<void>
    {
        this.setState({
            type
        });

        switch ( type )
        {
            case SourceV1Type.Ldap:
                this.setState({
                    step:   AdminSourceAddPageStep.LdapForm
                });
                break;
            case SourceV1Type.Local:
                this.setState({
                    step:   AdminSourceAddPageStep.LocalForm
                });
                break;
        }
    }


    private async _finishLdapForm ( formData: AdminSourceAddPageLdapFormStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminSourceAddPageStep.Loading,
                error:  null
            });

            await this._sourceService.addSource(
                {
                    type:       SourceV1Type.Ldap,
                    name:       formData.name.trim(),
                    enabled:    formData.enabled,
                    config:     {
                        registration_enabled:   formData.registration_enabled,
                        auto_assign_enabled:    formData.auto_assign_enabled,
                        ldap:                   {
                            uri:                        formData.ldap_uri.trim(),
                            bind_user:                  formData.ldap_bind_user.trim() || null,
                            bind_password:              formData.ldap_bind_password.trim() || null,
                            user_filter_pattern:        formData.ldap_user_filter_pattern.trim() || null,
                            user_username_attribute:    formData.ldap_user_username_attribute.trim(),
                            user_firstname_attribute:   formData.ldap_user_firstname_attribute.trim(),
                            user_lastname_attribute:    formData.ldap_user_lastname_attribute.trim(),
                            user_email_attribute:       formData.ldap_user_email_attribute.trim(),
                            user_base_dn:               formData.ldap_user_base_dn.trim()
                        }
                    }
                }
            );

            this.setState({
                step:   AdminSourceAddPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error adding ldap source: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminSourceAddPageStep.LdapForm,
                error:  err as Error
            });
        }
    }


    private async _finishLocalForm ( formData: AdminSourceAddPageLocalFormStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminSourceAddPageStep.Loading,
                error:  null
            });

            await this._sourceService.addSource(
                {
                    type:       SourceV1Type.Local,
                    name:       formData.name.trim(),
                    enabled:    formData.enabled,
                    config:     {
                        registration_enabled:   formData.registration_enabled,
                        auto_assign_enabled:    formData.auto_assign_enabled
                    }
                }
            );

            this.setState({
                step:   AdminSourceAddPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error adding ldap source: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminSourceAddPageStep.LdapForm,
                error:  err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('adminsourceaddpage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminSourceAddPageStep.Loading ?
                    <AdminSourceAddPageLoadingStep />
                : null}

                {this.state.step === AdminSourceAddPageStep.Type ?
                    <AdminSourceAddPageTypeStep
                        onFinish={this._finishType}
                    />
                : null}

                {this.state.step === AdminSourceAddPageStep.LdapForm ?
                    <AdminSourceAddPageLdapFormStep
                        onCancel={this._cancel}
                        onFinish={this._finishLdapForm}
                    />
                : null}

                {this.state.step === AdminSourceAddPageStep.LocalForm ?
                    <AdminSourceAddPageLocalFormStep
                        onCancel={this._cancel}
                        onFinish={this._finishLocalForm}
                    />
                : null}

                {this.state.step === AdminSourceAddPageStep.Success ?
                    <AdminSourceAddPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminSourceAddPage = withTranslation()(withRouter($AdminSourceAddPage));
