import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { Form, Formik } from 'formik';
import { ContentFileV1, ContentFileV1Key, ContentService } from '../../Services/ContentService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Locale } from '../../Model/Locale';
import { InputFile } from '../../Components/Input/InputFile';
import { Formatter } from '../../utils/Formatter';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcSpinner, IcText, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminContentFileEditPageRouteParams
{
    contentFileKey: string;
    locale:         string;
}


export interface AdminContentFileEditPageProps extends RouteComponentProps<AdminContentFileEditPageRouteParams>, WithTranslation
{
}


interface AdminContentFileEditPageFormData
{
    file:   File | null;
}


interface AdminContentFileEditPageState
{
    initialFormData:    AdminContentFileEditPageFormData;
    contentFile:        ContentFileV1 | null;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $AdminContentFileEditPage extends React.Component<AdminContentFileEditPageProps, AdminContentFileEditPageState>
{
    private readonly _contentService:   ContentService;
    

    constructor ( props: AdminContentFileEditPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                file:       null
            },
            contentFile:    null,
            error:          null,
            loading:        true,
            success:        null
        };

        this._contentService = ContentService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._update    = this._update.bind(this);
    }

    
    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            const contentFile = await this._contentService.getContentFile(
                this.props.router.params.contentFileKey as ContentFileV1Key,
                this.props.router.params.locale as Locale,
            );

            this.setState({
                error:      null,
                loading:    false,
                contentFile,
                initialFormData: {
                    file:       null
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading content file: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _update ( formData: AdminContentFileEditPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.contentFile )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._contentService.updateContentFile(
                this.state.contentFile.key,
                {
                    locale:     this.state.contentFile.locale,
                    file:       formData.file
                }
            );

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('admincontentfileeditpage.txt_success'),
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'contentfiles'));
        }
        catch ( err )
        {
            console.error(`Error updating content file: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        const MyFormik = Formik<AdminContentFileEditPageFormData>;

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('admincontentfileeditpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                {this.state.loading ?
                    <IcCard padding={IcCardPadding.Large}>
                        <IcSpinner />
                    </IcCard>
                : null}

                {!this.state.success && !this.state.loading ?
                    <MyFormik
                        onSubmit={this._update}
                        initialValues={this.state.initialFormData}
                        enableReinitialize={true}>
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <IcText>
                                    {this.state.contentFile ?
                                        `${Formatter.contentFileKey(this.state.contentFile.key)} (${Formatter.locale(this.state.contentFile.locale)})`
                                    : 
                                        <IcDummyContent />
                                    }
                                </IcText>

                                <InputFile
                                    name='file'
                                    label={this.props.t('admincontentfileeditpage.inp_file')}
                                />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('admincontentfileeditpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton type='submit'>
                                        {this.props.t('admincontentfileeditpage.btn_update')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    </MyFormik>
                : null}
            </IcPageContent>
        );
    } 
}


export const AdminContentFileEditPage = withTranslation()(withRouter($AdminContentFileEditPage));
