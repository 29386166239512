import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface UserAddPageSuccessStepProps extends WithTranslation
{
}


class $UserAddPageSuccessStep extends React.Component<UserAddPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                message={this.props.t('useraddpagesuccessstep.txt_success')}
            />
        );
    }
}


export const UserAddPageSuccessStep = withTranslation()($UserAddPageSuccessStep);
