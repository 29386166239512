import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Formatter } from '../../../utils/Formatter';
import { UserSfaV1Type } from '../../../Services/UserService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface AccountAddSfaPageSfaTypeStepProps extends WithTranslation
{
    availableSfaTypes:  Array<UserSfaV1Type>;
    onFinish:           ( sfaType: UserSfaV1Type ) => any;
}


class $AccountAddSfaPageSfaTypeStep extends React.Component<AccountAddSfaPageSfaTypeStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small}>
                    {this.props.t('accountaddsfapagesfatypestep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('accountaddsfapagesfatypestep.txt_title')}
                </IcText>

                <IcText>
                    {this.props.t('accountaddsfapagesfatypestep.txt_select_type')}
                </IcText>

                <br />
                <br />

                <List>
                    {this.props.availableSfaTypes.map( ( sfaType ) => (
                        <ListItem key={sfaType}>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    grow={true}
                                    text={Formatter.sfaType(sfaType)}
                                    onClick={ ( ) => this.props.onFinish(sfaType)}
                                />

                                <ListItemHeaderField>
                                    <IcButton
                                        color={IcButtonColor.Secondary}
                                        onClick={ ( ) => this.props.onFinish(sfaType)}>
                                        {this.props.t('accountaddsfapagesfatypestep.btn_select')}

                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </IcButton>
                                </ListItemHeaderField>
                            </ListItemHeader>
                        </ListItem>
                    ))}
                </List>
            </IcCard>
        );
    }
}


export const AccountAddSfaPageSfaTypeStep = withTranslation()($AccountAddSfaPageSfaTypeStep);
