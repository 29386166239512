import React from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { UserService, UserV1GlobalRole } from '../../../Services/UserService';
import { Gender, Genders } from '../../../Model/Gender';
import { Formatter } from '../../../utils/Formatter';
import { LocaleService } from '../../../Services/LocaleService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GroupService } from '../../../Services/GroupService';
import { IcButton, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputSelect, IcInputText, IcSpinner, IcText, IcTextSize, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface SetupAddUserStepProps extends WithTranslation
{
    onFinish:   ( userUID: string ) => any;
}


interface SetupAddUserStepFormData
{
    gender:             Gender;
    title:              string;
    firstname:          string;
    lastname:           string;
    email:              string;
    password:           string;
    password_confirm:   string;
}


interface SetupAddUserStepState
{
    initialFormData:    SetupAddUserStepFormData;
    loading:            boolean;
    error:              Error | null;
}


class $SetupAddUserStep extends React.Component<SetupAddUserStepProps, SetupAddUserStepState>
{
    private readonly _userService: UserService;
    private readonly _groupService: GroupService;
    private readonly _localeService: LocaleService;


    constructor ( props: SetupAddUserStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                gender:             Gender.Male,
                title:              '',
                firstname:          '',
                lastname:           '',
                email:              '',
                password:           '',
                password_confirm:   ''
            },
            loading:    false,
            error:      null
        };

        this._userService = UserService.getInstance();
        this._groupService = GroupService.getInstance();
        this._localeService = LocaleService.getInstance();

        this._validate = this._validate.bind(this);
        this._submit = this._submit.bind(this);
    }


    private _validate ( formData: SetupAddUserStepFormData ): FormikErrors<SetupAddUserStepFormData>
    {
        const errors: FormikErrors<SetupAddUserStepFormData> = {};

        if ( formData.password.trim() !== formData.password_confirm.trim() )
        {
            errors.password_confirm = this.props.t('useraddpagedatastep.txt_password_mismatch');
        }

        return errors;
    }


    private async _submit ( values: SetupAddUserStepFormData ): Promise<void>
    {
        try
        {
            if ( this.state.loading )
            {
                return;
            }

            this.setState({
                loading:    true,
                error:      null
            });

            const groups = await this._groupService.getGroups(0, 100); // TODO
            let adminGroupUID = groups.find( o => o.key = UserV1GlobalRole.Admin )?.uid;
            if ( ! adminGroupUID )
            {
                adminGroupUID = await this._groupService.addGroup({
                    key: UserV1GlobalRole.Admin,
                    name: UserV1GlobalRole.Admin
                });
            }

            const resp = await this._userService.addUser({
                locale:         this._localeService.getLocale().get(),
                gender:         values.gender,
                title:          values.title.trim() || null,
                firstname:      values.firstname.trim(),
                lastname:       values.lastname.trim(),
                email:          values.email.trim(),
                password:       values.password.trim(),
                invitation_code:        null,
                invitation_user_uid:    null,
                accept_marketing:       null
            });

            await this._userService.assignUserGroup(resp.user_uid, adminGroupUID);

            this.setState({
                loading:    false
            });

            this.props.onFinish(resp.user_uid);
        }
        catch ( err )
        {
            console.error(`Error adding user: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText size={IcTextSize.Small}>
                    {this.props.t('setupadduserstep.txt_subtitle')}
                </IcText>

                <IcText size={IcTextSize.Heading1}>
                    {this.props.t('setupadduserstep.txt_title')}
                </IcText>

                <IcErrorBox error={this.state.error} />

                <Formik
                    initialValues={this.state.initialFormData}
                    onSubmit={this._submit}
                    validate={this._validate}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputSelect
                                    name='gender'
                                    label={this.props.t('setupadduserstep.inp_gender')}
                                    options={Genders.map( ( gender ) => ({
                                        label: Formatter.gender(gender),
                                        value: gender
                                    }))}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='title'
                                    label={this.props.t('setupadduserstep.inp_title')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='firstname'
                                    label={this.props.t('setupadduserstep.inp_firstname')}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='lastname'
                                    label={this.props.t('setupadduserstep.inp_lastname')}
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='email'
                                    label={this.props.t('setupadduserstep.inp_email')}
                                    required={true}
                                    validators={[
                                        Validator.email
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='password'
                                    label={this.props.t('setupadduserstep.inp_password')}
                                    type='password'
                                    required={true}
                                    validators={[
                                        Validator.password
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='password_confirm'
                                    label={this.props.t('setupadduserstep.inp_password_confirm')}
                                    type='password'
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='submit'
                                disabled={this.state.loading}>
                                {this.props.t('setupadduserstep.btn_continue')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </Formik>

                <IcSpinner active={this.state.loading} />
            </IcCard>
        );
    }
}


export const SetupAddUserStep = withTranslation()($SetupAddUserStep);
