import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { Form, Formik } from 'formik';
import { PolicyService, PolicyV1RulesPasswordResetSfaMode, PolicyV1RulesPasswordResetSfaModes, PolicyV1RulesSfaMode, PolicyV1RulesSfaModes } from '../../Services/PolicyService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcPageContent, IcSeparator, IcSpinner, LinkUtils, Validator, RouteComponentProps, withRouter, IcBreadcrumbs, IcBreadcrumbHome, IcBreadcrumbItem, IcInputSelect, IcInputRadio, IcInputSelectOnSearchParams, IcInputSelectItem, IcInputMultiSelect, IcInputCheckbox, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../Components/RequiredHint/RequiredHint';
import { UserSfaV1Type, UserSfaV1Types } from '../../Services/UserService';
import { GroupService, GroupV1 } from '../../Services/GroupService';
import { Formatter } from '../../utils/Formatter';
import { SourceService, SourceV1 } from '../../Services/SourceService';


export interface AdminPolicyAddPageProps extends RouteComponentProps, WithTranslation
{
}


interface AdminPolicyAddPageFormData
{
    name:                           string;
    enabled:                        boolean;
    filter_whitelist_all_groups:    string;
    filter_whitelist_group_uids:    Array<string>;
    filter_blacklist_group_uids:    Array<string>;
    filter_whitelist_all_sources:   string;
    filter_whitelist_source_uids:   Array<string>;
    filter_blacklist_source_uids:   Array<string>;
    override_login:                 boolean;
    login_enabled:                  boolean;
    override_sfa:                   boolean;
    sfa_mode:                       PolicyV1RulesSfaMode | null;
    sfa_types:                      Array<UserSfaV1Type>;
    override_password_reset:        boolean;
    password_reset_enabled:         boolean;
    password_reset_sfa_mode:        PolicyV1RulesPasswordResetSfaMode | null;
}


interface AdminPolicyAddPageState
{
    initialFormData:    AdminPolicyAddPageFormData;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $AdminPolicyAddPage extends React.Component<AdminPolicyAddPageProps, AdminPolicyAddPageState>
{
    private readonly _groupService:     GroupService;
    private readonly _sourceService:    SourceService;
    private readonly _policyService:    PolicyService;


    constructor ( props: AdminPolicyAddPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:                           '',
                enabled:                        true,
                filter_whitelist_all_groups:    'true',
                filter_whitelist_group_uids:    [],
                filter_blacklist_group_uids:    [],
                filter_whitelist_all_sources:   'true',
                filter_whitelist_source_uids:   [],
                filter_blacklist_source_uids:   [],
                override_login:                 false,
                login_enabled:                  true,
                override_sfa:                   false,
                sfa_mode:                       null,
                sfa_types:                      [],
                override_password_reset:        false,
                password_reset_enabled:         false,
                password_reset_sfa_mode:        null
            },
            error:      null,
            loading:    false,
            success:    null
        };

        this._groupService = GroupService.getInstance();
        this._sourceService = SourceService.getInstance();
        this._policyService = PolicyService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._searchGroups = this._searchGroups.bind(this);
        this._searchSources = this._searchSources.bind(this);
        this._add = this._add.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _searchGroups (params: IcInputSelectOnSearchParams<string> ): Promise<Array<IcInputSelectItem<string>>>
    {
        let groups: Array<GroupV1> = [];

        if ( params.value )
        {
            const group = await this._groupService.getGroup(params.value);

            groups = [group];
        }
        else if ( params.query && params.query.trim() )
        {
            const query = params.query.trim().toLowerCase();

            const orgGroups = await this._groupService.getGroups(0, 100);  // TODO

            groups = orgGroups.filter( o => o.name.toLowerCase().includes(query) || o.key.toLowerCase().includes(query) );
        }
        else
        {
            const orgGroups = await this._groupService.getGroups(0, 10);

            groups = orgGroups;
        }

        return groups.map( ( o ) => ({
            label:  o.name,
            value:  o.uid
        }));
    }


    private async _searchSources (params: IcInputSelectOnSearchParams<string> ): Promise<Array<IcInputSelectItem<string>>>
    {
        let sources: Array<SourceV1> = [];

        if ( params.value )
        {
            const source = await this._sourceService.getSource(params.value);

            sources = [source];
        }
        else if ( params.query && params.query.trim() )
        {
            const query = params.query.trim().toLowerCase();

            const orgSources = await this._sourceService.getSources(0, 100);  // TODO

            sources = orgSources.filter( o => o.name.toLowerCase().includes(query) );
        }
        else
        {
            const orgSources = await this._sourceService.getSources(0, 10);

            sources = orgSources;
        }

        return sources.map( ( o ) => ({
            label:  o.name,
            value:  o.uid
        }));
    }


    private async _add ( formData: AdminPolicyAddPageFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._policyService.addPolicy({
                name:  formData.name.trim(),
                enabled: formData.enabled,
                config: {
                    filter: {
                        whitelist_all_groups:   formData.filter_whitelist_all_groups === 'true',
                        whitelist_group_uids:   formData.filter_whitelist_group_uids,
                        blacklist_group_uids:   formData.filter_blacklist_group_uids,
                        whitelist_all_sources:  formData.filter_whitelist_all_sources === 'true',
                        whitelist_source_uids:  formData.filter_whitelist_source_uids,
                        blacklist_source_uids:  formData.filter_blacklist_source_uids
                    },
                    rules: {
                        login: formData.override_login ?  {
                            enabled:    formData.login_enabled
                        }: null,
                        sfa: formData.override_sfa ? {
                            mode:       formData.sfa_mode!,
                            types:      formData.sfa_types
                        } : null,
                        password_reset: formData.override_password_reset ?  {
                            enabled:    formData.password_reset_enabled,
                            sfa_mode:   formData.password_reset_sfa_mode!
                        } : null
                    }
                }
            });

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('adminpolicyaddpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'policies'));
        }
        catch ( err )
        {
            console.error(`Error adding policy: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminPolicyAddPageFormData>;

        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'policies')}
                        label={this.props.t('adminpoliciespage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'policy', 'add')}
                        label={this.props.t('adminpolicyaddpage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminpolicyaddpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._add}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminpolicyaddpage.inp_name')}
                                            name='name'
                                            required={true}
                                            validators={[
                                                Validator.minLength(1),
                                                Validator.maxLength(256)
                                            ]}
                                        />
                                    </IcGridItem>
                                </IcGridRow>
                                
                                <IcSeparator />

                                <IcText size={IcTextSize.Heading3}>
                                    {this.props.t('adminpolicyaddpage.txt_filter')}
                                </IcText>

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcFloatRow align={IcFloatRowAlign.Center}>
                                            <IcInputRadio
                                                name='filter_whitelist_all_groups'
                                                value='false'
                                                label={this.props.t('adminpolicyaddpage.inp_filter_whitelist_all_groups_false')}
                                            />

                                            <IcInputRadio
                                                name='filter_whitelist_all_groups'
                                                value='true'
                                                label={this.props.t('adminpolicyaddpage.inp_filter_whitelist_all_groups_true')}
                                            />
                                        </IcFloatRow>
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputMultiSelect
                                            name='filter_whitelist_group_uids'
                                            label={this.props.t('adminpolicyaddpage.inp_filter_whitelist_group_uids')}
                                            onSearch={this._searchGroups}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputMultiSelect
                                            name='filter_blacklist_group_uids'
                                            label={this.props.t('adminpolicyaddpage.inp_filter_blacklist_group_uids')}
                                            onSearch={this._searchGroups}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcFloatRow align={IcFloatRowAlign.Center}>
                                            <IcInputRadio
                                                name='filter_whitelist_all_sources'
                                                value='false'
                                                label={this.props.t('adminpolicyaddpage.inp_filter_whitelist_all_sources_false')}
                                            />

                                            <IcInputRadio
                                                name='filter_whitelist_all_sources'
                                                value='true'
                                                label={this.props.t('adminpolicyaddpage.inp_filter_whitelist_all_sources_true')}
                                            />
                                        </IcFloatRow>
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputMultiSelect
                                            name='filter_whitelist_source_uids'
                                            label={this.props.t('adminpolicyaddpage.inp_filter_whitelist_source_uids')}
                                            onSearch={this._searchSources}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputMultiSelect
                                            name='filter_blacklist_source_uids'
                                            label={this.props.t('adminpolicyaddpage.inp_filter_blacklist_source_uids')}
                                            onSearch={this._searchSources}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <IcSeparator />

                                <IcText size={IcTextSize.Heading3}>
                                    {this.props.t('adminpolicyaddpage.txt_rules_login')}
                                </IcText>

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputCheckbox
                                            name='override_login'
                                            label={this.props.t('adminpolicyaddpage.inp_override_login')}
                                        />
                                    </IcGridItem>

                                    {values.override_login ? 
                                        <>
                                            <IcGridItem s={12}>
                                                <IcInputCheckbox
                                                    name='login_enabled'
                                                    label={this.props.t('adminpolicyaddpage.inp_login_enabled')}
                                                />
                                            </IcGridItem>
                                        </>
                                    : null}
                                </IcGridRow>

                                <IcSeparator />

                                <IcText size={IcTextSize.Heading3}>
                                    {this.props.t('adminpolicyaddpage.txt_rules_sfa')}
                                </IcText>

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputCheckbox
                                            name='override_sfa'
                                            label={this.props.t('adminpolicyaddpage.inp_override_sfa')}
                                        />
                                    </IcGridItem>

                                    {values.override_sfa ? 
                                        <>
                                            <IcGridItem s={12}>
                                                <IcInputSelect
                                                    name='sfa_mode'
                                                    label={this.props.t('adminpolicyaddpage.inp_sfa_mode')}
                                                    required={true}
                                                    options={PolicyV1RulesSfaModes.map( ( o ) => ({
                                                        label:  o,
                                                        value:  o
                                                    }))}
                                                />
                                            </IcGridItem>

                                            <IcGridItem s={12}>
                                                <IcInputMultiSelect
                                                    name='sfa_types'
                                                    label={this.props.t('adminpolicyaddpage.inp_sfa_types')}
                                                    options={UserSfaV1Types.map( ( o ) => ({
                                                        label:  Formatter.sfaType(o),
                                                        value:  o
                                                    }))}
                                                />
                                            </IcGridItem>
                                        </>
                                    : null}
                                </IcGridRow>

                                <IcSeparator />

                                <IcText size={IcTextSize.Heading3}>
                                    {this.props.t('adminpolicyaddpage.txt_rules_password_reset')}
                                </IcText>

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputCheckbox
                                            name='override_password_reset'
                                            label={this.props.t('adminpolicyaddpage.inp_override_password_reset')}
                                        />
                                    </IcGridItem>

                                    {values.override_password_reset ? 
                                        <>
                                            <IcGridItem s={12}>
                                                <IcInputCheckbox
                                                    name='password_reset_enabled'
                                                    label={this.props.t('adminpolicyaddpage.inp_password_reset_enabled')}
                                                />
                                            </IcGridItem>

                                            <IcGridItem s={12}>
                                                <IcInputSelect
                                                    name='password_reset_sfa_mode'
                                                    label={this.props.t('adminpolicyaddpage.inp_password_reset_sfa_mode')}
                                                    required={true}
                                                    options={PolicyV1RulesPasswordResetSfaModes.map( ( o ) => ({
                                                        label:  o,
                                                        value:  o
                                                    }))}
                                                />
                                            </IcGridItem>
                                        </>
                                    : null}
                                </IcGridRow>

                                <RequiredHint />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminpolicyaddpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('adminpolicyaddpage.btn_add')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminPolicyAddPage = withTranslation()(withRouter($AdminPolicyAddPage));
