import React from 'react';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { RegisterPageCredentialsStep, RegisterPageCredentialsStepFormData } from './RegisterPageCredentialsStep/RegisterPageCredentialsStep';
import { Delay } from 'ts-delay';
import { RegisterPageLoadingStep } from './RegisterPageLoadingStep/RegisterPageLoadingStep';
import { RegisterPageSuccessStep } from './RegisterPageSuccessStep/RegisterPageSuccessStep';
import { Gender } from '../../Model/Gender';
import { UserService, UserV1 } from '../../Services/UserService';
import { LocaleService } from '../../Services/LocaleService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RegisterPageConfirmEmailStep, RegisterPageConfirmEmailStepFormData } from './RegisterPageConfirmEmailStep/RegisterPageConfirmEmailStep';
import { Environment } from '../../utils/Environment';
import { IcCard, IcCardPadding, IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface RegisterPageRouteParams
{
    userUID?:           string;
    invitationCode?:    string;
}


export interface RegisterPageProps extends RouteComponentProps<RegisterPageRouteParams>, WithTranslation
{
}


enum RegisterPageStep
{
    Credentials     = 'CREDENTIALS',
    ConfirmEmail    = 'CONFIRM_EMAIL',
    Loading         = 'LOADING',
    Success         = 'SUCCESS'
}


interface RegisterPageFormData
{
    title:                  string;
    gender:                 Gender;
    firstname:              string;
    lastname:               string;
    email:                  string;
    password:               string;
    accept_marketing:       boolean;
}


interface RegisterPageState
{
    formData:       RegisterPageFormData;
    invitedUser:    UserV1 | null;
    userUID:        string | null;
    userSfaUID:     string | null;
    step:           RegisterPageStep;
    error:          Error | null;
}


class $RegisterPage extends React.Component<RegisterPageProps, RegisterPageState>
{
    private readonly _userService:      UserService;
    private readonly _localeService:    LocaleService;


    constructor ( props: RegisterPageProps )
    {
        super(props);

        this.state = {
            formData: {
                title:                  '',
                gender:                 '' as Gender,
                firstname:              '',
                lastname:               '',
                email:                  '',
                password:               '',
                accept_marketing:       false
            },
            invitedUser:    null,
            userUID:        null,
            userSfaUID:     null,
            step:           RegisterPageStep.Credentials,
            error:          null
        };

        this._userService = UserService.getInstance();
        this._localeService = LocaleService.getInstance();

        this._finishCredentials = this._finishCredentials.bind(this);
        this._finishConfirmEmail = this._finishConfirmEmail.bind(this);
    }

    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   RegisterPageStep.Loading,
                error:  null
            });

            let invitedUser: UserV1 | null = null;

            if ( this.props.router.params.userUID &&
                 this.props.router.params.invitationCode )
            {
                invitedUser = await this._userService.getUserByInvitation(
                    this.props.router.params.userUID,
                    this.props.router.params.invitationCode
                );
            }

            this.setState({
                step:       RegisterPageStep.Credentials,
                invitedUser
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                step:   RegisterPageStep.Credentials,
                error:  err as Error
            });
        }
    }


    private async _finishCredentials ( formData: RegisterPageCredentialsStepFormData ): Promise<void>
    {
        try
        {
            this.setState({
                formData: {
                    ...this.state.formData,
                    title:              formData.title.trim(),
                    gender:             formData.gender,
                    firstname:          formData.firstname.trim(),
                    lastname:           formData.lastname.trim(),
                    email:              formData.email.trim(),
                    password:           formData.password.trim(),
                    accept_marketing:   formData.accept_marketing
                },
                step:   RegisterPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            const resp = await this._userService.addUser({
                title:                  formData.title.trim(),
                locale:                 this._localeService.getLocale().get(),
                gender:                 formData.gender || null,
                firstname:              formData.firstname.trim(),
                lastname:               formData.lastname.trim(),
                email:                  formData.email.trim(),
                password:               formData.password.trim(),
                invitation_code:        this.props.router.params.invitationCode || null,
                invitation_user_uid:    this.props.router.params.userUID || null,
                accept_marketing:       formData.accept_marketing
            });

            await delay.sleep();

            this.setState({
                step:       RegisterPageStep.ConfirmEmail,
                userUID:    resp.user_uid,
                userSfaUID: resp.usersfa_uid
            });
        }
        catch ( err )
        {
            console.error(`Error registering in: ${(err as Error).message}`, err);

            this.setState({
                step:   RegisterPageStep.Credentials,
                error:  err as Error
            });
        }
    }


    private async _finishConfirmEmail ( formData: RegisterPageConfirmEmailStepFormData ): Promise<void>
    {
        try
        {
            if ( !this.state.userUID || !this.state.userSfaUID )
            {
                throw new Error('No user or user sfa uid set');
            }

            this.setState({
                step:   RegisterPageStep.Loading,
                error:  null
            });

            const delay = new Delay(1000);

            await this._userService.confirmSfa(
                this.state.userUID,
                this.state.userSfaUID,
                {
                    code:   formData.code,
                }
            );

            await delay.sleep();

            this.setState({
                step:   RegisterPageStep.Success
            });
        }
        catch ( err )
        {
            console.error(`Error confirming email in: ${(err as Error).message}`, err);

            this.setState({
                step:   RegisterPageStep.ConfirmEmail,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('registerpage.txt_register')}
                    hidden={true}
                />

                <IcErrorBox error={this.state.error} />
       
                {Environment.server.registration_disabled ?
                    <IcCard padding={IcCardPadding.Large}>
                        {this.props.t('registerpage.txt_registration_disabled')}
                    </IcCard>
                :
                    <>
                        {this.state.step === RegisterPageStep.Loading ?
                            <RegisterPageLoadingStep />
                        : null}
                        
                        {this.state.step === RegisterPageStep.Credentials ?
                            <RegisterPageCredentialsStep
                                invitedUser={this.state.invitedUser}
                                onFinish={this._finishCredentials}
                            />
                        : null}

                        {this.state.step === RegisterPageStep.ConfirmEmail ?
                            <RegisterPageConfirmEmailStep
                                onFinish={this._finishConfirmEmail}
                            />
                        : null}

                        {this.state.step === RegisterPageStep.Success ?
                            <RegisterPageSuccessStep />
                        : null}
                    </>
                }
            </IcPageContent>
        );
    }
}


export const RegisterPage = withTranslation()(withRouter($RegisterPage));
