import React from 'react';
import { Formatter } from '../../../utils/Formatter';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserSfaV1, UserSfaV1Type } from '../../../Services/UserService';
import { List } from '../../../Components/List/List';
import { ListItem } from '../../../Components/List/ListItem';
import { ListItemHeader } from '../../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../../Components/List/ListItemHeaderField';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcGridItem, IcGridPadding, IcGridRow, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';


export interface LoginPageSfaStepProps extends WithTranslation
{
    sfas:               Array<UserSfaV1>;
    availableSfaTypes:  Array<UserSfaV1Type>;
    onFinish:           ( sfa: UserSfaV1 ) => any;
}


class $LoginPageSfaStep extends React.Component<LoginPageSfaStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.None}>
                <IcGridRow padding={IcGridPadding.None}>
                    <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                        <IcText size={IcTextSize.Small}>
                            {this.props.t('loginpagesfastep.txt_subtitle')}
                        </IcText>
                        
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('loginpagesfastep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('loginpagesfastep.txt_select_type')}
                        </IcText>

                        <List>
                            {this.props.sfas.map( ( sfa ) => (
                                <ListItem key={sfa.uid}>
                                    <ListItemHeader>
                                        <ListItemHeaderField
                                            grow={true}
                                            subtext={sfa.name}
                                            onClick={ ( ) => this.props.availableSfaTypes.includes(sfa.type) ? this.props.onFinish(sfa) : null }>
                                            {Formatter.sfaType(sfa.type)}

                                            {!this.props.availableSfaTypes.includes(sfa.type) ?
                                                <IcChip label={this.props.t('loginpagesfastep.txt_not_available')} />
                                            : null}
                                        </ListItemHeaderField>

                                        <ListItemHeaderField>
                                            <IcButton
                                                color={IcButtonColor.Secondary}
                                                onClick={ ( ) => this.props.onFinish(sfa)}
                                                disabled={!this.props.availableSfaTypes.includes(sfa.type)}>
                                                {this.props.t('loginpagesfastep.btn_select')}

                                                <FontAwesomeIcon icon={faAngleRight} />
                                            </IcButton>
                                        </ListItemHeaderField>
                                    </ListItemHeader>
                                </ListItem>
                            )) }
                        </List>
                    </IcGridItem>

                    <IcGridItem s={0} m={4} l={6}>
                        <BoxImage>
                            <ContentImage contentFileKey={ContentFileV1Key.LoginSfaImage} />

                            <SupportOverlay />
                        </BoxImage>
                    </IcGridItem>
                </IcGridRow>
            </IcCard>
        );
    }
}


export const LoginPageSfaStep = withTranslation()($LoginPageSfaStep);
