import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputCheckbox, IcInputText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { SourceV1 } from '../../../Services/SourceService';


export interface AdminSourceEditPageLocalFormStepFormData
{
    name:                           string;
    enabled:                        boolean;
    registration_enabled:           boolean;
    auto_assign_enabled:            boolean;
}


export interface AdminSourceEditPageLocalFormStepProps extends WithTranslation, WithLocaleProps
{
    source:     SourceV1;
    onCancel:   ( ) => any;
    onFinish:   ( formData: AdminSourceEditPageLocalFormStepFormData ) => any;
}


interface AdminSourceEditPageLocalFormStepState
{
    initialFormData:    AdminSourceEditPageLocalFormStepFormData;
}


class $AdminSourceEditPageLocalFormStep extends React.Component<AdminSourceEditPageLocalFormStepProps, AdminSourceEditPageLocalFormStepState>
{
    constructor ( props: AdminSourceEditPageLocalFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:                           '',
                enabled:                        true,
                registration_enabled:           false,
                auto_assign_enabled:            true
            }
        };
    }


    private _applySource ( source: SourceV1 ): void
    {
        this.setState({
            initialFormData: {
                name:                   source.name,
                enabled:                source.enabled,
                registration_enabled:   source.config.registration_enabled,
                auto_assign_enabled:    source.config.auto_assign_enabled
            }
        });
    }


    public componentDidMount ( ): void
    {
        this._applySource(this.props.source);
    }


    public componentDidUpdate ( prevProps: AdminSourceEditPageLocalFormStepProps ): void
    {
        if ( prevProps.source !== this.props.source )
        {
            this._applySource(this.props.source);
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminSourceEditPageLocalFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='enabled'
                                    label={this.props.t('adminsourceeditpagelocalformstep.inp_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='registration_enabled'
                                    label={this.props.t('adminsourceeditpagelocalformstep.inp_registration_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='auto_assign_enabled'
                                    label={this.props.t('adminsourceeditpagelocalformstep.inp_auto_assign_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('adminsourceeditpagelocalformstep.inp_name')}
                                    testID='adminsourceeditpagelocalformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='adminsourceeditpagelocalformstep.btn_cancel'>
                                {this.props.t('adminsourceeditpagelocalformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='adminsourceeditpagelocalformstep.btn_update'>
                                {this.props.t('adminsourceeditpagelocalformstep.btn_update')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminSourceEditPageLocalFormStep = withLocale(withTranslation()($AdminSourceEditPageLocalFormStep));
