import React from 'react';
import DayJS from 'dayjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { JobService, JobV1 } from '../../Services/JobService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcCard, IcCardPadding, IcDummyContent, IcErrorBox, IcPageContent, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';


export interface AdminJobDetailsPageRouteParams
{
    jobUID: string;
}


export interface AdminJobDetailsPageProps extends RouteComponentProps<AdminJobDetailsPageRouteParams>, WithTranslation
{
}


interface AdminJobDetailsPageState
{
    job:        JobV1 | null;
    loading:    boolean;
    error:      Error | null;
}


class $AdminJobDetailsPage extends React.Component<AdminJobDetailsPageProps, AdminJobDetailsPageState>
{
    private readonly _jobService:   JobService;


    constructor ( props: AdminJobDetailsPageProps )
    {
        super(props);

        this.state = {
            job:        null,
            loading:    true,
            error:      null
        }

        this._jobService = JobService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const job = await this._jobService.getJob(this.props.router.params.jobUID);

            this.setState({
                job,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading job: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'jobs')}
                        label={this.props.t('adminjobspage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'job', this.props.router.params.jobUID)}
                        label={this.props.t('adminjobdetailspage.txt_title')}
                    />
                </IcBreadcrumbs>
                
                <PageTitle
                    title={this.props.t('adminjobdetailspage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                <IcCard padding={IcCardPadding.Small}>
                    <List>
                        <ListItem>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    text={this.props.t('adminjobdetailspage.txt_datetime_created')}
                                    grow={true}
                                />
                                
                                <ListItemHeaderField>
                                    {this.state.job ?
                                        DayJS(this.state.job.datetime_created).format('YYYY-MM-DD HH:mm:ss')
                                    :
                                        <IcDummyContent />
                                    }
                                </ListItemHeaderField>
                            </ListItemHeader>
                        </ListItem>
                        
                        <ListItem>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    text={this.props.t('adminjobdetailspage.txt_type')}
                                    grow={true}
                                />
                                
                                <ListItemHeaderField>
                                    {this.state.job ?
                                        this.state.job.type
                                    :
                                        <IcDummyContent />
                                    }
                                </ListItemHeaderField>
                            </ListItemHeader>
                        </ListItem>

                        <ListItem>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    text={this.props.t('adminjobdetailspage.txt_status')}
                                    grow={true}
                                />
                                
                                <ListItemHeaderField>
                                    {this.state.job ?
                                        this.state.job.status
                                    :
                                        <IcDummyContent />
                                    }
                                </ListItemHeaderField>
                            </ListItemHeader>
                        </ListItem>

                        <ListItem>
                            <ListItemHeader>
                                <ListItemHeaderField
                                    text={this.props.t('adminjobdetailspage.txt_details')}
                                    grow={true}
                                />
                                
                                <ListItemHeaderField>
                                    {this.state.job ?
                                        this.state.job.details
                                    :
                                        <IcDummyContent />
                                    }
                                </ListItemHeaderField>
                            </ListItemHeader>
                        </ListItem>
                    </List>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminJobDetailsPage = withTranslation()(withRouter($AdminJobDetailsPage));
