import { Form, Formik } from 'formik';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcButton, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface ResetPasswordPageEmailStepProps extends WithTranslation
{
    onFinish: ( formData: ResetPasswordPageEmailStepFormData ) => any;
}


export interface ResetPasswordPageEmailStepFormData
{
    email:    string;
}


interface ResetPasswordPageEmailStepState
{
    initialFormData:    ResetPasswordPageEmailStepFormData;
}


class $ResetPasswordPageEmailStep extends React.Component<ResetPasswordPageEmailStepProps, ResetPasswordPageEmailStepState>
{
    constructor ( props: ResetPasswordPageEmailStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                email:    ''
            }
        };

        this._submit = this._submit.bind(this);
    }


    private _submit ( formData: ResetPasswordPageEmailStepFormData ): void
    {
        this.props.onFinish(formData);
    }


    public render ( )
    {
        const MyFormik = Formik<ResetPasswordPageEmailStepFormData>;
    
        return (
            <IcCard padding={IcCardPadding.Large}>
                <MyFormik
                    initialValues={this.state.initialFormData}
                    onSubmit={this._submit}
                    enableReinitialize={true}>
                    <Form>
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('resetpasswordpageemailstep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('resetpasswordpageemailstep.txt_insert_email')}
                        </IcText>

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    type='email'
                                    name='email'
                                    label={this.props.t('resetpasswordpageemailstep.inp_email')}
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton type='submit'>
                                {this.props.t('resetpasswordpageemailstep.btn_continue')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    }
}


export const ResetPasswordPageEmailStep = withTranslation()($ResetPasswordPageEmailStep);
