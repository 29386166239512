import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { GroupService, GroupV1 } from '../../Services/GroupService';
import { WithTranslation, withTranslation } from 'react-i18next';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminGroupsPageProps extends WithTranslation
{
}


interface AdminGroupsPageState
{
    groups:     Array<GroupV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminGroupsPage extends React.Component<AdminGroupsPageProps, AdminGroupsPageState>
{
    private readonly BULK_SIZE      = 50;
    private readonly _groupService: GroupService;


    constructor ( props: AdminGroupsPageProps )
    {
        super(props);

        this.state = {
            groups:     [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._groupService = GroupService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const groups = await this._groupService.getGroups(
                0,
                this.BULK_SIZE
            );

            this.setState({
                groups,
                hasMore:    groups.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading groups: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const groups = await this._groupService.getGroups(
                this.state.groups.length,
                this.BULK_SIZE
            );

            this.setState({
                groups:     [...this.state.groups, ...groups],
                hasMore:    groups.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading more groups: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'groups')}
                        label={this.props.t('admingroupspage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('admingroupspage.txt_title')} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton
                            color={IcButtonColor.Secondary}
                            to={LinkUtils.make('admin', 'group', 'add')}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                            
                            {this.props.t('admingroupspage.btn_add_group')}
                        </IcButton>
                    </IcFloatRow>

                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('admingroupspage.txt_col_key')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('admingroupspage.txt_col_name')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('admingroupspage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.groups.map( ( group ) => (
                                    <IcTableRow key={group.uid}>
                                        <IcTableCell>
                                            {group.key}
                                        </IcTableCell>

                                        <IcTableCell>
                                            {group.name}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcPopupMenu>
                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'group', group.uid, 'edit')}>
                                                    {this.props.t('admingroupspage.btn_edit')}
                                                </IcPopupMenuItem>

                                                <IcPopupMenuItem to={LinkUtils.make('admin', 'group', group.uid, 'delete')}>
                                                    {this.props.t('admingroupspage.btn_delete')}
                                                </IcPopupMenuItem>
                                            </IcPopupMenu>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.groups.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={3}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('admingroupspage.txt_no_groups')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner active={this.state.loading} />
                    </InfiniteScroll>
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminGroupsPage = withTranslation()($AdminGroupsPage);
