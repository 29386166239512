import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputSelect, IcInputSelectItem, IcInputSelectOnSearchParams, IcInputText, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { SourceService, SourceV1 } from '../../../Services/SourceService';


export interface AdminApiClientAddPageFormStepFormData
{
    source_uid: string;
    name:       string;
}


export interface AdminApiClientAddPageFormStepProps extends WithTranslation, WithLocaleProps
{
    onCancel:   ( ) => any;
    onFinish:   ( formData: AdminApiClientAddPageFormStepFormData ) => any;
}


interface AdminApiClientAddPageFormStepState
{
    initialFormData:    AdminApiClientAddPageFormStepFormData;
}


class $AdminApiClientAddPageFormStep extends React.Component<AdminApiClientAddPageFormStepProps, AdminApiClientAddPageFormStepState>
{
    private readonly _sourceService:    SourceService;


    constructor ( props: AdminApiClientAddPageFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                source_uid: '',
                name:       ''
            }
        };

        this._sourceService = SourceService.getInstance();

        this._searchSources = this._searchSources.bind(this);
    }


    private async _searchSources (params: IcInputSelectOnSearchParams<string> ): Promise<Array<IcInputSelectItem<string>>>
    {
        let sources: Array<SourceV1> = [];

        if ( params.value )
        {
            const source = await this._sourceService.getSource(params.value);

            sources = [source];
        }
        else if ( params.query && params.query.trim() )
        {
            const query = params.query.trim().toLowerCase();

            const orgSources = await this._sourceService.getSources(0, 100);  // TODO

            sources = orgSources.filter( o => o.name.toLowerCase().includes(query) );
        }
        else
        {
            const orgSources = await this._sourceService.getSources(0, 10);

            sources = orgSources;
        }

        return sources.map( ( o ) => ({
            label:  o.name,
            value:  o.uid
        }));
    }


    public render ( )
    {
        const MyFormik = Formik<AdminApiClientAddPageFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputSelect
                                    name='source_uid'
                                    label={this.props.t('adminapiclientaddpageformstep.inp_source')}
                                    required={true}
                                    onSearch={this._searchSources}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('adminapiclientaddpageformstep.inp_name')}
                                    testID='adminapiclientaddpageformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='adminapiclientaddpageformstep.btn_cancel'>
                                {this.props.t('adminapiclientaddpageformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='adminapiclientaddpageformstep.btn_add'>
                                {this.props.t('adminapiclientaddpageformstep.btn_add')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminApiClientAddPageFormStep = withLocale(withTranslation()($AdminApiClientAddPageFormStep));
