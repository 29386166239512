import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { ClientService } from '../../Services/ClientService';
import { sleep } from 'ts-delay';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputText, IcPageContent, IcSeparator, IcSpinner, LinkUtils, Validator, RouteComponentProps, withRouter, IcBreadcrumbs, IcBreadcrumbHome, IcBreadcrumbItem, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../Components/RequiredHint/RequiredHint';


export interface AdminClientAddPageProps extends RouteComponentProps, WithTranslation
{
}


interface AdminClientAddPageFormData
{
    client_id:                  string;
    redirect_uris:              Array<string>;
    logout_uris:                Array<string>;
    cors_uris:                  Array<string>;
    on_user_delete_uri:         string;
    on_user_purge_uri:          string;
    on_organisation_delete_uri: string;
    on_organisation_purge_uri:  string;
}


interface AdminClientAddPageState
{
    initialFormData:    AdminClientAddPageFormData;
    error:              Error | null;
    loading:            boolean;
    success:            string | null;
}


class $AdminClientAddPage extends React.Component<AdminClientAddPageProps, AdminClientAddPageState>
{
    private readonly _clientService:  ClientService;
    

    constructor ( props: AdminClientAddPageProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                client_id:                  '',
                redirect_uris:              [],
                logout_uris:                [],
                cors_uris:                  [],
                on_user_delete_uri:         '',
                on_user_purge_uri:          '',
                on_organisation_delete_uri: '',
                on_organisation_purge_uri:  ''
            },
            error:      null,
            loading:    false,
            success:    null
        };

        this._clientService = ClientService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._add = this._add.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _add ( formData: AdminClientAddPageFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true,
                success:    null
            });

            await this._clientService.addClient({
                client_id:  formData.client_id.trim(),
                config: {
                    redirect_uris:              formData.redirect_uris.map( s => s.trim() ).filter( s => !!s ),
                    logout_uris:                formData.logout_uris.map( s => s.trim() ).filter( s => !!s ),
                    cors_uris:                  formData.cors_uris.map( s => s.trim() ).filter( s => !!s ),
                    on_user_delete_uri:         formData.on_user_delete_uri.trim() || null,
                    on_user_purge_uri:          formData.on_user_purge_uri.trim() || null,
                    on_organisation_delete_uri: formData.on_organisation_delete_uri.trim() || null,
                    on_organisation_purge_uri:  formData.on_organisation_purge_uri.trim() || null,
                }
            });

            this.setState({
                error:      null,
                loading:    false,
                success:    this.props.t('adminclientaddpage.txt_success')
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'clients'));
        }
        catch ( err )
        {
            console.error(`Error adding client: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public render ( )
    {
        const MyFormik = Formik<AdminClientAddPageFormData>;

        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'clients')}
                        label={this.props.t('adminclientspage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'client', 'add')}
                        label={this.props.t('adminclientaddpage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminclientaddpage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <SuccessBox message={this.state.success} />

                <IcSpinner active={this.state.loading} />

                <MyFormik
                    onSubmit={this._add}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    {({ values }) => (
                        <Form>
                            <IcCard padding={IcCardPadding.Small}>
                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            label={this.props.t('adminclientaddpage.inp_client_id')}
                                            name='client_id'
                                            required={true}
                                            validators={[
                                                Validator.minLength(1),
                                                Validator.maxLength(36)
                                            ]}
                                        />
                                    </IcGridItem>
                                </IcGridRow>
                                
                                <IcSeparator />

                                <FieldArray name='redirect_uris'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminclientaddpage.txt_redirect_uris')}
                                            </IcText>

                                            <List>
                                                {values.redirect_uris.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`redirect_uris.${index}`}
                                                                    label={this.props.t('adminclientaddpage.inp_redirect_uri')}
                                                                    validators={[
                                                                        Validator.url
                                                                    ]}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminclientaddpage.btn_delete_redirect_uri')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.redirect_uris.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminclientaddpage.txt_no_redirect_uris')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    onClick={() => arrayHelpers.push('')}
                                                    color={IcButtonColor.Secondary}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />

                                                    {this.props.t('adminclientaddpage.btn_add_redirect_uri')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='logout_uris'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminclientaddpage.txt_logout_uris')}
                                            </IcText>
                                            
                                            <List>
                                                {values.logout_uris.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`logout_uris.${index}`}
                                                                    label={this.props.t('adminclientaddpage.inp_logout_uri')}
                                                                    validators={[
                                                                        Validator.url
                                                                    ]}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminclientaddpage.btn_delete_logout_uri')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.logout_uris.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminclientaddpage.txt_no_logout_uris')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    onClick={() => arrayHelpers.push('')}
                                                    color={IcButtonColor.Secondary}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />

                                                    {this.props.t('adminclientaddpage.btn_add_logout_uri')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <FieldArray name='cors_uris'>
                                    {( arrayHelpers: ArrayHelpers ) => (
                                        <>
                                            <IcText size={IcTextSize.Heading3}>
                                                {this.props.t('adminclientaddpage.txt_cors_uris')}
                                            </IcText>

                                            <List>
                                                {values.cors_uris.map( ( _, index ) => (
                                                    <ListItem key={index}>
                                                        <ListItemHeader>
                                                            <ListItemHeaderField grow={true}>
                                                                <IcInputText
                                                                    name={`cors_uris.${index}`}
                                                                    label={this.props.t('adminclientaddpage.inp_cors_uri')}
                                                                    validators={[
                                                                        Validator.url
                                                                    ]}
                                                                />
                                                            </ListItemHeaderField>

                                                            <ListItemHeaderAction
                                                                icon={faTimes}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                title={this.props.t('adminclientaddpage.btn_delete_cors_uri')}
                                                            />
                                                        </ListItemHeader>
                                                    </ListItem>
                                                ))}

                                                {values.cors_uris.length === 0 ?
                                                    <ListEmpty>
                                                        {this.props.t('adminclientaddpage.txt_no_cors_uris')}
                                                    </ListEmpty>
                                                : null}
                                            </List>

                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    onClick={() => arrayHelpers.push('')}
                                                    color={IcButtonColor.Secondary}>
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                    
                                                    {this.props.t('adminclientaddpage.btn_add_cors_uri')}
                                                </IcButton>
                                            </IcFloatRow>
                                        </>
                                    )}
                                </FieldArray>

                                <IcSeparator />

                                <IcGridRow>
                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_user_delete_uri'
                                            label={this.props.t('adminclientaddpage.inp_on_user_delete_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_user_purge_uri'
                                            label={this.props.t('adminclientaddpage.inp_on_user_purge_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_organisation_delete_uri'
                                            label={this.props.t('adminclientaddpage.inp_on_organisation_delete_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>

                                    <IcGridItem s={12}>
                                        <IcInputText
                                            name='on_organisation_purge_uri'
                                            label={this.props.t('adminclientaddpage.inp_on_organisation_purge_uri')}
                                            validators={[
                                                Validator.url
                                            ]}
                                        />
                                    </IcGridItem>
                                </IcGridRow>

                                <RequiredHint />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        type='button'
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        {this.props.t('adminclientaddpage.btn_cancel')}
                                    </IcButton>

                                    <IcButton
                                        type='submit'
                                        disabled={this.state.loading}>
                                        {this.props.t('adminclientaddpage.btn_add')}
                                    </IcButton>
                                </IcFloatRow>
                            </IcCard>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        );
    } 
}


export const AdminClientAddPage = withTranslation()(withRouter($AdminClientAddPage));
