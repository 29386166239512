import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SourceService, SourceV1 } from '../../Services/SourceService';
import { sleep } from 'ts-delay';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminSourceEditPageLoadingStep } from './AdminSourceEditPageLoadingStep/AdminSourceEditPageLoadingStep';
import { AdminSourceEditPageSuccessStep } from './AdminSourceEditPageSuccessStep/AdminSourceEditPageSuccessStep';
import { IcErrorBox, IcPageContent, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { SourceV1Type } from '../../Services/SourceService';
import { AdminSourceEditPageLdapFormStep, AdminSourceEditPageLdapFormStepFormData } from './AdminSourceEditPageLdapFormStep/AdminSourceEditPageLdapFormStep';
import { AdminSourceEditPageLocalFormStep, AdminSourceEditPageLocalFormStepFormData } from './AdminSourceEditPageLocalFormStep/AdminSourceEditPageLocalFormStep';


export interface AdminSourceEditPageRouteParams
{
    sourceUID:  string;
}


export interface AdminSourceEditPageProps extends RouteComponentProps<AdminSourceEditPageRouteParams>, WithTranslation
{
}


enum AdminSourceEditPageStep
{
    Loading     = 'LOADING',
    LdapForm    = 'LDAP_FORM',
    LocalForm   = 'LOCAL_FORM',
    Success     = 'SUCCESS'
}


interface AdminSourceEditPageState
{
    step:   AdminSourceEditPageStep;
    source: SourceV1 | null;
    error:  Error | null;
}


class $AdminSourceEditPage extends React.Component<AdminSourceEditPageProps, AdminSourceEditPageState>
{
    private readonly _sourceService: SourceService;
    

    constructor ( props: AdminSourceEditPageProps )
    {
        super(props);

        this.state = {
            step:   AdminSourceEditPageStep.Loading,
            source: null,
            error:  null
        };

        this._sourceService = SourceService.getInstance();

        this._cancel = this._cancel.bind(this);
        this._finishLdapForm = this._finishLdapForm.bind(this);
        this._finishLocalForm = this._finishLocalForm.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                step:   AdminSourceEditPageStep.Loading,
                error:  null
            });

            const source = await this._sourceService.getSource(this.props.router.params.sourceUID);

            switch ( source.type )
            {
                case SourceV1Type.Ldap:
                    this.setState({
                        step:   AdminSourceEditPageStep.LdapForm,
                        source
                    });
                    break;
                case SourceV1Type.Local:
                    this.setState({
                        step:   AdminSourceEditPageStep.LocalForm,
                        source
                    });
                    break;
            }
        }
        catch ( err )
        {
            console.error(`Error loading source: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error
            });
        }
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _finishLdapForm ( formData: AdminSourceEditPageLdapFormStepFormData ): Promise<void>
    {
        try
        {
            if ( ! this.state.source )
            {
                throw new Error('No source loaded');
            }

            this.setState({
                step:   AdminSourceEditPageStep.Loading,
                error:  null
            });

            await this._sourceService.updateSource(
                this.state.source.uid,
                {
                    name:       formData.name.trim(),
                    enabled:    formData.enabled,
                    config:     {
                        registration_enabled:   formData.registration_enabled,
                        auto_assign_enabled:    formData.auto_assign_enabled,
                        ldap:                   {
                            uri:                        formData.ldap_uri.trim(),
                            bind_user:                  formData.ldap_bind_user.trim() || null,
                            bind_password:              formData.ldap_bind_password.trim() || null,
                            user_filter_pattern:        formData.ldap_user_filter_pattern.trim() || null,
                            user_username_attribute:    formData.ldap_user_username_attribute.trim(),
                            user_firstname_attribute:   formData.ldap_user_firstname_attribute.trim(),
                            user_lastname_attribute:    formData.ldap_user_lastname_attribute.trim(),
                            user_email_attribute:       formData.ldap_user_email_attribute.trim(),
                            user_base_dn:               formData.ldap_user_base_dn.trim()
                        }
                    }
                }
            );

            this.setState({
                step:   AdminSourceEditPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating ldap source: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminSourceEditPageStep.LdapForm,
                error:  err as Error
            });
        }
    }


    private async _finishLocalForm ( formData: AdminSourceEditPageLocalFormStepFormData ): Promise<void>
    {
        try
        {
            if ( ! this.state.source )
            {
                throw new Error('No source loaded');
            }

            this.setState({
                step:   AdminSourceEditPageStep.Loading,
                error:  null
            });

            await this._sourceService.updateSource(
                this.state.source.uid,
                {
                    name:       formData.name.trim(),
                    enabled:    formData.enabled,
                    config:     {
                        registration_enabled:   formData.registration_enabled,
                        auto_assign_enabled:    formData.auto_assign_enabled,
                        ldap:                   null
                    }
                }
            );

            this.setState({
                step:   AdminSourceEditPageStep.Success
            });

            await sleep(1000);

            this.props.router.navigate(-1);
        }
        catch ( err )
        {
            console.error(`Error updating local source: ${(err as Error).message}`, err);

            this.setState({
                step:   AdminSourceEditPageStep.LocalForm,
                error:  err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('adminsourceeditpage.txt_title')}
                />

                <IcErrorBox error={this.state.error} />

                {this.state.step === AdminSourceEditPageStep.Loading ?
                    <AdminSourceEditPageLoadingStep />
                : null}

                {this.state.step === AdminSourceEditPageStep.LdapForm && this.state.source ?
                    <AdminSourceEditPageLdapFormStep
                        source={this.state.source}
                        onCancel={this._cancel}
                        onFinish={this._finishLdapForm}
                    />
                : null}

                {this.state.step === AdminSourceEditPageStep.LocalForm && this.state.source ?
                    <AdminSourceEditPageLocalFormStep
                        source={this.state.source}
                        onCancel={this._cancel}
                        onFinish={this._finishLocalForm}
                    />
                : null}

                {this.state.step === AdminSourceEditPageStep.Success ?
                    <AdminSourceEditPageSuccessStep />
                : null}
            </IcPageContent>
        );
    }
}


export const AdminSourceEditPage = withTranslation()(withRouter($AdminSourceEditPage));
