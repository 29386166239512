import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { UserService, UserV1 } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { Formatter } from '../../utils/Formatter';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcButton, IcButtonColor, IcCard, IcCardPadding, IcErrorBox, IcGridItem, IcGridRow, IcPageContent, IcPopupMenu, IcPopupMenuItem, IcPopupMenuSeparator, IcSearchbar, IcSpinner, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { LockedChip } from '../../Components/Chip/LockedChip';
import { InvitedChip } from '../../Components/Chip/InvitedChip';


export interface AdminUsersPageProps extends WithTranslation
{
}


interface AdminUsersPageState
{
    users:      Array<UserV1>;
    query:      string | null;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


class $AdminUsersPage extends React.Component<AdminUsersPageProps, AdminUsersPageState>
{
    private readonly BULK_SIZE      = 50;
    private readonly _userService:  UserService;


    constructor ( props: AdminUsersPageProps )
    {
        super(props);

        this.state = {
            users:      [],
            query:      null,
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._userService = UserService.getInstance();

        this._load = this._load.bind(this);
        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( query: string | null ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const users = await this._userService.getUsers(
                {
                    query: query ? query.trim() : null
                },
                0,
                this.BULK_SIZE
            );

            this.setState({
                users,
                query:      query ? query.trim() : null,
                hasMore:    users.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading users: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const users = await this._userService.getUsers(
                {
                    query: this.state.query ? this.state.query.trim() : null
                },
                this.state.users.length,
                this.BULK_SIZE
            );

            this.setState({
                users:     [...this.state.users, ...users],
                hasMore:    users.length >= this.BULK_SIZE,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading more users: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load(null);
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'users')}
                        label={this.props.t('adminuserspage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminuserspage.txt_title')} />

                <IcErrorBox error={this.state.error} />

                <IcCard padding={IcCardPadding.Small}>
                    <IcGridRow>
                        <IcGridItem s={6} m={10}>
                            <IcSearchbar
                                onSearch={ ( _, query ) => this._load(query) }
                            />
                        </IcGridItem>

                        <IcGridItem s={6} m={2}>
                            <IcButton
                                color={IcButtonColor.Secondary}
                                to={LinkUtils.make('admin', 'user', 'add')}>
                                <FontAwesomeIcon icon={faPlusSquare} />
                                
                                {this.props.t('adminuserspage.btn_add')}
                            </IcButton>
                        </IcGridItem>
                    </IcGridRow>

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        {this.props.t('adminuserspage.txt_col_name')}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {this.props.t('adminuserspage.txt_col_email')}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        {this.props.t('adminuserspage.txt_col_action')}
                                    </IcTableCell>
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.users.map( ( user ) => (
                                    <IcTableRow key={user.uid}>
                                            <IcTableCell to={LinkUtils.make('admin', 'user', user.uid)}>
                                                {Formatter.username(user)}

                                                {user.invitation_pending ? 
                                                    <InvitedChip date={user.datetime_invited || ''} />
                                                : null}

                                                {user.locked ? 
                                                    <LockedChip />
                                                : null}
                                            </IcTableCell>

                                            <IcTableCell to={LinkUtils.make('admin', 'user', user.uid)}>
                                                {user.email}
                                            </IcTableCell>

                                            <IcTableCell align={IcTableCellAlign.Right}>
                                                <IcPopupMenu>
                                                    <IcPopupMenuItem to={LinkUtils.make('admin', 'user', user.uid)}>
                                                        {this.props.t('adminuserspage.btn_show')}
                                                    </IcPopupMenuItem>

                                                    <IcPopupMenuSeparator />

                                                    <IcPopupMenuItem to={LinkUtils.make('admin', 'user', user.uid, 'edit')}>
                                                        {this.props.t('adminuserspage.btn_edit')}
                                                    </IcPopupMenuItem>

                                                    <IcPopupMenuItem to={LinkUtils.make('admin', 'user', user.uid, 'delete')}>
                                                        {this.props.t('adminuserspage.btn_delete')}
                                                    </IcPopupMenuItem>
                                                </IcPopupMenu>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.users.length === 0 && !this.state.loading ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={3}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            {this.props.t('adminuserspage.txt_no_users')}
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>
                    </InfiniteScroll>

                    <IcSpinner active={this.state.loading} />
                </IcCard>
            </IcPageContent>
        );
    }
}


export const AdminUsersPage = withTranslation()($AdminUsersPage);
