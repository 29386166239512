import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserService, UserV1 } from '../../Services/UserService';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { LabelValueList } from '../../Components/LabelValueList/LabelValueList';
import { LabelValue } from '../../Components/LabelValueList/LabelValue';
import { Formatter } from '../../utils/Formatter';
import { List } from '../../Components/List/List';
import { ListItem } from '../../Components/List/ListItem';
import { ListItemHeader } from '../../Components/List/ListItemHeader';
import { ListItemHeaderField } from '../../Components/List/ListItemHeaderField';
import { ListItemHeaderAction } from '../../Components/List/ListItemHeaderAction';
import { faBan, faPlus, faRefresh, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListEmpty } from '../../Components/List/ListEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { GroupService, GroupV1 } from '../../Services/GroupService';
import { Form, Formik } from 'formik';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcChip, IcDummyContent, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputSelect, IcInputSelectItem, IcInputSelectOnSearchParams, IcPageContent, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { LockedChip } from '../../Components/Chip/LockedChip';
import { sleep } from 'ts-delay';
import { SourceService, SourceV1 } from '../../Services/SourceService';


export interface AdminUserPageRouteParams
{
    userUID:    string;
}


export interface AdminUserPageProps extends RouteComponentProps<AdminUserPageRouteParams>, WithTranslation
{
}


interface AdminUserPageFormData
{
    new_group_uid:  string;
}


interface AdminUserPageState
{
    initalFormData:         AdminUserPageFormData;
    user:                   UserV1 | null;
    ownUser:                UserV1 | null;
    groups:                 Record<string, GroupV1>;
    sources:                Record<string, SourceV1>;
    assigned_group_uids:    Array<string>;
    changed:                boolean;
    loading:                boolean;
    error:                  Error | null;
}


class $AdminUserPage extends React.Component<AdminUserPageProps, AdminUserPageState>
{
    private readonly _userService:      UserService;
    private readonly _groupService:     GroupService;
    private readonly _sourceService:    SourceService;


    constructor ( props: AdminUserPageProps )
    {
        super(props);

        this.state = {
            initalFormData: {
                new_group_uid:  '',
            },
            user:                   null,
            ownUser:                null,
            groups:                 {},
            assigned_group_uids:    [],
            sources:                {},
            changed:                false,
            loading:                true,
            error:                  null
        };

        this._userService = UserService.getInstance();
        this._groupService = GroupService.getInstance();
        this._sourceService = SourceService.getInstance();

        this._updateUserGroups = this._updateUserGroups.bind(this);
        this._searchGroup = this._searchGroup.bind(this);
        this._addGroup = this._addGroup.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                user:       null,
                error:      null,
                loading:    true
            });

            const user = await this._userService.getUser(this.props.router.params.userUID);
            const groupUIDs: Array<string> = [];
            const groups: Record<string, GroupV1> = {};
            const sources: Record<string, SourceV1> = {};

            for ( const userGroup of user.groups )
            {
                groupUIDs.push(userGroup.group.uid);
                groups[userGroup.group.uid] = userGroup.group;
            }

            for ( const userSource of user.sources )
            {
                const source = await this._sourceService.getSource(userSource.source_uid);

                sources[source.uid] = source;
            }

            this.setState({
                initalFormData: {
                    ...this.state.initalFormData,
                },
                user,
                groups,
                sources,
                assigned_group_uids:    groupUIDs,
                changed:                false,
                error:                  null,
                loading:                false
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _searchGroup ( params: IcInputSelectOnSearchParams<string> ): Promise<Array<IcInputSelectItem<string>>>
    {
        const query = params.query?.trim();

        let groups = await this._groupService.getGroups(0, 100); // TOOD

        if ( query )
        {
            groups = groups.filter( o => o.key.includes(query) );
        }

        return groups.map( ( o ) => ({
            label:  o.name,
            value:  o.uid
        }));
    }


    private async _addGroup ( formData: AdminUserPageFormData ): Promise<void>
    {
        if ( !formData.new_group_uid )
        {
            return;
        }

        if ( this.state.assigned_group_uids.includes(formData.new_group_uid) )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const group = await this._groupService.getGroup(formData.new_group_uid);

            this.setState({
                groups:     {
                    ...this.state.groups,
                    [group.uid]: group,
                },
                assigned_group_uids: [
                    ...this.state.assigned_group_uids,
                    group.uid
                ],
                initalFormData: {
                    new_group_uid:  '-'
                },
                changed:    true,
                loading:    false
            });

            await sleep(50);

            this.setState({
                initalFormData: {
                    new_group_uid:  ''
                }
            });
        }
        catch ( err )
        {
            console.error(`Error loading group: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _removeGroup ( groupUID: string ): void
    {
        this.setState({
            assigned_group_uids: this.state.assigned_group_uids.filter( s => s !== groupUID ),
            changed:    true,
            loading:    false
        });
    }


    private async _updateUserGroups ( ): Promise<void>
    {
        if ( this.state.loading || !this.state.user )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            for ( const groupUID of this.state.assigned_group_uids )
            {
                if ( this.state.user.groups.find( o => o.group.uid === groupUID ) )
                {
                    // Already assigned
                    continue;
                }

                await this._userService.assignUserGroup(this.state.user.uid, groupUID);
            }

            for ( const userGroup of this.state.user.groups )
            {
                if ( this.state.assigned_group_uids.includes(userGroup.group.uid) )
                {
                    // Still assigned

                    continue;
                }

                await this._userService.unassignUserGroup(this.state.user.uid, userGroup.group.uid);
            }

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error assigning groups to user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._userService.isLoggedIn().subscribe(this, ( ownUser ) =>
        {
            this.setState({
                ownUser
            });
        });

        const ownUser = this._userService.isLoggedIn().get();
        this.setState({
            ownUser
        });

        await this._load();
    }


    public componentWillUnmount ( ): void
    {
        this._userService.isLoggedIn().unsubscribe(this);
    }


    public render ( )
    {
        const MyFormik = Formik<AdminUserPageFormData>;

        return (
            <IcPageContent>
                <PageTitle title={this.props.t('adminuserpage.txt_user')} />

                <IcErrorBox error={this.state.error} />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('adminuserpage.txt_general')}
                            </IcText>

                            <LabelValueList>
                                <LabelValue label={this.props.t('adminuserpage.txt_name')}>
                                    {this.state.user ?
                                        <>
                                            {Formatter.username(this.state.user)}

                                            {this.state.user.invitation_pending ?
                                                <IcChip
                                                    label={this.props.t('adminuserpage.txt_invitation_sent', {date: Formatter.date(this.state.user.datetime_invited || '')})}
                                                />
                                            : null}

                                            {this.state.user.locked ?
                                                <LockedChip />
                                            : null}
                                        </>
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>

                                <LabelValue label={this.props.t('adminuserpage.txt_email')}>
                                    {this.state.user ?
                                        this.state.user.email
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>

                                <LabelValue label={this.props.t('adminuserpage.txt_sources')}>
                                    {this.state.user ?
                                        this.state.user.sources.map( ( userSource ) => this.state.sources[userSource.source_uid]?.name || '??' ).join(', ') || '-'
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                            </LabelValueList>

                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                {this.state.user && !this.state.user.locked ?
                                    <IcButton
                                        to={LinkUtils.make('admin', 'user', this.state.user.uid, 'lock')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faBan} />
                                        {this.props.t('adminuserpage.btn_lock')}
                                    </IcButton>
                                : null}

                                {this.state.user && this.state.user.locked ?
                                    <IcButton
                                        to={LinkUtils.make('admin', 'user', this.state.user.uid, 'unlock')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faCheckCircle} />
                                        {this.props.t('adminuserpage.btn_unlock')}
                                    </IcButton>
                                : null}

                                {this.state.user ?
                                    <IcButton
                                        to={LinkUtils.make('admin', 'user', this.state.user.uid, 'edit')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                        {this.props.t('adminuserpage.btn_edit')}
                                    </IcButton>
                                : null}

                                {this.state.user ?
                                    <IcButton
                                        to={LinkUtils.make('admin', 'user', this.state.user.uid, 'reset-password')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faRefresh} />
                                        {this.props.t('adminuserpage.btn_reset_password_sfa')}
                                    </IcButton>
                                : null}
                                
                                {this.state.user ?
                                    <IcButton
                                        to={LinkUtils.make('admin', 'user', this.state.user.uid, 'delete')}
                                        color={IcButtonColor.Secondary}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        {this.props.t('adminuserpage.btn_delete')}
                                    </IcButton>
                                : null}
                            </IcFloatRow>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <MyFormik
                    initialValues={this.state.initalFormData}
                    enableReinitialize={true}
                    onSubmit={this._addGroup}>
                    {( formHelpers ) => (
                        <Form>
                            <IcGridRow>
                                <IcGridItem s={12}>
                                    <IcCard padding={IcCardPadding.Small}>
                                        <IcText size={IcTextSize.Heading3}>
                                            {this.props.t('adminuserpage.txt_groups')}
                                        </IcText>

                                        {this.state.changed ?
                                            <IcFloatRow align={IcFloatRowAlign.Right}>
                                                <IcButton
                                                    type='button'
                                                    onClick={this._updateUserGroups}>
                                                    {this.props.t('')}
                                                </IcButton>
                                            </IcFloatRow>
                                        : null}

                                        <List>
                                            <ListItem>
                                                <ListItemHeader>
                                                    <ListItemHeaderField grow={true}>
                                                        <IcInputSelect
                                                            name='new_group_uid'
                                                            onSearch={this._searchGroup}
                                                            label={this.props.t('')}
                                                        />
                                                    </ListItemHeaderField>

                                                    <ListItemHeaderAction
                                                        onClick={ ( ) => formHelpers.submitForm() }
                                                        icon={faPlus}
                                                    />
                                                </ListItemHeader>
                                            </ListItem>

                                            {this.state.assigned_group_uids.map( ( groupUID ) => (
                                                <ListItem key={groupUID}>
                                                    <ListItemHeader>
                                                        <ListItemHeaderField
                                                            grow={true}
                                                            text={this.state.groups[groupUID].key}
                                                        />

                                                        <ListItemHeaderField
                                                            grow={true}
                                                            text={this.state.groups[groupUID].name}
                                                        />
                                                        
                                                        <ListItemHeaderAction
                                                            icon={faTimes}
                                                            onClick={ ( ) => this._removeGroup(groupUID)}
                                                            title={this.props.t('adminuserpage.btn_group_unassign')}
                                                        />
                                                    </ListItemHeader>
                                                </ListItem>
                                            ))}

                                            {this.state.assigned_group_uids.length === 0 && !this.state.loading ?
                                                <ListEmpty>
                                                    {this.props.t('adminuserpage.txt_no_groups')}
                                                </ListEmpty>
                                            : null}
                                        </List>
                                    </IcCard>
                                </IcGridItem>
                            </IcGridRow>
                        </Form>
                    )}
                </MyFormik>
            </IcPageContent>
        )
    }
}


export const AdminUserPage = withTranslation()(withRouter($AdminUserPage));
