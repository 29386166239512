import React from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Gender, Genders } from '../../../Model/Gender';
import { Formatter } from '../../../utils/Formatter';
import { AuthContextProps, withAuth } from 'oidc-react';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { UserV1 } from '../../../Services/UserService';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { Link } from 'react-router-dom';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcGridItem, IcGridPadding, IcGridRow, IcInputCheckbox, IcInputSelect, IcInputText, IcText, IcTextSize, LinkUtils, Validator } from '@indece-common/ic-ui-lib-react';


export interface RegisterPageCredentialsStepProps extends WithTranslation, AuthContextProps
{
    invitedUser:    UserV1 | null;
    onFinish:       ( formData: RegisterPageCredentialsStepFormData ) => any;
}


export interface RegisterPageCredentialsStepFormData
{
    title:                  string;
    gender:                 Gender;
    firstname:              string;
    lastname:               string;
    email:                  string;
    password:               string;
    password_confirm:       string;
    accept_terms:           boolean;
    accept_privacypolicy:   boolean;
    accept_marketing:       boolean;
}


interface RegisterPageCredentialsStepState
{
    initialFormData: RegisterPageCredentialsStepFormData;
}


class $RegisterPageCredentialsStep extends React.Component<RegisterPageCredentialsStepProps, RegisterPageCredentialsStepState>
{
    constructor ( props: RegisterPageCredentialsStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                title:                  '',
                gender:                 '' as Gender,
                firstname:              '',
                lastname:               '',
                email:                  '',
                password:               '',
                password_confirm:       '',
                accept_terms:           false,
                accept_privacypolicy:   false,
                accept_marketing:       false
            }
        };

        this._validate = this._validate.bind(this);
        this._submit = this._submit.bind(this);
    }


    private _validate ( formData: RegisterPageCredentialsStepFormData ): FormikErrors<RegisterPageCredentialsStepFormData>
    {
        const errors: FormikErrors<RegisterPageCredentialsStepFormData> = {};

        if ( formData.password.trim() !== formData.password_confirm.trim() )
        {
            errors.password_confirm = this.props.t('registerpagecredentialsstep.err_password_mismatch');
        }

        return errors;
    }


    private _submit ( values: RegisterPageCredentialsStepFormData ): void
    {
        this.props.onFinish(values);
    }


    public componentDidMount ( ): void
    {
        if ( this.props.invitedUser )
        {
            this.setState({
                initialFormData: {
                    ...this.state.initialFormData,
                    title:      this.props.invitedUser.title || '',
                    gender:     (this.props.invitedUser.gender || '') as Gender,
                    email:      this.props.invitedUser.email,
                    firstname:  this.props.invitedUser.firstname,
                    lastname:   this.props.invitedUser.lastname
                }
            });
        }
    }
    
    
    public componentDidUpdate ( prevProps: RegisterPageCredentialsStepProps ): void
    {
        if ( this.props.invitedUser && !prevProps.invitedUser )
        {
            this.setState({
                initialFormData: {
                    ...this.state.initialFormData,
                    title:      this.props.invitedUser.title || '',
                    gender:     (this.props.invitedUser.gender || '') as Gender,
                    email:      this.props.invitedUser.email,
                    firstname:  this.props.invitedUser.firstname,
                    lastname:   this.props.invitedUser.lastname
                }
            });
        }
    }


    public render ( )
    {
        const MyFormik  = Formik<RegisterPageCredentialsStepFormData>;

        return (
            <div className='RegisterPageCredentialsStep'>
                <MyFormik
                    onSubmit={this._submit}
                    initialValues={this.state.initialFormData}
                    validate={this._validate}
                    enableReinitialize={true}>
                    <Form>
                        <IcCard padding={IcCardPadding.None}>
                            <IcGridRow padding={IcGridPadding.None}>
                                <IcGridItem
                                    s={12}
                                    m={8}
                                    l={6}
                                    padding={IcGridPadding.Large}>
                                    <IcText size={IcTextSize.Small}>
                                        {this.props.t('registerpagecredentialsstep.txt_subtitle')}
                                    </IcText>
                                    
                                    <IcText size={IcTextSize.Heading1}>
                                        {this.props.t('registerpagecredentialsstep.txt_title')}
                                    </IcText>

                                    <IcText>
                                        {this.props.t('registerpagecredentialsstep.txt_already_account')}

                                        <IcButton
                                            color={IcButtonColor.Link}
                                            onClick={ ( ) => this.props.signIn() }
                                            type='button'>
                                            {this.props.t('registerpagecredentialsstep.btn_login')}
                                        </IcButton>
                                    </IcText>

                                    <IcGridRow>
                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='title'
                                                label={this.props.t('registerpagecredentialsstep.inp_title')}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputSelect
                                                name='gender'
                                                label={this.props.t('registerpagecredentialsstep.inp_gender')}
                                                options={Genders.map( ( gender ) => ({
                                                    label:  Formatter.gender(gender),
                                                    value:  gender
                                                }))}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='firstname'
                                                label={this.props.t('registerpagecredentialsstep.inp_firstname')}
                                                required={true}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='lastname'
                                                label={this.props.t('registerpagecredentialsstep.inp_lastname')}
                                                required={true}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='email'
                                                type='email'
                                                label={this.props.t('registerpagecredentialsstep.inp_email')}
                                                required={true}
                                                validators={[Validator.email]}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='password'
                                                type='password'
                                                label={this.props.t('registerpagecredentialsstep.inp_password')}
                                                required={true}
                                                validators={[Validator.password]}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='password_confirm'
                                                type='password'
                                                label={this.props.t('registerpagecredentialsstep.inp_password_confirm')}
                                                required={true}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcText>
                                                {this.props.t('validator.password.policy', {length: Validator.PASSWORD_MIN_LENGTH})}
                                            </IcText>
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputCheckbox
                                                name='accept_terms'
                                                required={true}
                                                hideRequired={true}>
                                                {this.props.t('registerpagecredentialsstep.inp_accept_terms')} (<Link to={LinkUtils.make('terms')} target='_blank'>{this.props.t('registerpagecredentialsstep.btn_terms')}</Link>)
                                            </IcInputCheckbox>
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputCheckbox
                                                name='accept_privacypolicy'
                                                required={true}
                                                hideRequired={true}>
                                                {this.props.t('registerpagecredentialsstep.inp_accept_privacypolicy')} (<Link to={LinkUtils.make('privacypolicy')} target='_blank'>{this.props.t('registerpagecredentialsstep.btn_privacypolicy')}</Link>)
                                            </IcInputCheckbox>
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputCheckbox
                                                name='accept_marketing'
                                                label={this.props.t('registerpagecredentialsstep.inp_accept_marketing')}
                                            />
                                        </IcGridItem>
                                    </IcGridRow>


                                    <RequiredHint />

                                    <IcButton
                                        type='submit'
                                        fullWidth={true}>
                                        {this.props.t('registerpagecredentialsstep.btn_register')}
                                    </IcButton>
                                </IcGridItem>

                                <IcGridItem s={0} m={4} l={6}>
                                    <BoxImage>
                                        <ContentImage contentFileKey={ContentFileV1Key.RegisterCredentialsImage} />

                                        <SupportOverlay />
                                    </BoxImage>
                                </IcGridItem>
                            </IcGridRow>
                        </IcCard>
                    </Form>
                </MyFormik>
            </div>
        );
    }
}


export const RegisterPageCredentialsStep = withTranslation()(withAuth($RegisterPageCredentialsStep));
