import React from 'react';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserV1, UserService } from '../../Services/UserService';
import { Formatter } from '../../utils/Formatter';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { LabelValueList } from '../../Components/LabelValueList/LabelValueList';
import { LabelValue } from '../../Components/LabelValueList/LabelValue';
import { AuthContextProps, withAuth } from 'oidc-react';
import { AccountPageSfasBox } from './AccountPageSfasBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcDummyContent, IcGridItem, IcGridRow, IcPageContent, IcText, IcTextSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { LockedChip } from '../../Components/Chip/LockedChip';


export interface AccountPageProps extends RouteComponentProps, WithTranslation, AuthContextProps
{
}


interface AccountPageState
{
    user:           UserV1 | null;
    error:          Error | null;
    loading:        boolean;
}


class $AccountPage extends React.Component<AccountPageProps, AccountPageState>
{
    private readonly _userService:          UserService;
    

    constructor ( props: AccountPageProps )
    {
        super(props);

        this.state = {
            user:       null,
            error:      null,
            loading:    true
        };

        this._userService   = UserService.getInstance();

        this._logout    = this._logout.bind(this);
    }


    private async _logout ( ): Promise<void>
    {
        await this.props.signOutRedirect();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const user = await this._userService.getOwnUser();

            this.setState({
                user,
                error:      null,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading user: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <IcPageContent>
                <PageTitle
                    title={this.props.t('accountpage.txt_account')}
                />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <IcCard padding={IcCardPadding.Small}>
                            <LabelValueList>
                                <LabelValue label={this.props.t('accountpage.txt_name')}>
                                    {this.state.user ?
                                        <>
                                            {Formatter.username(this.state.user)}

                                            {this.state.user.locked ?
                                                <LockedChip />
                                            : null}
                                        </>
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>

                                <LabelValue label={this.props.t('accountpage.txt_email')}>
                                    {this.state.user ?
                                        this.state.user.email
                                    :
                                        <IcDummyContent />
                                    }
                                </LabelValue>
                            </LabelValueList>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12} m={6} l={4}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('accountpage.txt_title_password')}
                            </IcText>
                            
                            <IcButton
                                to={LinkUtils.make('account', 'update_password')}
                                color={IcButtonColor.Secondary}>
                                <FontAwesomeIcon icon={faPenToSquare} />

                                {this.props.t('accountpage.btn_update_password')}
                            </IcButton>
                        </IcCard>
                    </IcGridItem>
                    
                    <IcGridItem s={12} m={6} l={4}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('accountpage.txt_title_email')}
                            </IcText>
                            
                            <IcButton
                                to={LinkUtils.make('account', 'update_email')}
                                color={IcButtonColor.Secondary}>
                                <FontAwesomeIcon icon={faPenToSquare} />
                                {this.props.t('accountpage.btn_update_email')}
                            </IcButton>
                        </IcCard>
                    </IcGridItem>
                    
                    <IcGridItem s={12} m={6} l={4}>
                        <IcCard padding={IcCardPadding.Small}>
                            <IcText size={IcTextSize.Heading3}>
                                {this.props.t('accountpage.txt_title_logout')}
                            </IcText>
                            
                            <IcButton
                                onClick={this._logout}
                                color={IcButtonColor.Secondary}>
                                {this.props.t('accountpage.btn_logout')}
                                <FontAwesomeIcon icon={faSignOut} />
                            </IcButton>
                        </IcCard>
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <AccountPageSfasBox />
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    } 
}


export const AccountPage = withTranslation()(withAuth(withRouter($AccountPage)));
