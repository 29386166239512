import React from 'react';
import Cookies from 'universal-cookie';
import i18n, { t } from 'i18next';
import DayJS from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { LoginPage } from './Pages/LoginPage/LoginPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookieHintModal } from './Modals/CookieHintModal/CookieHintModal';
import { HomePage } from './Pages/HomePage/HomePage';
import { RouteGuard, UserProfileRole } from './Components/RouteGuard/RouteGuard';
import { AccountPage } from './Pages/AccountPage/AccountPage';
import { AccountUpdatePasswordPage } from './Pages/AccountUpdatePasswordPage/AccountUpdatePasswordPage';
import { Locale } from './Model/Locale';
import { initReactI18next } from 'react-i18next';
import { MetadataService } from './Services/MetadataService';
import { RegisterPage } from './Pages/RegisterPage/RegisterPage';
import { NavBar } from './Components/NavBar/NavBar';
import { Error404Page } from './Pages/Error404Page/Error404Page';
import { AdminPage } from './Pages/AdminPage/AdminPage';
import { SetupPage } from './Pages/SetupPage/SetupPage';
import { Environment } from './utils/Environment';
import { AuthController } from './Controllers/AuthController/AuthController';
import { ScrollToTopController } from './Controllers/ScrollToTopController/ScrollToTopController';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { AdminUsersPage } from './Pages/AdminUsersPage/AdminUsersPage';
import { AdminClientsPage } from './Pages/AdminClientsPage/AdminClientsPage';
import { AdminClientAddPage } from './Pages/AdminClientAddPage/AdminClientAddPage';
import { LoginSuccessPage } from './Pages/LoginSuccessPage/LoginSuccessPage';
import { LocaleService } from './Services/LocaleService';
import { LocaleController } from './Controllers/LocaleController/LocaleController';
import { AdminClientEditPage } from './Pages/AdminClientEditPage/AdminClientEditPage';
import { Footer } from './Components/Footer/Footer';
import { ImprintPage } from './Pages/ImprintPage/ImprintPage';
import { AdminContentEditPage } from './Pages/AdminContentEditPage/AdminContentEditPage';
import { AdminContentsPage } from './Pages/AdminContentsPage/AdminContentsPage';
import { PrivacyPolicyPage } from './Pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { ResetPasswordPage } from './Pages/ResetPasswordPage/ResetPasswordPage';
import { AccountAddSfaPage } from './Pages/AccountAddSfaPage/AccountAddSfaPage';
import { AccountUpdateEmailPage } from './Pages/AccountUpdateEmailPage/AccountUpdateEmailPage';
import { AccountDeleteSfaPage } from './Pages/AccountDeleteSfaPage/AccountDeleteSfaPage';
import { AdminUserPage } from './Pages/AdminUserPage/AdminUserPage';
import { AdminConfigPage } from './Pages/AdminConfigPage/AdminConfigPage';
import { UserAddPage } from './Pages/UserAddPage/UserAddPage';
import { AdminClientDeletePage } from './Pages/AdminClientDeletePage/AdminClientDeletePage';
import { UserDeletePage } from './Pages/UserDeletePage/UserDeletePage';
import { UserEditPage } from './Pages/UserEditPage/UserEditPage';
import { AdminClientDetailsPage } from './Pages/AdminClientDetailsPage/AdminClientDetailsPage';
import { AdminStatusPage } from './Pages/AdminStatusPage/AdminStatusPage';
import { LogoutSuccessPage } from './Pages/LogoutSuccessPage/LogoutSuccessPage';
import { AdminLogsPage } from './Pages/AdminLogsPage/AdminLogsPage';
import { UserLockPage } from './Pages/UserLockPage/UserLockPage';
import { UserUnlockPage } from './Pages/UserUnlockPage/UserUnlockPage';
import { AdminJobsPage } from './Pages/AdminJobsPage/AdminJobsPage';
import { AdminJobAddSyncAllUsersPage } from './Pages/AdminJobAddSyncAllUsersPage/AdminJobAddSyncAllUsersPage';
import { ClientMissingLicensePage } from './Pages/ClientMissingLicensePage/ClientMissingLicensePage';
import { SideNav } from './Components/SideNav/SideNav';
import { UserResetPasswordPage } from './Pages/UserResetPasswordPage/UserResetPasswordPage';
import { AdminGroupDeletePage } from './Pages/AdminGroupDeletePage/AdminGroupDeletePage';
import { AdminGroupEditPage } from './Pages/AdminGroupEditPage/AdminGroupEditPage';
import { AdminGroupAddPage } from './Pages/AdminGroupAddPage/AdminGroupAddPage';
import { AdminGroupsPage } from './Pages/AdminGroupsPage/AdminGroupsPage';
import { AdminContentFilesPage } from './Pages/AdminContentFilesPage/AdminContentFilesPage';
import { AdminContentFileEditPage } from './Pages/AdminContentFileEditPage/AdminContentFileEditPage';
import { IcErrorBox, Validator } from '@indece-common/ic-ui-lib-react';
import { ErrorTranslator } from './utils/ErrorTranslator';
import { AdminPoliciesPage } from './Pages/AdminPoliciesPage/AdminPoliciesPage';
import { AdminPolicyAddPage } from './Pages/AdminPolicyAddPage/AdminPolicyAddPage';
import { AdminPolicyEditPage } from './Pages/AdminPolicyEditPage/AdminPolicyEditPage';
import { AdminSourcesPage } from './Pages/AdminSourcesPage/AdminSourcesPage';
import { AdminJobAddSyncSourcePage } from './Pages/AdminJobAddSyncSourcePage/AdminJobAddSyncSourcePage';
import { AdminSourceAddPage } from './Pages/AdminSourceAddPage/AdminSourceAddPage';
import { AdminSourceEditPage } from './Pages/AdminSourceEditPage/AdminSourceEditPage';
import { AdminJobDetailsPage } from './Pages/AdminJobDetailsPage/AdminJobDetailsPage';
import { AdminApiClientsPage } from './Pages/AdminApiClientsPage/AdminApiClientsPage';
import { AdminApiClientAddPage } from './Pages/AdminApiClientAddPage/AdminApiClientAddPage';

import './App.css';

import Locale_DE_DE from './locale/de-DE.json';
import Locale_EN_GB from './locale/en-GB.json';
import Locale_EN_US from './locale/en-US.json';


export const resources: Record<Locale, any> = {
    [Locale.DE_DE]: {
        translation: Locale_DE_DE,
    },
    [Locale.EN_GB]: {
        translation: Locale_EN_GB,
    },
    [Locale.EN_US]: {
        translation: Locale_EN_US,
    }
};

i18n.use(initReactI18next).init({
    lng: LocaleService.getInstance().getLocale().get(),
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    compatibilityJSON: 'v3',
    pluralSeparator: '#',
    resources,
});

DayJS.extend(Duration);
DayJS.extend(RelativeTime);

const oidcConfig: AuthProviderProps = {
    onSignIn: ( userData ) =>
    {
        const cookies = new Cookies(null, {path: '/'});

        cookies.set('ic-licenser-signedin', '1', {
            path:       '/',
            secure:     false,
            httpOnly:   false
        });
    },
    onSignOut: ( ) => 
    {
        const cookies = new Cookies(null, {path: '/'});

        cookies.remove('ic-licenser-signedin');
    },
    autoSignIn: false,
    authority: Environment.oauth.authority,
    clientId: Environment.oauth.clientId,
    redirectUri: Environment.oauth.redirectUri + '/login-success',
    postLogoutRedirectUri: Environment.oauth.redirectUri + '/logout-success'
};

Validator.formatter = ( key, args ) => t(`validator.${key}`, args);
IcErrorBox.translate = ( err ) => ErrorTranslator.translate(err) || err.message;


export class App extends React.Component
{
    private readonly _metadataService:  MetadataService;


    constructor ( props: any )
    {
        super(props);

        this._metadataService = MetadataService.getInstance();

        this._metadataService.setTitle('');
        this._metadataService.setDescription('');
    }


    public render ( )
    {
        return (
            <BrowserRouter>
                <AuthProvider {...oidcConfig}>
                    <div className='App'>
                        <NavBar />

                        <div className='App-content'>
                            <SideNav />
                            <div className='App-content-main'>
                                <Routes>
                                    <Route
                                        path='/'
                                        element={<HomePage />}
                                    />

                                    <Route
                                        path='/register'
                                        element={<RegisterPage />}
                                    />

                                    <Route
                                        path='/join/:userUID/:invitationCode'
                                        element={<RegisterPage />}
                                    />

                                    <Route
                                        path='/login'
                                        element={<LoginPage />}
                                    />

                                    <Route
                                        path='/reset_password'
                                        element={<ResetPasswordPage />}
                                    />

                                    <Route
                                        path='/client-missing-license/:licenseTag'
                                        element={<ClientMissingLicensePage />}
                                    />

                                    <Route
                                        path='/imprint'
                                        element={<ImprintPage />}
                                    />

                                    <Route
                                        path='/privacypolicy'
                                        element={<PrivacyPolicyPage />}
                                    />

                                    <Route
                                        path='/account'
                                        element={<RouteGuard component={AccountPage} />}
                                    />

                                    <Route
                                        path='/account/update_password'
                                        element={<RouteGuard component={AccountUpdatePasswordPage} />}
                                    />

                                    <Route
                                        path='/account/update_email'
                                        element={<RouteGuard component={AccountUpdateEmailPage} />}
                                    />

                                    <Route
                                        path='/account/sfa/add'
                                        element={<RouteGuard component={AccountAddSfaPage} />}
                                    />

                                    <Route
                                        path='/account/sfa/:sfaUID/delete'
                                        element={<RouteGuard component={AccountDeleteSfaPage} />}
                                    />

                                    <Route
                                        path='/admin'
                                        element={<RouteGuard
                                            component={AdminPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/status'
                                        element={<RouteGuard
                                            component={AdminStatusPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/logs'
                                        element={<RouteGuard
                                            component={AdminLogsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/groups'
                                        element={<RouteGuard
                                            component={AdminGroupsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/group/add'
                                        element={<RouteGuard
                                            component={AdminGroupAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/group/:groupUID/edit'
                                        element={<RouteGuard
                                            component={AdminGroupEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/group/:groupUID/delete'
                                        element={<RouteGuard
                                            component={AdminGroupDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/users'
                                        element={<RouteGuard
                                            component={AdminUsersPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/user/add'
                                        element={<RouteGuard component={UserAddPage} />}
                                    />

                                    <Route
                                        path='/admin/user/:userUID'
                                        element={<RouteGuard
                                            component={AdminUserPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/user/:userUID/edit'
                                        element={<RouteGuard
                                            component={UserEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/user/:userUID/reset-password'
                                        element={<RouteGuard
                                            component={UserResetPasswordPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/user/:userUID/lock'
                                        element={<RouteGuard
                                            component={UserLockPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/user/:userUID/unlock'
                                        element={<RouteGuard
                                            component={UserUnlockPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/user/:userUID/delete'
                                        element={<RouteGuard
                                            component={UserDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/policies'
                                        element={<RouteGuard
                                            component={AdminPoliciesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/policy/add'
                                        element={<RouteGuard
                                            component={AdminPolicyAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/policy/:policyUID/edit'
                                        element={<RouteGuard
                                            component={AdminPolicyEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/sources'
                                        element={<RouteGuard
                                            component={AdminSourcesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/source/add'
                                        element={<RouteGuard
                                            component={AdminSourceAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/source/:sourceUID/edit'
                                        element={<RouteGuard
                                            component={AdminSourceEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/clients'
                                        element={<RouteGuard
                                            component={AdminClientsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/add'
                                        element={<RouteGuard
                                            component={AdminClientAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/:clientUID'
                                        element={<RouteGuard
                                            component={AdminClientDetailsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/:clientUID/edit'
                                        element={<RouteGuard
                                            component={AdminClientEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/client/:clientUID/delete'
                                        element={<RouteGuard
                                            component={AdminClientDeletePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/apiclients'
                                        element={<RouteGuard
                                            component={AdminApiClientsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/apiclient/add'
                                        element={<RouteGuard
                                            component={AdminApiClientAddPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/contents'
                                        element={<RouteGuard
                                            component={AdminContentsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/content/:contentKey/:locale/edit'
                                        element={<RouteGuard
                                            component={AdminContentEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/contentfiles'
                                        element={<RouteGuard
                                            component={AdminContentFilesPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/contentfile/:contentFileKey/:locale/edit'
                                        element={<RouteGuard
                                            component={AdminContentFileEditPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/config'
                                        element={<RouteGuard
                                            component={AdminConfigPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/jobs'
                                        element={<RouteGuard
                                            component={AdminJobsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/job/add/sync-all-users'
                                        element={<RouteGuard
                                            component={AdminJobAddSyncAllUsersPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/job/add/sync-source'
                                        element={<RouteGuard
                                            component={AdminJobAddSyncSourcePage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/admin/job/:jobUID'
                                        element={<RouteGuard
                                            component={AdminJobDetailsPage}
                                            globalRoles={[UserProfileRole.Admin]}
                                        />}
                                    />

                                    <Route
                                        path='/setup'
                                        element={<SetupPage />}
                                    />

                                    <Route
                                        path='/login-success'
                                        element={<LoginSuccessPage />}
                                    />

                                    <Route
                                        path='/logout-success'
                                        element={<LogoutSuccessPage />}
                                    />

                                    <Route
                                        path='*'
                                        element={<Error404Page />}
                                    />
                                </Routes>
                            </div>
                        </div>

                        <Footer />
                    </div>

                    <CookieHintModal />

                    <LocaleController />
                    <AuthController />
                    <ScrollToTopController />
                </AuthProvider>
            </BrowserRouter>
        );
    }
}
