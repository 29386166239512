import { BackendService } from './BackendService';


export interface ApiClientV1Config
{
}

export interface ApiClientV1Status
{
    connected:  boolean;
}


export interface ApiClientV1
{
    uid:        string;
    source_uid: string;
    name:       string;
    config:     ApiClientV1Config;
    status:     ApiClientV1Status;
}


export interface AddApiClientV1
{
    source_uid: string;
    name:       string;
    config:     ApiClientV1Config;
}


export interface UpdateApiClientV1
{
    name:       string;
    config:     ApiClientV1Config;
}


export class ApiClientService
{
    private static _instance: ApiClientService;
    
    
    public static getInstance ( ): ApiClientService
    {
        if ( ! this._instance )
        {
            this._instance = new ApiClientService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getApiClients ( from: number, size: number ): Promise<Array<ApiClientV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/apiclient?` +
            `from=${encodeURIComponent(from)}&` +
            `size=${encodeURIComponent(size)}`
        );

        return resp.apiclients;
    }


    public async getApiClient ( apiClientUID: string ): Promise<ApiClientV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/apiclient/${encodeURIComponent(apiClientUID)}`
        );

        return resp.apiclient;
    }
    

    public async addApiClient ( params: AddApiClientV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/apiclient`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.apiclient_uid;
    }
    

    public async updateApiClient ( apiClientUID: string, params: UpdateApiClientV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/apiclient/${encodeURIComponent(apiClientUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
    

    public async deleteApiClient ( apiClientUID: string ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/apiclient/${encodeURIComponent(apiClientUID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
