import React from 'react';
import DayJS from 'dayjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Environment } from '../../utils/Environment';
import { AuthContextProps, withAuth } from 'oidc-react';
import { IcGridItem, IcGridRow, IcSeparator } from '@indece-common/ic-ui-lib-react';

import './Footer.css';


export interface FooterProps extends WithTranslation, AuthContextProps
{
}


class $Footer extends React.Component<FooterProps>
{
    public render ( )
    {
        return (
            <div className='Footer'>
                <IcGridRow>
                    <IcGridItem s={12}>
                        <div className='Footer-upper-company'>
                            <Link to='/' className='Footer-upper-company-logo'>
                                <img src='/logo' title={Environment.server.name} alt='Home' />
                            </Link>

                            <div className='Footer-upper-company-slogan'>
                                {Environment.company.slogan}
                            </div>
                        </div>
                    </IcGridItem>
                </IcGridRow>

                <IcSeparator />

                <IcGridRow>
                    <IcGridItem s={12} m={6}>
                        <div className='Footer-lower-copyright'>
                            Copyright &copy; {DayJS().format('YYYY')} {Environment.company.name}
                        </div>

                        <div className='Footer-lower-version'>
                            indece SSO {Environment.server.version}
                        </div>
                    </IcGridItem>

                    <IcGridItem s={4} m={2}>
                        <Link to='/imprint' className='Footer-lower-link'>
                            {this.props.t('footer.btn_imprint')}
                        </Link>
                    </IcGridItem>

                    <IcGridItem s={4} m={2}>
                        <Link to='/terms' className='Footer-lower-link'>
                            {this.props.t('footer.btn_terms')}
                        </Link>
                    </IcGridItem>

                    <IcGridItem s={4} m={2}>
                        <Link to='/privacypolicy' className='Footer-lower-link'>
                            {this.props.t('footer.btn_privacypolicy')}
                        </Link>
                    </IcGridItem>
                </IcGridRow>
            </div>
        );
    }
}


export const Footer = withAuth(withTranslation()($Footer));
