import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { withLocale, WithLocaleProps } from '../../../utils/withLocale';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridRow, IcInputCheckbox, IcInputText, IcSeparator, Validator } from '@indece-common/ic-ui-lib-react';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';


export interface AdminSourceAddPageLdapFormStepFormData
{
    name:                           string;
    enabled:                        boolean;
    registration_enabled:           boolean;
    auto_assign_enabled:            boolean;
    ldap_uri:                       string;
    ldap_bind_user:                 string;
    ldap_bind_password:             string;
    ldap_user_filter_pattern:       string;
    ldap_user_username_attribute:   string;
    ldap_user_firstname_attribute:  string;
    ldap_user_lastname_attribute:   string;
    ldap_user_email_attribute:      string;
    ldap_user_base_dn:              string;
}


export interface AdminSourceAddPageLdapFormStepProps extends WithTranslation, WithLocaleProps
{
    onCancel:   ( ) => any;
    onFinish:   ( formData: AdminSourceAddPageLdapFormStepFormData ) => any;
}


interface AdminSourceAddPageLdapFormStepState
{
    initialFormData:    AdminSourceAddPageLdapFormStepFormData;
}


class $AdminSourceAddPageLdapFormStep extends React.Component<AdminSourceAddPageLdapFormStepProps, AdminSourceAddPageLdapFormStepState>
{
    constructor ( props: AdminSourceAddPageLdapFormStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                name:                           '',
                enabled:                        true,
                registration_enabled:           false,
                auto_assign_enabled:            true,
                ldap_uri:                       '',
                ldap_bind_user:                 '',
                ldap_bind_password:             '',
                ldap_user_filter_pattern:       '',
                ldap_user_username_attribute:   '',
                ldap_user_firstname_attribute:  '',
                ldap_user_lastname_attribute:   '',
                ldap_user_email_attribute:      '',
                ldap_user_base_dn:              ''
            }
        };
    }


    public render ( )
    {
        const MyFormik = Formik<AdminSourceAddPageLdapFormStepFormData>;

        return (
            <IcCard padding={IcCardPadding.Small}>
                <MyFormik
                    onSubmit={this.props.onFinish}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='enabled'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='registration_enabled'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_registration_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputCheckbox
                                    name='auto_assign_enabled'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_auto_assign_enabled')}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='name'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_name')}
                                    testID='adminsourceaddpageldapformstep.inp_name'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <IcSeparator />

                        <IcGridRow>
                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_uri'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_uri')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_uri'
                                    required={true}
                                    validators={[
                                        Validator.minLength(1),
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_bind_user'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_bind_user')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_bind_user'
                                    validators={[
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_bind_password'
                                    type='password'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_bind_password')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_bind_password'
                                    validators={[
                                        Validator.maxLength(256)
                                    ]}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_base_dn'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_user_base_dn')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_user_base_dn'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_filter_pattern'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_user_filter_pattern')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_user_filter_pattern'
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_username_attribute'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_user_username_attribute')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_user_username_attribute'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_firstname_attribute'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_user_firstname_attribute')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_user_firstname_attribute'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_lastname_attribute'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_user_lastname_attribute')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_user_lastname_attribute'
                                    required={true}
                                />
                            </IcGridItem>

                            <IcGridItem s={12}>
                                <IcInputText
                                    name='ldap_user_email_attribute'
                                    label={this.props.t('adminsourceaddpageldapformstep.inp_ldap_user_email_attribute')}
                                    testID='adminsourceaddpageldapformstep.inp_ldap_user_email_attribute'
                                    required={true}
                                />
                            </IcGridItem>
                        </IcGridRow>

                        <RequiredHint />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={this.props.onCancel}
                                testID='adminsourceaddpageldapformstep.btn_cancel'>
                                {this.props.t('adminsourceaddpageldapformstep.btn_cancel')}
                            </IcButton>

                            <IcButton
                                type='submit'
                                testID='adminsourceaddpageldapformstep.btn_add'>
                                {this.props.t('adminsourceaddpageldapformstep.btn_add')}
                            </IcButton>
                        </IcFloatRow>
                    </Form>
                </MyFormik>
            </IcCard>
        );
    } 
}


export const AdminSourceAddPageLdapFormStep = withLocale(withTranslation()($AdminSourceAddPageLdapFormStep));
