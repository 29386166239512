import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcGridItem, IcGridPadding, IcGridRow, IcSpinner, IcText, IcTextSize } from '@indece-common/ic-ui-lib-react';
import { WebAuthN } from '@indece-common/ic-ui-lib-webauthn';


export interface LoginPageWebAuthNOnlyStepProps extends WithTranslation
{
    onFinish:       ( webauthnAuthData: string, webauthnClientDataJSON: string, webauthnSignature: string ) => any;
    onError:        ( err: Error | null ) => any;
    onRegularLogin: ( ) => any;
}


class $LoginPageWebAuthNOnlyStep extends React.Component<LoginPageWebAuthNOnlyStepProps>
{
    private _active:    boolean;
    private _webAuthN:  WebAuthN;


    constructor ( props: LoginPageWebAuthNOnlyStepProps )
    {
        super(props);

        this._active = false;
        this._webAuthN = new WebAuthN();

        this._verify = this._verify.bind(this);
    }


    private async _verify ( ): Promise<void>
    {
        if ( this._active )
        {
            return;
        }

        try
        {
            this._active = true;

            this.props.onError(null);

            /* TODO
            if ( !this.props.loginSfa.webauthn_challenge || !this.props.loginSfa.webauthn_credential_id )
            {
                throw new Error('No challenge or credential id provided');
            }

            const response = await this._webAuthN.getCredentials(
                this.props.loginSfa.webauthn_credential_id,
                this.props.loginSfa.webauthn_challenge
            );

            if ( ! response )
            {
                this._active = false;

                return;
            }

            const authResponse = (response.response as AuthenticatorAssertionResponse);
            const signature = authResponse.signature;

            this.props.onFinish(
                btoa(String.fromCharCode(...new Uint8Array(authResponse.authenticatorData))),
                btoa(String.fromCharCode(...new Uint8Array(authResponse.clientDataJSON))),
                btoa(String.fromCharCode(...new Uint8Array(signature!)))
            );*/

            this._active = false;
        }
        catch ( err )
        {
            console.error(`Error getting credential: ${(err as Error).message}`, err);

            this.props.onError(err as Error);

            this._active = false;
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._verify();
    }


    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.None}>
                <IcGridRow padding={IcGridPadding.None}>
                    <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                        <IcText size={IcTextSize.Small}>
                            {this.props.t('loginpagewebauthnonlystep.txt_subtitle')}
                        </IcText>
                        
                        <IcText size={IcTextSize.Heading1}>
                            {this.props.t('loginpagewebauthnonlystep.txt_title')}
                        </IcText>

                        <IcText>
                            {this.props.t('loginpagewebauthnonlystep.txt_webauthn')}
                        </IcText>

                        <IcSpinner />

                        <IcFloatRow align={IcFloatRowAlign.Right}>
                            <IcButton
                                type='button'
                                color={IcButtonColor.Link}
                                onClick={ ( ) => this.props.onRegularLogin() }>
                                {this.props.t('loginpagewebauthnonlystep.btn_regular_login')}
                            </IcButton>
                        </IcFloatRow>
                    </IcGridItem>

                    <IcGridItem s={0} m={4} l={6}>
                        <BoxImage>
                            <ContentImage contentFileKey={ContentFileV1Key.LoginSfaWebAuthNImage} />

                            <SupportOverlay />
                        </BoxImage>
                    </IcGridItem>
                </IcGridRow>
            </IcCard>
        );
    }
}


export const LoginPageWebAuthNOnlyStep = withTranslation()($LoginPageWebAuthNOnlyStep);
