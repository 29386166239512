import React from 'react';
import { Form, Formik } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ContentFileV1Key } from '../../../Services/ContentService';
import { ContentImage } from '../../../Components/ContentImage/ContentImage';
import { BoxImage } from '../../../Components/Box/BoxImage';
import { SecureConnectionTag } from '../../../Components/SecureConnectionTag/SecureConnectionTag';
import { SupportOverlay } from '../../../Components/SupportOverlay/SupportOverlay';
import { RequiredHint } from '../../../Components/RequiredHint/RequiredHint';
import { IcButton, IcButtonColor, IcCard, IcCardPadding, IcGridItem, IcGridPadding, IcGridRow, IcInputCheckbox, IcInputText, IcText, IcTextSize, LinkUtils } from '@indece-common/ic-ui-lib-react';
import { WebAuthN } from '@indece-common/ic-ui-lib-webauthn';


export interface LoginPageCredentialsStepProps extends WithTranslation
{
    onFinish: ( formData: LoginPageCredentialsStepFormData | null, webauthn: boolean ) => any;
}


export interface LoginPageCredentialsStepFormData
{
    email:          string;
    password:       string;
    stay_loggedin:  boolean;
}


interface LoginPageCredentialsStepState
{
    initialFormData:    LoginPageCredentialsStepFormData;
    webauthnAvailable:  boolean;
}


class $LoginPageCredentialsStep extends React.Component<LoginPageCredentialsStepProps, LoginPageCredentialsStepState>
{
    private readonly _webauthn: WebAuthN;


    constructor ( props: LoginPageCredentialsStepProps )
    {
        super(props);

        this.state = {
            initialFormData: {
                email:          '',
                password:       '',
                stay_loggedin:  false
            },
            webauthnAvailable:  false
        };

        this._webauthn = new WebAuthN();

        this._login = this._login.bind(this);
        this._loginWebauthn = this._loginWebauthn.bind(this);
    }


    private _login ( values: LoginPageCredentialsStepFormData ): void
    {
        this.props.onFinish(values, false);
    }


    private _loginWebauthn ( ): void
    {
        this.props.onFinish(null, true);
    }


    public async componentDidMount(): Promise<void>
    {
        try
        {
            const userSfas = await this._webauthn.checkBrowserSupport();

            this.setState({
                webauthnAvailable:  userSfas.length > 0     // TODO
            });
        }
        catch ( err )
        {
            console.info(`WebAuthN is not available: ${(err as Error).message}`, err);

            this.setState({
                webauthnAvailable:  false
            });
        }
    }


    public render ( )
    {
        const MyFormik  = Formik<LoginPageCredentialsStepFormData>;

        return (
            <div className='LoginPageCredentialsStep'>
                <MyFormik
                    onSubmit={this._login}
                    initialValues={this.state.initialFormData}
                    enableReinitialize={true}>
                    <Form>
                        <IcCard padding={IcCardPadding.None}>
                            <IcGridRow padding={IcGridPadding.None}>
                                <IcGridItem s={12} m={8} l={6} padding={IcGridPadding.Large}>
                                        <IcText size={IcTextSize.Small}>
                                            {this.props.t('loginpagecredentialsstep.txt_subtitle')}
                                        </IcText>
                                        
                                        <IcText size={IcTextSize.Heading1}>
                                            {this.props.t('loginpagecredentialsstep.txt_title')}
                                        </IcText>

                                        <IcText>
                                            {this.props.t('loginpagecredentialsstep.txt_register')}

                                            <IcButton
                                                color={IcButtonColor.Link}
                                                to={LinkUtils.make('register')}
                                                type='button'>
                                                {this.props.t('loginpagecredentialsstep.btn_register')}
                                            </IcButton>
                                    </IcText>

                                    {this.state.webauthnAvailable ?
                                        <IcGridRow>
                                            <IcGridItem s={12}>
                                                <IcButton
                                                    onClick={this._loginWebauthn}
                                                    fullWidth={true}>
                                                    {this.props.t('loginpagecredentialsstep.btn_login_webauthn')}
                                                </IcButton>
                                            </IcGridItem>
                                        </IcGridRow>
                                    : null}

                                    <IcGridRow>
                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='email'
                                                type='email'
                                                label={this.props.t('loginpagecredentialsstep.inp_email')}
                                                required={true}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputText
                                                name='password'
                                                type='password'
                                                label={this.props.t('loginpagecredentialsstep.inp_password')}
                                                required={true}
                                            />
                                        </IcGridItem>

                                        <IcGridItem s={12}>
                                            <IcInputCheckbox
                                                name='stay_loggedin'
                                                label={this.props.t('loginpagecredentialsstep.inp_stay_loggedin')}
                                            />
                                        </IcGridItem>
                                    </IcGridRow>

                                    <RequiredHint />

                                    <IcButton
                                        type='submit'
                                        fullWidth={true}>
                                        {this.props.t('loginpagecredentialsstep.btn_login')}
                                    </IcButton>

                                    <IcButton
                                        to={LinkUtils.make('reset_password')}
                                        color={IcButtonColor.Link}
                                        fullWidth={true}>
                                        {this.props.t('loginpagecredentialsstep.btn_reset_password')}
                                    </IcButton>

                                    <SecureConnectionTag />
                                </IcGridItem>

                                <IcGridItem s={0} m={4} l={6}>
                                    <BoxImage>
                                        <ContentImage contentFileKey={ContentFileV1Key.LoginCredentialsImage} />

                                        <SupportOverlay />
                                    </BoxImage>
                                </IcGridItem>
                            </IcGridRow>
                        </IcCard>
                    </Form>
                </MyFormik>
            </div>
        );
    }
}


export const LoginPageCredentialsStep = withTranslation()($LoginPageCredentialsStep);
