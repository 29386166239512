import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SuccessBox } from '../../../Components/SuccessBox/SuccessBox';


export interface AdminGroupEditPageSuccessStepProps extends WithTranslation
{
}


class $AdminGroupEditPageSuccessStep extends React.Component<AdminGroupEditPageSuccessStepProps>
{
    public render ( )
    {
        return (
            <SuccessBox
                testID='admingroupeditpagesuccessstep.txt_success'
                message={this.props.t('admingroupeditpagesuccessstep.txt_success')}
            />
        );
    }
}


export const AdminGroupEditPageSuccessStep = withTranslation()($AdminGroupEditPageSuccessStep);
