import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IcCard, IcCardPadding, IcFloatRow, IcFloatRowAlign, IcInputRadio, IcText } from '@indece-common/ic-ui-lib-react';


export interface UserAddPageMethodStepProps extends WithTranslation
{
    onFinish: ( invite: boolean ) => any;
}


class $UserAddPageMethodStep extends React.Component<UserAddPageMethodStepProps>
{
    public render ( )
    {
        return (
            <IcCard padding={IcCardPadding.Large}>
                <IcText>
                    {this.props.t('useraddpagemethodstep.txt_info')}
                </IcText>

                <IcFloatRow align={IcFloatRowAlign.Center}>
                    <IcInputRadio
                        onClick={ ( ) => this.props.onFinish(true)}
                        value=''
                        label={this.props.t('useraddpagemethodstep.btn_invite')}
                    />

                    <IcInputRadio
                        onClick={ ( ) => this.props.onFinish(false)}
                        value=''
                        label={this.props.t('useraddpagemethodstep.btn_create')}
                    />
                </IcFloatRow>
            </IcCard>
        );
    }
}


export const UserAddPageMethodStep = withTranslation()($UserAddPageMethodStep);
