import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageTitle } from '../../Components/PageTitle/PageTitle';
import { AdminConfigPageCustomizationBox } from './AdminConfigPageCustomizationBox';
import { AdminConfigPageLocalesBox } from './AdminConfigPageLocalesBox';
import { AdminConfigPageServerBox } from './AdminConfigPageServerBox';
import { AdminConfigPageEmailFooterBox } from './AdminConfigPageEmailFooterBox';
import { IcBreadcrumbHome, IcBreadcrumbItem, IcBreadcrumbs, IcGridItem, IcGridRow, IcPageContent, LinkUtils } from '@indece-common/ic-ui-lib-react';


export interface AdminConfigPageProps extends WithTranslation
{
}


class $AdminConfigPage extends React.Component<AdminConfigPageProps>
{
    public render ( )
    {
        return (
            <IcPageContent>
                <IcBreadcrumbs>
                    <IcBreadcrumbHome to={LinkUtils.make()} />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin')}
                        label={this.props.t('adminpage.txt_title')}
                    />

                    <IcBreadcrumbItem
                        to={LinkUtils.make('admin', 'config')}
                        label={this.props.t('adminconfigpage.txt_title')}
                    />
                </IcBreadcrumbs>

                <PageTitle title={this.props.t('adminconfigpage.txt_title')} />

                <IcGridRow>
                    <IcGridItem s={12}>
                        <AdminConfigPageServerBox />
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <AdminConfigPageLocalesBox />
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <AdminConfigPageCustomizationBox />
                    </IcGridItem>
                </IcGridRow>

                <IcGridRow>
                    <IcGridItem s={12}>
                        <AdminConfigPageEmailFooterBox />
                    </IcGridItem>
                </IcGridRow>
            </IcPageContent>
        );
    }
}


export const AdminConfigPage = withTranslation()($AdminConfigPage);
